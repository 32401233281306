/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCompanyInput = {
  id?: string | null,
  owner: string,
  name: string,
  email?: string | null,
  iban: string,
  initials: string,
  handle?: string | null,
  consumablesFee: number,
  ticketsFee: number,
  companyAvatarId?: string | null,
};

export type ModelCompanyConditionInput = {
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  iban?: ModelStringInput | null,
  initials?: ModelStringInput | null,
  handle?: ModelStringInput | null,
  consumablesFee?: ModelFloatInput | null,
  ticketsFee?: ModelFloatInput | null,
  and?: Array< ModelCompanyConditionInput | null > | null,
  or?: Array< ModelCompanyConditionInput | null > | null,
  not?: ModelCompanyConditionInput | null,
  companyAvatarId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Company = {
  __typename: "Company",
  id: string,
  owner: string,
  name: string,
  email?: string | null,
  iban: string,
  initials: string,
  handle?: string | null,
  consumablesFee: number,
  ticketsFee: number,
  avatar?: Image | null,
  collaborators?: ModelCollaboratorConnection | null,
  events?: ModelEventConnection | null,
  promoters?: ModelPromoterConnection | null,
  members?: ModelMemberConnection | null,
  createdAt: string,
  updatedAt: string,
  companyAvatarId?: string | null,
};

export type Image = {
  __typename: "Image",
  id: string,
  owner: string,
  eventID?: string | null,
  filename: string,
  identityID: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelCollaboratorConnection = {
  __typename: "ModelCollaboratorConnection",
  items:  Array<Collaborator | null >,
  nextToken?: string | null,
};

export type Collaborator = {
  __typename: "Collaborator",
  id: string,
  owner: string,
  name: string,
  email?: string | null,
  handle?: string | null,
  phone: string,
  activity?: string | null,
  companyID: string,
  avatar?: Image | null,
  readTickets?: ModelPersonTicketConnection | null,
  history?: ModelConsumableTransactionConnection | null,
  events?: ModelCollaboratorEventConnectionConnection | null,
  chargeHistory?: ModelWalletTransactionConnection | null,
  createdAt: string,
  updatedAt: string,
  collaboratorAvatarId?: string | null,
};

export type ModelPersonTicketConnection = {
  __typename: "ModelPersonTicketConnection",
  items:  Array<PersonTicket | null >,
  nextToken?: string | null,
};

export type PersonTicket = {
  __typename: "PersonTicket",
  id: string,
  owner: string,
  readDates: Array< string >,
  privacy: Privacy,
  eventID: string,
  collaboratorID?: string | null,
  personID: string,
  ticket: Ticket,
  person: Person,
  refund?: TicketRefund | null,
  transaction?: TicketTransaction | null,
  createdAt: string,
  updatedAt: string,
  personTicketTicketId: string,
  personTicketRefundId?: string | null,
  personTicketTransactionId?: string | null,
};

// Description:
// * Holds the type of visibility that our users wants.
// 
// Arguments:
// * PRIVATE: no one can see this info besides the owner
// * PROTECTED: only our friends can see this info
// * PUBLIC: anyone can see this info
export enum Privacy {
  PRIVATE = "PRIVATE",
  PROTECTED = "PROTECTED",
  PUBLIC = "PUBLIC",
}


export type Ticket = {
  __typename: "Ticket",
  id: string,
  owner: string,
  name?: string | null,
  initialStock?: number | null,
  stock?: number | null,
  prices: Prices,
  validDates:  Array<DateSpan >,
  eventName: string,
  metadata?: string | null,
  eventID: string,
  createdAt: string,
  updatedAt: string,
};

export type Prices = {
  __typename: "Prices",
  price: number,
  memberPrice?: number | null,
  promoPrices:  Array<PromoPrices >,
};

export type PromoPrices = {
  __typename: "PromoPrices",
  dateSpan: DateSpan,
  memberPrice?: number | null,
  price: number,
};

export type DateSpan = {
  __typename: "DateSpan",
  start: string,
  end: string,
};

export type Person = {
  __typename: "Person",
  id: string,
  owner: string,
  name: string,
  email?: string | null,
  phone: string,
  dateOfBirth: string,
  wallet: number,
  privacy: Privacy,
  handle?: string | null,
  currentEventID?: string | null,
  address?: Address | null,
  auxIdentity?: AuxiliaryIdentity | null,
  avatar?: Image | null,
  event?: Event | null,
  consumables?: ModelPairPersonConnection | null,
  tickets?: ModelPersonTicketConnection | null,
  eventState?: ModelPersonEventStateConnection | null,
  historyTickets?: ModelTicketTransactionConnection | null,
  historyConsumables?: ModelConsumableTransactionConnection | null,
  historyWallet?: ModelWalletTransactionConnection | null,
  friends?: ModelFriendConnection | null,
  ticketRefunds?: ModelTicketRefundConnection | null,
  createdAt: string,
  updatedAt: string,
  personAuxIdentityId?: string | null,
  personAvatarId?: string | null,
};

export type Address = {
  __typename: "Address",
  street: string,
  zipCode: string,
  city: string,
  country: string,
};

export type AuxiliaryIdentity = {
  __typename: "AuxiliaryIdentity",
  id: string,
  owner: string,
  validDate: string,
  personID: string,
  createdAt: string,
  updatedAt: string,
};

export type Event = {
  __typename: "Event",
  id: string,
  owner: string,
  name: string,
  promotions: Array< Promotions >,
  location: Location,
  addressAlias: string,
  companyID: string,
  description: string,
  startDate: string,
  endDate: string,
  date:  Array<DateSpan >,
  maxCapacity?: number | null,
  company?: Company | null,
  banner: Image,
  poster?: Poster | null,
  streaks?: ModelStreakConnection | null,
  photos?: ModelImageConnection | null,
  tickets?: ModelTicketConnection | null,
  collaborators?: ModelCollaboratorEventConnectionConnection | null,
  consumables?: ModelConsumableConnection | null,
  peopleState?: ModelPersonEventStateConnection | null,
  transactionsTickets?: ModelTicketTransactionConnection | null,
  transactionsConsumables?: ModelConsumableTransactionConnection | null,
  ticketRefunds?: ModelTicketRefundConnection | null,
  promoters?: ModelPromoterEventConnectionConnection | null,
  createdAt: string,
  updatedAt: string,
  eventBannerId: string,
  eventPosterId?: string | null,
};

// Description:
// * Describes types of promotions that can be associated with events, streaks, etc...
// 
// Arguments:
// * TOP_PAGE: puts event/streak at the top of the page
export enum Promotions {
  TOP_PAGE = "TOP_PAGE",
}


export type Location = {
  __typename: "Location",
  lon: number,
  lat: number,
};

export type Poster = {
  __typename: "Poster",
  id: string,
  owner: string,
  artists:  Array<Artist | null >,
  createdAt: string,
  updatedAt: string,
};

export type Artist = {
  __typename: "Artist",
  name: string,
  performanceDateTime: string,
};

export type ModelStreakConnection = {
  __typename: "ModelStreakConnection",
  items:  Array<Streak | null >,
  nextToken?: string | null,
};

export type Streak = {
  __typename: "Streak",
  id: string,
  owner: string,
  name: string,
  isRepeatable: boolean,
  promotions: Array< Promotions >,
  eventID: string,
  promoPrice: number,
  promoPair: PairStreak,
  set?: ModelPairStreakConnection | null,
  createdAt: string,
  updatedAt: string,
  streakPromoPairId: string,
};

export type PairStreak = {
  __typename: "PairStreak",
  id: string,
  owner: string,
  streakID: string,
  amount: number,
  consumable: Consumable,
  createdAt: string,
  updatedAt: string,
  pairStreakConsumableId: string,
};

export type Consumable = {
  __typename: "Consumable",
  id: string,
  owner: string,
  type: ConsumableType,
  size: ConsumableSize,
  name: string,
  prices: Prices,
  companyID?: string | null,
  eventID: string,
  smallOrLargeRef?: Consumable | null,
  createdAt: string,
  updatedAt: string,
  consumableSmallOrLargeRefId?: string | null,
};

// Description:
// * Our consumables can be either a drink  or food (hot dogs, etc.). We use this distinction so that
// we can have multiple tabs.
// 
// Arguments:
// * FOOD: foods such as hot dogs, etc...
// * SPIRITS: vodkas, whiskey, etc...
// * SHOTS: shots and mixtures, etc...
// * BEER: beer related drinks, etc...
// * SANGRIA: sangria, etc...
// * CIDER: cider, etc...
// * NONALCOHOLIC: sodas, water, etc...
// * ACTIVITIES: any kind of activities that can be purchased such as "beer pong"
// * CUP: just an empty cup
// * CHARITY: used to donate money to charity
// * MISC: anything else that does not fit any of this criteria
export enum ConsumableType {
  BEER = "BEER",
  SANGRIA = "SANGRIA",
  CIDER = "CIDER",
  SPIRITS = "SPIRITS",
  SHOTS = "SHOTS",
  FOOD = "FOOD",
  NONALCOHOLIC = "NONALCOHOLIC",
  ACTIVITIES = "ACTIVITIES",
  CUP = "CUP",
  CHARITY = "CHARITY",
  MISC = "MISC",
}


// Description:
// * Our consumables can have different sizes and both have different values for scoreboard purposes.
// 
// Arguments:
// * REGULAR: regular/normal consumable size
// * LARGE: large consumable size
export enum ConsumableSize {
  REGULAR = "REGULAR",
  LARGE = "LARGE",
}


export type ModelPairStreakConnection = {
  __typename: "ModelPairStreakConnection",
  items:  Array<PairStreak | null >,
  nextToken?: string | null,
};

export type ModelImageConnection = {
  __typename: "ModelImageConnection",
  items:  Array<Image | null >,
  nextToken?: string | null,
};

export type ModelTicketConnection = {
  __typename: "ModelTicketConnection",
  items:  Array<Ticket | null >,
  nextToken?: string | null,
};

export type ModelCollaboratorEventConnectionConnection = {
  __typename: "ModelCollaboratorEventConnectionConnection",
  items:  Array<CollaboratorEventConnection | null >,
  nextToken?: string | null,
};

export type CollaboratorEventConnection = {
  __typename: "CollaboratorEventConnection",
  id: string,
  collaboratorID: string,
  eventID: string,
  collaborator: Collaborator,
  event: Event,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelConsumableConnection = {
  __typename: "ModelConsumableConnection",
  items:  Array<Consumable | null >,
  nextToken?: string | null,
};

export type ModelPersonEventStateConnection = {
  __typename: "ModelPersonEventStateConnection",
  items:  Array<PersonEventState | null >,
  nextToken?: string | null,
};

export type PersonEventState = {
  __typename: "PersonEventState",
  id: string,
  owner: string,
  personID: string,
  eventID: string,
  score: number,
  person: Person,
  streaks?: ModelStreakStateConnection | null,
  previous?: PreviousEventState | null,
  createdAt: string,
  updatedAt: string,
  personEventStatePreviousId?: string | null,
};

export type ModelStreakStateConnection = {
  __typename: "ModelStreakStateConnection",
  items:  Array<StreakState | null >,
  nextToken?: string | null,
};

export type StreakState = {
  __typename: "StreakState",
  id: string,
  owner: string,
  personStateID: string,
  currentState: Array< string >,
  prizeState?: Array< string > | null,
  nrOfTimesCompleted: number,
  streak: Streak,
  createdAt: string,
  updatedAt: string,
  streakStateStreakId: string,
};

export type PreviousEventState = {
  __typename: "PreviousEventState",
  id: string,
  owner: string,
  score: number,
  streaks:  Array<PreviousStreakState >,
  createdAt: string,
  updatedAt: string,
};

export type PreviousStreakState = {
  __typename: "PreviousStreakState",
  id: string,
  currentState: Array< string >,
  prizeState?: Array< string > | null,
  nrOfTimesCompleted: number,
  streakID: string,
};

export type ModelTicketTransactionConnection = {
  __typename: "ModelTicketTransactionConnection",
  items:  Array<TicketTransaction | null >,
  nextToken?: string | null,
};

export type TicketTransaction = {
  __typename: "TicketTransaction",
  id: string,
  owner: string,
  date: string,
  fee?: number | null,
  price: number,
  personID: string,
  eventID: string,
  ticket: Ticket,
  refund?: TicketRefund | null,
  personTicket?: PersonTicket | null,
  promoter?: Promoter | null,
  createdAt: string,
  updatedAt: string,
  ticketTransactionTicketId: string,
  ticketTransactionRefundId?: string | null,
  ticketTransactionPersonTicketId?: string | null,
  ticketTransactionPromoterId?: string | null,
};

export type TicketRefund = {
  __typename: "TicketRefund",
  id: string,
  owner: string,
  refundedAmount: number,
  refundedPercentage: number,
  ticketID: string,
  ticket: Ticket,
  personTicket: PersonTicket,
  transaction: TicketTransaction,
  eventID: string,
  personID: string,
  createdAt: string,
  updatedAt: string,
  ticketRefundPersonTicketId: string,
  ticketRefundTransactionId: string,
};

export type Promoter = {
  __typename: "Promoter",
  id: string,
  owner: string,
  name: string,
  companyID: string,
  events?: ModelPromoterEventConnectionConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelPromoterEventConnectionConnection = {
  __typename: "ModelPromoterEventConnectionConnection",
  items:  Array<PromoterEventConnection | null >,
  nextToken?: string | null,
};

export type PromoterEventConnection = {
  __typename: "PromoterEventConnection",
  id: string,
  promoterID: string,
  eventID: string,
  promoter: Promoter,
  event: Event,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelConsumableTransactionConnection = {
  __typename: "ModelConsumableTransactionConnection",
  items:  Array<ConsumableTransaction | null >,
  nextToken?: string | null,
};

export type ConsumableTransaction = {
  __typename: "ConsumableTransaction",
  id: string,
  owner: string,
  date: string,
  isRefunded?: boolean | null,
  personHandle?: string | null,
  price: number,
  collaboratorID: string,
  personID: string,
  eventID: string,
  cart?: ModelPairTransactionConnection | null,
  person?: Person | null,
  event?: Event | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelPairTransactionConnection = {
  __typename: "ModelPairTransactionConnection",
  items:  Array<PairTransaction | null >,
  nextToken?: string | null,
};

export type PairTransaction = {
  __typename: "PairTransaction",
  id: string,
  owner: string,
  personHandle?: string | null,
  transactionID: string,
  amount: number,
  isRefunded?: boolean | null,
  wasUsedInPromo: boolean,
  streakID?: string | null,
  wasPersonConsumable: boolean,
  pricePaid: number,
  whoGaveIt?: string | null,
  consumable: Consumable,
  createdAt: string,
  updatedAt: string,
  pairTransactionConsumableId: string,
};

export type ModelTicketRefundConnection = {
  __typename: "ModelTicketRefundConnection",
  items:  Array<TicketRefund | null >,
  nextToken?: string | null,
};

export type ModelPairPersonConnection = {
  __typename: "ModelPairPersonConnection",
  items:  Array<PairPerson | null >,
  nextToken?: string | null,
};

export type PairPerson = {
  __typename: "PairPerson",
  id: string,
  owner: string,
  personID: string,
  whoGaveIt: string,
  amount: number,
  consumable: Consumable,
  eventID: string,
  pricePaid?: number | null,
  createdAt: string,
  updatedAt: string,
  pairPersonConsumableId: string,
};

export type ModelWalletTransactionConnection = {
  __typename: "ModelWalletTransactionConnection",
  items:  Array<WalletTransaction | null >,
  nextToken?: string | null,
};

export type WalletTransaction = {
  __typename: "WalletTransaction",
  id: string,
  owner: string,
  date: string,
  amount: number,
  method?: PaymentMethod | null,
  status: PaymentStatus,
  metadata?: string | null,
  sourceID?: string | null,
  transactionID: string,
  personID: string,
  provider?: PaymentProviders | null,
  createdAt: string,
  updatedAt: string,
};

// Description:
// * When a user adds money to their wallet, they can use one of the following methods.
// 
// Arguments:
// * MBWAY: made a transactions with mbway
// * CREDITCARD: made a transaction with a credit card
// * NUMERARY: made a transaction with real money with a collaborator
// * REFUND: the transaction updates the amount in the wallet via a ticket refund made by a company
export enum PaymentMethod {
  MBWAY = "MBWAY",
  CREDITCARD = "CREDITCARD",
  NUMERARY = "NUMERARY",
  REFUND = "REFUND",
}


// Description:
// * The payment requested by a user when he adds fund to their wallet can have one of the following states.
// 
// Arguments:
// * PENDING: is still being processed
// * APPROVED: is done and was approved
// * DENIED: is done and was denied
export enum PaymentStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  FAILED = "FAILED",
}


// Description:
// * List of payment providers used.
// 
// Arguments:
// * SIBS : https://www.pay.sibs.com/en/
// * KEVIN : https://www.kevin.eu/pt-pt/
// * IFTHENPAY : https://ifthenpay.com
export enum PaymentProviders {
  SIBS = "SIBS",
  KEVIN = "KEVIN",
  IFTHENPAY = "IFTHENPAY",
}


export type ModelFriendConnection = {
  __typename: "ModelFriendConnection",
  items:  Array<Friend | null >,
  nextToken?: string | null,
};

export type Friend = {
  __typename: "Friend",
  id: string,
  personID: string,
  friendID: string,
  owner: string,
  friend: Person,
  request: FriendRequest,
  createdAt: string,
  updatedAt: string,
};

// Description:
// * Types that a friend can be in.
// 
// Arguments:
// * SENT: was sent still waiting confirmation
// * RECEIVED: was received still waiting confirmation
// * ACCEPTED: is done and was accepted
// * DENIED: is done and was denied
// * REMOVE: was removed by request of one of the users
export enum FriendRequest {
  SENT = "SENT",
  RECEIVED = "RECEIVED",
  ACCEPTED = "ACCEPTED",
  DENIED = "DENIED",
  REMOVE = "REMOVE",
}


export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
};

export type ModelPromoterConnection = {
  __typename: "ModelPromoterConnection",
  items:  Array<Promoter | null >,
  nextToken?: string | null,
};

export type ModelMemberConnection = {
  __typename: "ModelMemberConnection",
  items:  Array<Member | null >,
  nextToken?: string | null,
};

export type Member = {
  __typename: "Member",
  id: string,
  owner: string,
  name?: string | null,
  internalID?: string | null,
  email?: string | null,
  phone: string,
  companyID: string,
  company: Company,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCompanyInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  email?: string | null,
  iban?: string | null,
  initials?: string | null,
  handle?: string | null,
  consumablesFee?: number | null,
  ticketsFee?: number | null,
  companyAvatarId?: string | null,
};

export type DeleteCompanyInput = {
  id: string,
};

export type CreateMemberInput = {
  id?: string | null,
  owner: string,
  name?: string | null,
  internalID?: string | null,
  email?: string | null,
  phone: string,
  companyID: string,
};

export type ModelMemberConditionInput = {
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  internalID?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  and?: Array< ModelMemberConditionInput | null > | null,
  or?: Array< ModelMemberConditionInput | null > | null,
  not?: ModelMemberConditionInput | null,
};

export type UpdateMemberInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  internalID?: string | null,
  email?: string | null,
  phone?: string | null,
  companyID?: string | null,
};

export type DeleteMemberInput = {
  id: string,
};

export type CreateCollaboratorInput = {
  id?: string | null,
  owner: string,
  name: string,
  email?: string | null,
  handle?: string | null,
  phone: string,
  activity?: string | null,
  companyID: string,
  collaboratorAvatarId?: string | null,
};

export type ModelCollaboratorConditionInput = {
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  handle?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  activity?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  and?: Array< ModelCollaboratorConditionInput | null > | null,
  or?: Array< ModelCollaboratorConditionInput | null > | null,
  not?: ModelCollaboratorConditionInput | null,
  collaboratorAvatarId?: ModelIDInput | null,
};

export type UpdateCollaboratorInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  email?: string | null,
  handle?: string | null,
  phone?: string | null,
  activity?: string | null,
  companyID?: string | null,
  collaboratorAvatarId?: string | null,
};

export type DeleteCollaboratorInput = {
  id: string,
};

export type CreatePromoterInput = {
  id?: string | null,
  owner: string,
  name: string,
  companyID: string,
};

export type ModelPromoterConditionInput = {
  owner?: ModelStringInput | null,
  name?: ModelIDInput | null,
  companyID?: ModelIDInput | null,
  and?: Array< ModelPromoterConditionInput | null > | null,
  or?: Array< ModelPromoterConditionInput | null > | null,
  not?: ModelPromoterConditionInput | null,
};

export type UpdatePromoterInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  companyID?: string | null,
};

export type DeletePromoterInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  owner: string,
  name: string,
  promotions: Array< Promotions >,
  location: LocationInput,
  addressAlias: string,
  companyID: string,
  description: string,
  startDate: string,
  endDate: string,
  date: Array< DateSpanInput >,
  maxCapacity?: number | null,
  eventBannerId: string,
  eventPosterId?: string | null,
};

export type LocationInput = {
  lon: number,
  lat: number,
};

export type DateSpanInput = {
  start: string,
  end: string,
};

export type ModelEventConditionInput = {
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  promotions?: ModelPromotionsInput | null,
  addressAlias?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  description?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  maxCapacity?: ModelIntInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
  eventBannerId?: ModelIDInput | null,
  eventPosterId?: ModelIDInput | null,
};

export type ModelPromotionsInput = {
  eq?: Promotions | null,
  ne?: Promotions | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateEventInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  promotions?: Array< Promotions > | null,
  location?: LocationInput | null,
  addressAlias?: string | null,
  companyID?: string | null,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  date?: Array< DateSpanInput > | null,
  maxCapacity?: number | null,
  eventBannerId: string,
  eventPosterId?: string | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreatePersonEventStateInput = {
  id?: string | null,
  owner: string,
  personID: string,
  eventID: string,
  score: number,
  personEventStatePreviousId?: string | null,
};

export type ModelPersonEventStateConditionInput = {
  owner?: ModelStringInput | null,
  personID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  score?: ModelIntInput | null,
  and?: Array< ModelPersonEventStateConditionInput | null > | null,
  or?: Array< ModelPersonEventStateConditionInput | null > | null,
  not?: ModelPersonEventStateConditionInput | null,
  personEventStatePreviousId?: ModelIDInput | null,
};

export type UpdatePersonEventStateInput = {
  id: string,
  owner?: string | null,
  personID?: string | null,
  eventID?: string | null,
  score?: number | null,
  personEventStatePreviousId?: string | null,
};

export type DeletePersonEventStateInput = {
  id: string,
};

export type CreateStreakStateInput = {
  id?: string | null,
  owner: string,
  personStateID: string,
  currentState: Array< string >,
  prizeState?: Array< string > | null,
  nrOfTimesCompleted: number,
  streakStateStreakId: string,
};

export type ModelStreakStateConditionInput = {
  owner?: ModelStringInput | null,
  personStateID?: ModelIDInput | null,
  currentState?: ModelIDInput | null,
  prizeState?: ModelIDInput | null,
  nrOfTimesCompleted?: ModelIntInput | null,
  and?: Array< ModelStreakStateConditionInput | null > | null,
  or?: Array< ModelStreakStateConditionInput | null > | null,
  not?: ModelStreakStateConditionInput | null,
  streakStateStreakId?: ModelIDInput | null,
};

export type UpdateStreakStateInput = {
  id: string,
  owner?: string | null,
  personStateID?: string | null,
  currentState?: Array< string > | null,
  prizeState?: Array< string > | null,
  nrOfTimesCompleted?: number | null,
  streakStateStreakId: string,
};

export type DeleteStreakStateInput = {
  id: string,
};

export type CreatePreviousEventStateInput = {
  id?: string | null,
  owner: string,
  score: number,
  streaks: Array< PreviousStreakStateInput >,
};

export type PreviousStreakStateInput = {
  id?: string | null,
  currentState: Array< string >,
  prizeState?: Array< string > | null,
  nrOfTimesCompleted: number,
  streakID: string,
};

export type ModelPreviousEventStateConditionInput = {
  owner?: ModelStringInput | null,
  score?: ModelIntInput | null,
  and?: Array< ModelPreviousEventStateConditionInput | null > | null,
  or?: Array< ModelPreviousEventStateConditionInput | null > | null,
  not?: ModelPreviousEventStateConditionInput | null,
};

export type UpdatePreviousEventStateInput = {
  id: string,
  owner?: string | null,
  score?: number | null,
  streaks?: Array< PreviousStreakStateInput > | null,
};

export type DeletePreviousEventStateInput = {
  id: string,
};

export type CreateTicketInput = {
  id?: string | null,
  owner: string,
  name?: string | null,
  initialStock?: number | null,
  stock?: number | null,
  prices: PricesInput,
  validDates: Array< DateSpanInput >,
  eventName: string,
  metadata?: string | null,
  eventID: string,
};

export type PricesInput = {
  price: number,
  memberPrice?: number | null,
  promoPrices: Array< PromoPricesInput >,
};

export type PromoPricesInput = {
  dateSpan: DateSpanInput,
  memberPrice?: number | null,
  price: number,
};

export type ModelTicketConditionInput = {
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  initialStock?: ModelIntInput | null,
  stock?: ModelIntInput | null,
  eventName?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelTicketConditionInput | null > | null,
  or?: Array< ModelTicketConditionInput | null > | null,
  not?: ModelTicketConditionInput | null,
};

export type UpdateTicketInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  initialStock?: number | null,
  stock?: number | null,
  prices?: PricesInput | null,
  validDates?: Array< DateSpanInput > | null,
  eventName?: string | null,
  metadata?: string | null,
  eventID?: string | null,
};

export type DeleteTicketInput = {
  id: string,
};

export type CreatePosterInput = {
  id?: string | null,
  owner: string,
  artists: Array< Array< ArtistInput > | null >,
};

export type ArtistInput = {
  name: string,
  performanceDateTime: string,
};

export type ModelPosterConditionInput = {
  owner?: ModelStringInput | null,
  and?: Array< ModelPosterConditionInput | null > | null,
  or?: Array< ModelPosterConditionInput | null > | null,
  not?: ModelPosterConditionInput | null,
};

export type UpdatePosterInput = {
  id: string,
  owner?: string | null,
  artists?: Array< Array< ArtistInput > | null > | null,
};

export type DeletePosterInput = {
  id: string,
};

export type CreateTicketTransactionInput = {
  id?: string | null,
  owner: string,
  date: string,
  fee?: number | null,
  price: number,
  personID: string,
  eventID: string,
  ticketTransactionTicketId: string,
  ticketTransactionRefundId?: string | null,
  ticketTransactionPersonTicketId?: string | null,
  ticketTransactionPromoterId?: string | null,
};

export type ModelTicketTransactionConditionInput = {
  owner?: ModelStringInput | null,
  date?: ModelStringInput | null,
  fee?: ModelFloatInput | null,
  price?: ModelFloatInput | null,
  personID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelTicketTransactionConditionInput | null > | null,
  or?: Array< ModelTicketTransactionConditionInput | null > | null,
  not?: ModelTicketTransactionConditionInput | null,
  ticketTransactionTicketId?: ModelIDInput | null,
  ticketTransactionRefundId?: ModelIDInput | null,
  ticketTransactionPersonTicketId?: ModelIDInput | null,
  ticketTransactionPromoterId?: ModelIDInput | null,
};

export type UpdateTicketTransactionInput = {
  id: string,
  owner?: string | null,
  date?: string | null,
  fee?: number | null,
  price?: number | null,
  personID?: string | null,
  eventID?: string | null,
  ticketTransactionTicketId: string,
  ticketTransactionRefundId?: string | null,
  ticketTransactionPersonTicketId?: string | null,
  ticketTransactionPromoterId?: string | null,
};

export type DeleteTicketTransactionInput = {
  id: string,
};

export type CreateWalletTransactionInput = {
  id?: string | null,
  owner: string,
  date: string,
  amount: number,
  method?: PaymentMethod | null,
  status: PaymentStatus,
  metadata?: string | null,
  sourceID?: string | null,
  transactionID: string,
  personID: string,
  provider?: PaymentProviders | null,
};

export type ModelWalletTransactionConditionInput = {
  owner?: ModelStringInput | null,
  date?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  method?: ModelPaymentMethodInput | null,
  status?: ModelPaymentStatusInput | null,
  metadata?: ModelStringInput | null,
  sourceID?: ModelIDInput | null,
  transactionID?: ModelStringInput | null,
  personID?: ModelIDInput | null,
  provider?: ModelPaymentProvidersInput | null,
  and?: Array< ModelWalletTransactionConditionInput | null > | null,
  or?: Array< ModelWalletTransactionConditionInput | null > | null,
  not?: ModelWalletTransactionConditionInput | null,
};

export type ModelPaymentMethodInput = {
  eq?: PaymentMethod | null,
  ne?: PaymentMethod | null,
};

export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null,
  ne?: PaymentStatus | null,
};

export type ModelPaymentProvidersInput = {
  eq?: PaymentProviders | null,
  ne?: PaymentProviders | null,
};

export type UpdateWalletTransactionInput = {
  id: string,
  owner?: string | null,
  date?: string | null,
  amount?: number | null,
  method?: PaymentMethod | null,
  status?: PaymentStatus | null,
  metadata?: string | null,
  sourceID?: string | null,
  transactionID?: string | null,
  personID?: string | null,
  provider?: PaymentProviders | null,
};

export type DeleteWalletTransactionInput = {
  id: string,
};

export type CreateConsumableTransactionInput = {
  id?: string | null,
  owner: string,
  date: string,
  isRefunded?: boolean | null,
  personHandle?: string | null,
  price: number,
  collaboratorID: string,
  personID: string,
  eventID: string,
};

export type ModelConsumableTransactionConditionInput = {
  owner?: ModelStringInput | null,
  date?: ModelStringInput | null,
  isRefunded?: ModelBooleanInput | null,
  personHandle?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  collaboratorID?: ModelIDInput | null,
  personID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelConsumableTransactionConditionInput | null > | null,
  or?: Array< ModelConsumableTransactionConditionInput | null > | null,
  not?: ModelConsumableTransactionConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateConsumableTransactionInput = {
  id: string,
  owner?: string | null,
  date?: string | null,
  isRefunded?: boolean | null,
  personHandle?: string | null,
  price?: number | null,
  collaboratorID?: string | null,
  personID?: string | null,
  eventID?: string | null,
};

export type DeleteConsumableTransactionInput = {
  id: string,
};

export type CreatePersonTicketInput = {
  id?: string | null,
  owner: string,
  readDates: Array< string >,
  privacy: Privacy,
  eventID: string,
  collaboratorID?: string | null,
  personID: string,
  personTicketTicketId: string,
  personTicketRefundId?: string | null,
  personTicketTransactionId?: string | null,
};

export type ModelPersonTicketConditionInput = {
  owner?: ModelStringInput | null,
  readDates?: ModelStringInput | null,
  privacy?: ModelPrivacyInput | null,
  eventID?: ModelIDInput | null,
  collaboratorID?: ModelIDInput | null,
  personID?: ModelIDInput | null,
  and?: Array< ModelPersonTicketConditionInput | null > | null,
  or?: Array< ModelPersonTicketConditionInput | null > | null,
  not?: ModelPersonTicketConditionInput | null,
  personTicketTicketId?: ModelIDInput | null,
  personTicketRefundId?: ModelIDInput | null,
  personTicketTransactionId?: ModelIDInput | null,
};

export type ModelPrivacyInput = {
  eq?: Privacy | null,
  ne?: Privacy | null,
};

export type UpdatePersonTicketInput = {
  id: string,
  owner?: string | null,
  readDates?: Array< string > | null,
  privacy?: Privacy | null,
  eventID?: string | null,
  collaboratorID?: string | null,
  personID?: string | null,
  personTicketTicketId: string,
  personTicketRefundId?: string | null,
  personTicketTransactionId?: string | null,
};

export type DeletePersonTicketInput = {
  id: string,
};

export type CreateTicketRefundInput = {
  id?: string | null,
  owner: string,
  refundedAmount: number,
  refundedPercentage: number,
  ticketID: string,
  eventID: string,
  personID: string,
  ticketRefundPersonTicketId: string,
  ticketRefundTransactionId: string,
};

export type ModelTicketRefundConditionInput = {
  owner?: ModelStringInput | null,
  refundedAmount?: ModelFloatInput | null,
  refundedPercentage?: ModelFloatInput | null,
  ticketID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  personID?: ModelIDInput | null,
  and?: Array< ModelTicketRefundConditionInput | null > | null,
  or?: Array< ModelTicketRefundConditionInput | null > | null,
  not?: ModelTicketRefundConditionInput | null,
  ticketRefundPersonTicketId?: ModelIDInput | null,
  ticketRefundTransactionId?: ModelIDInput | null,
};

export type UpdateTicketRefundInput = {
  id: string,
  owner?: string | null,
  refundedAmount?: number | null,
  refundedPercentage?: number | null,
  ticketID?: string | null,
  eventID?: string | null,
  personID?: string | null,
  ticketRefundPersonTicketId: string,
  ticketRefundTransactionId: string,
};

export type DeleteTicketRefundInput = {
  id: string,
};

export type CreatePersonInput = {
  id?: string | null,
  owner: string,
  name: string,
  email?: string | null,
  phone: string,
  dateOfBirth: string,
  wallet: number,
  privacy: Privacy,
  handle?: string | null,
  currentEventID?: string | null,
  address?: AddressInput | null,
  personAuxIdentityId?: string | null,
  personAvatarId?: string | null,
};

export type AddressInput = {
  street: string,
  zipCode: string,
  city: string,
  country: string,
};

export type ModelPersonConditionInput = {
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  wallet?: ModelFloatInput | null,
  privacy?: ModelPrivacyInput | null,
  handle?: ModelStringInput | null,
  currentEventID?: ModelIDInput | null,
  and?: Array< ModelPersonConditionInput | null > | null,
  or?: Array< ModelPersonConditionInput | null > | null,
  not?: ModelPersonConditionInput | null,
  personAuxIdentityId?: ModelIDInput | null,
  personAvatarId?: ModelIDInput | null,
};

export type UpdatePersonInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  dateOfBirth?: string | null,
  wallet?: number | null,
  privacy?: Privacy | null,
  handle?: string | null,
  currentEventID?: string | null,
  address?: AddressInput | null,
  personAuxIdentityId?: string | null,
  personAvatarId?: string | null,
};

export type DeletePersonInput = {
  id: string,
};

export type CreateAuxiliaryIdentityInput = {
  id?: string | null,
  owner: string,
  validDate: string,
  personID: string,
};

export type ModelAuxiliaryIdentityConditionInput = {
  owner?: ModelStringInput | null,
  validDate?: ModelStringInput | null,
  personID?: ModelIDInput | null,
  and?: Array< ModelAuxiliaryIdentityConditionInput | null > | null,
  or?: Array< ModelAuxiliaryIdentityConditionInput | null > | null,
  not?: ModelAuxiliaryIdentityConditionInput | null,
};

export type UpdateAuxiliaryIdentityInput = {
  id: string,
  owner?: string | null,
  validDate?: string | null,
  personID?: string | null,
};

export type DeleteAuxiliaryIdentityInput = {
  id: string,
};

export type CreateFriendInput = {
  id?: string | null,
  personID: string,
  friendID: string,
  owner: string,
  request: FriendRequest,
};

export type ModelFriendConditionInput = {
  personID?: ModelIDInput | null,
  friendID?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  request?: ModelFriendRequestInput | null,
  and?: Array< ModelFriendConditionInput | null > | null,
  or?: Array< ModelFriendConditionInput | null > | null,
  not?: ModelFriendConditionInput | null,
};

export type ModelFriendRequestInput = {
  eq?: FriendRequest | null,
  ne?: FriendRequest | null,
};

export type UpdateFriendInput = {
  id: string,
  personID?: string | null,
  friendID?: string | null,
  owner?: string | null,
  request?: FriendRequest | null,
};

export type DeleteFriendInput = {
  id: string,
};

export type CreateConsumableInput = {
  id?: string | null,
  owner: string,
  type: ConsumableType,
  size: ConsumableSize,
  name: string,
  prices: PricesInput,
  companyID?: string | null,
  eventID: string,
  consumableSmallOrLargeRefId?: string | null,
};

export type ModelConsumableConditionInput = {
  owner?: ModelStringInput | null,
  type?: ModelConsumableTypeInput | null,
  size?: ModelConsumableSizeInput | null,
  name?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelConsumableConditionInput | null > | null,
  or?: Array< ModelConsumableConditionInput | null > | null,
  not?: ModelConsumableConditionInput | null,
  consumableSmallOrLargeRefId?: ModelIDInput | null,
};

export type ModelConsumableTypeInput = {
  eq?: ConsumableType | null,
  ne?: ConsumableType | null,
};

export type ModelConsumableSizeInput = {
  eq?: ConsumableSize | null,
  ne?: ConsumableSize | null,
};

export type UpdateConsumableInput = {
  id: string,
  owner?: string | null,
  type?: ConsumableType | null,
  size?: ConsumableSize | null,
  name?: string | null,
  prices?: PricesInput | null,
  companyID?: string | null,
  eventID?: string | null,
  consumableSmallOrLargeRefId?: string | null,
};

export type DeleteConsumableInput = {
  id: string,
};

export type CreateStreakInput = {
  id?: string | null,
  owner: string,
  name: string,
  isRepeatable: boolean,
  promotions: Array< Promotions >,
  eventID: string,
  promoPrice: number,
  streakPromoPairId: string,
};

export type ModelStreakConditionInput = {
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  isRepeatable?: ModelBooleanInput | null,
  promotions?: ModelPromotionsInput | null,
  eventID?: ModelIDInput | null,
  promoPrice?: ModelFloatInput | null,
  and?: Array< ModelStreakConditionInput | null > | null,
  or?: Array< ModelStreakConditionInput | null > | null,
  not?: ModelStreakConditionInput | null,
  streakPromoPairId?: ModelIDInput | null,
};

export type UpdateStreakInput = {
  id: string,
  owner?: string | null,
  name?: string | null,
  isRepeatable?: boolean | null,
  promotions?: Array< Promotions > | null,
  eventID?: string | null,
  promoPrice?: number | null,
  streakPromoPairId: string,
};

export type DeleteStreakInput = {
  id: string,
};

export type CreateImageInput = {
  id?: string | null,
  owner: string,
  eventID?: string | null,
  filename: string,
  identityID: string,
};

export type ModelImageConditionInput = {
  owner?: ModelStringInput | null,
  eventID?: ModelIDInput | null,
  filename?: ModelStringInput | null,
  identityID?: ModelStringInput | null,
  and?: Array< ModelImageConditionInput | null > | null,
  or?: Array< ModelImageConditionInput | null > | null,
  not?: ModelImageConditionInput | null,
};

export type UpdateImageInput = {
  id: string,
  owner?: string | null,
  eventID?: string | null,
  filename?: string | null,
  identityID?: string | null,
};

export type DeleteImageInput = {
  id: string,
};

export type CreatePairPersonInput = {
  id?: string | null,
  owner: string,
  personID: string,
  whoGaveIt: string,
  amount: number,
  eventID: string,
  pricePaid?: number | null,
  pairPersonConsumableId: string,
};

export type ModelPairPersonConditionInput = {
  owner?: ModelStringInput | null,
  personID?: ModelIDInput | null,
  whoGaveIt?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  eventID?: ModelIDInput | null,
  pricePaid?: ModelFloatInput | null,
  and?: Array< ModelPairPersonConditionInput | null > | null,
  or?: Array< ModelPairPersonConditionInput | null > | null,
  not?: ModelPairPersonConditionInput | null,
  pairPersonConsumableId?: ModelIDInput | null,
};

export type UpdatePairPersonInput = {
  id: string,
  owner?: string | null,
  personID?: string | null,
  whoGaveIt?: string | null,
  amount?: number | null,
  eventID?: string | null,
  pricePaid?: number | null,
  pairPersonConsumableId: string,
};

export type DeletePairPersonInput = {
  id: string,
};

export type CreatePairStreakInput = {
  id?: string | null,
  owner: string,
  streakID: string,
  amount: number,
  pairStreakConsumableId: string,
};

export type ModelPairStreakConditionInput = {
  owner?: ModelStringInput | null,
  streakID?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  and?: Array< ModelPairStreakConditionInput | null > | null,
  or?: Array< ModelPairStreakConditionInput | null > | null,
  not?: ModelPairStreakConditionInput | null,
  pairStreakConsumableId?: ModelIDInput | null,
};

export type UpdatePairStreakInput = {
  id: string,
  owner?: string | null,
  streakID?: string | null,
  amount?: number | null,
  pairStreakConsumableId: string,
};

export type DeletePairStreakInput = {
  id: string,
};

export type CreatePairTransactionInput = {
  id?: string | null,
  owner: string,
  personHandle?: string | null,
  transactionID: string,
  amount: number,
  isRefunded?: boolean | null,
  wasUsedInPromo: boolean,
  streakID?: string | null,
  wasPersonConsumable: boolean,
  pricePaid: number,
  whoGaveIt?: string | null,
  pairTransactionConsumableId: string,
};

export type ModelPairTransactionConditionInput = {
  owner?: ModelStringInput | null,
  personHandle?: ModelStringInput | null,
  transactionID?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  isRefunded?: ModelBooleanInput | null,
  wasUsedInPromo?: ModelBooleanInput | null,
  streakID?: ModelIDInput | null,
  wasPersonConsumable?: ModelBooleanInput | null,
  pricePaid?: ModelFloatInput | null,
  whoGaveIt?: ModelStringInput | null,
  and?: Array< ModelPairTransactionConditionInput | null > | null,
  or?: Array< ModelPairTransactionConditionInput | null > | null,
  not?: ModelPairTransactionConditionInput | null,
  pairTransactionConsumableId?: ModelIDInput | null,
};

export type UpdatePairTransactionInput = {
  id: string,
  owner?: string | null,
  personHandle?: string | null,
  transactionID?: string | null,
  amount?: number | null,
  isRefunded?: boolean | null,
  wasUsedInPromo?: boolean | null,
  streakID?: string | null,
  wasPersonConsumable?: boolean | null,
  pricePaid?: number | null,
  whoGaveIt?: string | null,
  pairTransactionConsumableId: string,
};

export type DeletePairTransactionInput = {
  id: string,
};

export type CreateCollaboratorEventConnectionInput = {
  id?: string | null,
  collaboratorID: string,
  eventID: string,
};

export type ModelCollaboratorEventConnectionConditionInput = {
  collaboratorID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelCollaboratorEventConnectionConditionInput | null > | null,
  or?: Array< ModelCollaboratorEventConnectionConditionInput | null > | null,
  not?: ModelCollaboratorEventConnectionConditionInput | null,
};

export type UpdateCollaboratorEventConnectionInput = {
  id: string,
  collaboratorID?: string | null,
  eventID?: string | null,
};

export type DeleteCollaboratorEventConnectionInput = {
  id: string,
};

export type CreatePromoterEventConnectionInput = {
  id?: string | null,
  promoterID: string,
  eventID: string,
};

export type ModelPromoterEventConnectionConditionInput = {
  promoterID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelPromoterEventConnectionConditionInput | null > | null,
  or?: Array< ModelPromoterEventConnectionConditionInput | null > | null,
  not?: ModelPromoterEventConnectionConditionInput | null,
};

export type UpdatePromoterEventConnectionInput = {
  id: string,
  promoterID?: string | null,
  eventID?: string | null,
};

export type DeletePromoterEventConnectionInput = {
  id: string,
};

export type TicketPurchaseInput = {
  personID: string,
  ticketID: string,
};

export type Response = {
  __typename: "Response",
  statusCode?: number | null,
  headers?: string | null,
  body?: string | null,
  status?: Status | null,
};

// Description:
// * Lambda return status.
// 
// Arguments:
// * SUCCESS: lambda was successful
// * FAILED: lambda was not successful
export enum Status {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}


export type ConsumablePurchaseInput = {
  personID: string,
  collaboratorID: string,
  eventID: string,
  consumables: Array< ConsumableAmount >,
};

export type ConsumableAmount = {
  consumableID: string,
  amount: number,
};

export type ConsumablePurchaseResponse = {
  __typename: "ConsumablePurchaseResponse",
  reply?: Reply | null,
  personName?: string | null,
  personHandle?: string | null,
  personWallet?: number | null,
  transactionID?: string | null,
};

export type Reply = {
  __typename: "Reply",
  status?: boolean | null,
  error?: string | null,
  metadata?: string | null,
};

export type HasTicketInput = {
  personID: string,
  eventID: string,
  collaboratorID: string,
};

export type DeleteUserInput = {
  username: string,
};

export type ScannerEncryptionInput = {
  info: string,
};

export type ScannerDecryptionInput = {
  info: string,
};

export type SendConsumableInput = {
  personID: string,
  phone: string,
  consumable: ConsumableAmount,
  receiverID?: string | null,
};

export type CheckoutMBWayInput = {
  amount: number,
  personID: string,
  address?: AddressInput | null,
  method: string,
};

export type CheckoutMBwayResponse = {
  __typename: "CheckoutMBwayResponse",
  formContext?: string | null,
  transactionID?: string | null,
  amount?: number | null,
};

export type StatusMBWayInput = {
  transactionID: string,
};

export type FriendRequestInput = {
  personID: string,
  phone: string,
  request: FriendRequest,
  friendID?: string | null,
};

export type AddFriendWithIDInput = {
  senderID: string,
  receiverID: string,
};

export type LinkPersonWithAuxInput = {
  personID: string,
  auxIdentity: string,
};

export type SibsPingInput = {
  transactionID: string,
};

export type RefundTicketsInput = {
  refundInfo: Array< ApplyRefundInfo >,
  refundPercentage: number,
  eventID: string,
  companyID: string,
  updateStock: boolean,
};

export type ApplyRefundInfo = {
  personID: string,
  ticketID: string,
  personTicketID: string,
};

export type CheckMinimumVersion = {
  currentVersion: string,
};

export type DoPaymentMBWay = {
  transactionID: string,
  phone: string,
};

export type PingMBWayInput = {
  transactionID: string,
};

export type ConsumablePurchaseRollbackInput = {
  transactionID: string,
  personID: string,
  eventID: string,
};

export type ConsumablePurchaseRollbackResponse = {
  __typename: "ConsumablePurchaseRollbackResponse",
  reply?: Reply | null,
  personName?: string | null,
  personHandle?: string | null,
  personWallet?: number | null,
  transactionID?: string | null,
};

export type ConsumableRefundInput = {
  transactionID: string,
  personID: string,
  eventID: string,
};

export type ConsumableRefundResponse = {
  __typename: "ConsumableRefundResponse",
  reply?: Reply | null,
  personName?: string | null,
  personHandle?: string | null,
  personWallet?: number | null,
  transactionID?: string | null,
};

export type LocationQueryInput = {
  location: LocationInput,
  radius: number,
};

export type ModelNearbyEventConnection = {
  __typename: "ModelNearbyEventConnection",
  items?:  Array<Event | null > | null,
  total?: number | null,
  nextToken?: string | null,
};

export type ModelCompanyFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  iban?: ModelStringInput | null,
  initials?: ModelStringInput | null,
  handle?: ModelStringInput | null,
  consumablesFee?: ModelFloatInput | null,
  ticketsFee?: ModelFloatInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
  companyAvatarId?: ModelIDInput | null,
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items:  Array<Company | null >,
  nextToken?: string | null,
};

export type ModelMemberFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  internalID?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  and?: Array< ModelMemberFilterInput | null > | null,
  or?: Array< ModelMemberFilterInput | null > | null,
  not?: ModelMemberFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type SearchableMemberFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  internalID?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  companyID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableMemberFilterInput | null > | null,
  or?: Array< SearchableMemberFilterInput | null > | null,
  not?: SearchableMemberFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableMemberSortInput = {
  field?: SearchableMemberSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableMemberSortableFields {
  id = "id",
  owner = "owner",
  name = "name",
  internalID = "internalID",
  email = "email",
  phone = "phone",
  companyID = "companyID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableMemberAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableMemberAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableMemberAggregateField {
  id = "id",
  owner = "owner",
  name = "name",
  internalID = "internalID",
  email = "email",
  phone = "phone",
  companyID = "companyID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableMemberConnection = {
  __typename: "SearchableMemberConnection",
  items:  Array<Member | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelCollaboratorFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  handle?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  activity?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  and?: Array< ModelCollaboratorFilterInput | null > | null,
  or?: Array< ModelCollaboratorFilterInput | null > | null,
  not?: ModelCollaboratorFilterInput | null,
  collaboratorAvatarId?: ModelIDInput | null,
};

export type ModelPromoterFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelIDInput | null,
  companyID?: ModelIDInput | null,
  and?: Array< ModelPromoterFilterInput | null > | null,
  or?: Array< ModelPromoterFilterInput | null > | null,
  not?: ModelPromoterFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type SearchablePromoterFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  name?: SearchableIDFilterInput | null,
  companyID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchablePromoterFilterInput | null > | null,
  or?: Array< SearchablePromoterFilterInput | null > | null,
  not?: SearchablePromoterFilterInput | null,
};

export type SearchablePromoterSortInput = {
  field?: SearchablePromoterSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePromoterSortableFields {
  id = "id",
  owner = "owner",
  name = "name",
  companyID = "companyID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePromoterAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePromoterAggregateField,
};

export enum SearchablePromoterAggregateField {
  id = "id",
  owner = "owner",
  name = "name",
  companyID = "companyID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePromoterConnection = {
  __typename: "SearchablePromoterConnection",
  items:  Array<Promoter | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  promotions?: ModelPromotionsInput | null,
  addressAlias?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  description?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  maxCapacity?: ModelIntInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
  eventBannerId?: ModelIDInput | null,
  eventPosterId?: ModelIDInput | null,
};

export type SearchableEventFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  addressAlias?: SearchableStringFilterInput | null,
  companyID?: SearchableIDFilterInput | null,
  description?: SearchableStringFilterInput | null,
  startDate?: SearchableStringFilterInput | null,
  endDate?: SearchableStringFilterInput | null,
  maxCapacity?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  eventBannerId?: SearchableIDFilterInput | null,
  eventPosterId?: SearchableIDFilterInput | null,
  promotions?: SearchableStringFilterInput | null,
  and?: Array< SearchableEventFilterInput | null > | null,
  or?: Array< SearchableEventFilterInput | null > | null,
  not?: SearchableEventFilterInput | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableEventSortInput = {
  field?: SearchableEventSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableEventSortableFields {
  id = "id",
  owner = "owner",
  name = "name",
  addressAlias = "addressAlias",
  companyID = "companyID",
  description = "description",
  startDate = "startDate",
  endDate = "endDate",
  maxCapacity = "maxCapacity",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  eventBannerId = "eventBannerId",
  eventPosterId = "eventPosterId",
}


export type SearchableEventAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableEventAggregateField,
};

export enum SearchableEventAggregateField {
  id = "id",
  owner = "owner",
  name = "name",
  promotions = "promotions",
  addressAlias = "addressAlias",
  companyID = "companyID",
  description = "description",
  startDate = "startDate",
  endDate = "endDate",
  maxCapacity = "maxCapacity",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  eventBannerId = "eventBannerId",
  eventPosterId = "eventPosterId",
}


export type SearchableEventConnection = {
  __typename: "SearchableEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPersonEventStateFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  personID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  score?: ModelIntInput | null,
  and?: Array< ModelPersonEventStateFilterInput | null > | null,
  or?: Array< ModelPersonEventStateFilterInput | null > | null,
  not?: ModelPersonEventStateFilterInput | null,
  personEventStatePreviousId?: ModelIDInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type SearchablePersonEventStateFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  personID?: SearchableIDFilterInput | null,
  eventID?: SearchableIDFilterInput | null,
  score?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  personEventStatePreviousId?: SearchableIDFilterInput | null,
  and?: Array< SearchablePersonEventStateFilterInput | null > | null,
  or?: Array< SearchablePersonEventStateFilterInput | null > | null,
  not?: SearchablePersonEventStateFilterInput | null,
};

export type SearchablePersonEventStateSortInput = {
  field?: SearchablePersonEventStateSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePersonEventStateSortableFields {
  id = "id",
  owner = "owner",
  personID = "personID",
  eventID = "eventID",
  score = "score",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  personEventStatePreviousId = "personEventStatePreviousId",
}


export type SearchablePersonEventStateAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePersonEventStateAggregateField,
};

export enum SearchablePersonEventStateAggregateField {
  id = "id",
  owner = "owner",
  personID = "personID",
  eventID = "eventID",
  score = "score",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  personEventStatePreviousId = "personEventStatePreviousId",
}


export type SearchablePersonEventStateConnection = {
  __typename: "SearchablePersonEventStateConnection",
  items:  Array<PersonEventState | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelStreakStateFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  personStateID?: ModelIDInput | null,
  currentState?: ModelIDInput | null,
  prizeState?: ModelIDInput | null,
  nrOfTimesCompleted?: ModelIntInput | null,
  and?: Array< ModelStreakStateFilterInput | null > | null,
  or?: Array< ModelStreakStateFilterInput | null > | null,
  not?: ModelStreakStateFilterInput | null,
  streakStateStreakId?: ModelIDInput | null,
};

export type SearchableStreakStateFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  personStateID?: SearchableIDFilterInput | null,
  currentState?: SearchableIDFilterInput | null,
  prizeState?: SearchableIDFilterInput | null,
  nrOfTimesCompleted?: SearchableIntFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  streakStateStreakId?: SearchableIDFilterInput | null,
  and?: Array< SearchableStreakStateFilterInput | null > | null,
  or?: Array< SearchableStreakStateFilterInput | null > | null,
  not?: SearchableStreakStateFilterInput | null,
};

export type SearchableStreakStateSortInput = {
  field?: SearchableStreakStateSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableStreakStateSortableFields {
  id = "id",
  owner = "owner",
  personStateID = "personStateID",
  currentState = "currentState",
  prizeState = "prizeState",
  nrOfTimesCompleted = "nrOfTimesCompleted",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  streakStateStreakId = "streakStateStreakId",
}


export type SearchableStreakStateAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableStreakStateAggregateField,
};

export enum SearchableStreakStateAggregateField {
  id = "id",
  owner = "owner",
  personStateID = "personStateID",
  currentState = "currentState",
  prizeState = "prizeState",
  nrOfTimesCompleted = "nrOfTimesCompleted",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  streakStateStreakId = "streakStateStreakId",
}


export type SearchableStreakStateConnection = {
  __typename: "SearchableStreakStateConnection",
  items:  Array<StreakState | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPreviousEventStateFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  score?: ModelIntInput | null,
  and?: Array< ModelPreviousEventStateFilterInput | null > | null,
  or?: Array< ModelPreviousEventStateFilterInput | null > | null,
  not?: ModelPreviousEventStateFilterInput | null,
};

export type ModelPreviousEventStateConnection = {
  __typename: "ModelPreviousEventStateConnection",
  items:  Array<PreviousEventState | null >,
  nextToken?: string | null,
};

export type ModelTicketFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  initialStock?: ModelIntInput | null,
  stock?: ModelIntInput | null,
  eventName?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelTicketFilterInput | null > | null,
  or?: Array< ModelTicketFilterInput | null > | null,
  not?: ModelTicketFilterInput | null,
};

export type ModelPosterFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelPosterFilterInput | null > | null,
  or?: Array< ModelPosterFilterInput | null > | null,
  not?: ModelPosterFilterInput | null,
};

export type ModelPosterConnection = {
  __typename: "ModelPosterConnection",
  items:  Array<Poster | null >,
  nextToken?: string | null,
};

export type ModelTicketTransactionFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  date?: ModelStringInput | null,
  fee?: ModelFloatInput | null,
  price?: ModelFloatInput | null,
  personID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelTicketTransactionFilterInput | null > | null,
  or?: Array< ModelTicketTransactionFilterInput | null > | null,
  not?: ModelTicketTransactionFilterInput | null,
  ticketTransactionTicketId?: ModelIDInput | null,
  ticketTransactionRefundId?: ModelIDInput | null,
  ticketTransactionPersonTicketId?: ModelIDInput | null,
  ticketTransactionPromoterId?: ModelIDInput | null,
};

export type SearchableTicketTransactionFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  date?: SearchableStringFilterInput | null,
  fee?: SearchableFloatFilterInput | null,
  price?: SearchableFloatFilterInput | null,
  personID?: SearchableIDFilterInput | null,
  eventID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  ticketTransactionTicketId?: SearchableIDFilterInput | null,
  ticketTransactionRefundId?: SearchableIDFilterInput | null,
  ticketTransactionPersonTicketId?: SearchableIDFilterInput | null,
  ticketTransactionPromoterId?: SearchableIDFilterInput | null,
  and?: Array< SearchableTicketTransactionFilterInput | null > | null,
  or?: Array< SearchableTicketTransactionFilterInput | null > | null,
  not?: SearchableTicketTransactionFilterInput | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableTicketTransactionSortInput = {
  field?: SearchableTicketTransactionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTicketTransactionSortableFields {
  id = "id",
  owner = "owner",
  date = "date",
  fee = "fee",
  price = "price",
  personID = "personID",
  eventID = "eventID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  ticketTransactionTicketId = "ticketTransactionTicketId",
  ticketTransactionRefundId = "ticketTransactionRefundId",
  ticketTransactionPersonTicketId = "ticketTransactionPersonTicketId",
  ticketTransactionPromoterId = "ticketTransactionPromoterId",
}


export type SearchableTicketTransactionAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableTicketTransactionAggregateField,
};

export enum SearchableTicketTransactionAggregateField {
  id = "id",
  owner = "owner",
  date = "date",
  fee = "fee",
  price = "price",
  personID = "personID",
  eventID = "eventID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  ticketTransactionTicketId = "ticketTransactionTicketId",
  ticketTransactionRefundId = "ticketTransactionRefundId",
  ticketTransactionPersonTicketId = "ticketTransactionPersonTicketId",
  ticketTransactionPromoterId = "ticketTransactionPromoterId",
}


export type SearchableTicketTransactionConnection = {
  __typename: "SearchableTicketTransactionConnection",
  items:  Array<TicketTransaction | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelWalletTransactionFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  date?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  method?: ModelPaymentMethodInput | null,
  status?: ModelPaymentStatusInput | null,
  metadata?: ModelStringInput | null,
  sourceID?: ModelIDInput | null,
  transactionID?: ModelStringInput | null,
  personID?: ModelIDInput | null,
  provider?: ModelPaymentProvidersInput | null,
  and?: Array< ModelWalletTransactionFilterInput | null > | null,
  or?: Array< ModelWalletTransactionFilterInput | null > | null,
  not?: ModelWalletTransactionFilterInput | null,
};

export type SearchableWalletTransactionFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  date?: SearchableStringFilterInput | null,
  amount?: SearchableFloatFilterInput | null,
  metadata?: SearchableStringFilterInput | null,
  sourceID?: SearchableIDFilterInput | null,
  transactionID?: SearchableStringFilterInput | null,
  personID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  method?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  provider?: SearchableStringFilterInput | null,
  and?: Array< SearchableWalletTransactionFilterInput | null > | null,
  or?: Array< SearchableWalletTransactionFilterInput | null > | null,
  not?: SearchableWalletTransactionFilterInput | null,
};

export type SearchableWalletTransactionSortInput = {
  field?: SearchableWalletTransactionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableWalletTransactionSortableFields {
  id = "id",
  owner = "owner",
  date = "date",
  amount = "amount",
  metadata = "metadata",
  sourceID = "sourceID",
  transactionID = "transactionID",
  personID = "personID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableWalletTransactionAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableWalletTransactionAggregateField,
};

export enum SearchableWalletTransactionAggregateField {
  id = "id",
  owner = "owner",
  date = "date",
  amount = "amount",
  method = "method",
  status = "status",
  metadata = "metadata",
  sourceID = "sourceID",
  transactionID = "transactionID",
  personID = "personID",
  provider = "provider",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableWalletTransactionConnection = {
  __typename: "SearchableWalletTransactionConnection",
  items:  Array<WalletTransaction | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelConsumableTransactionFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  date?: ModelStringInput | null,
  isRefunded?: ModelBooleanInput | null,
  personHandle?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  collaboratorID?: ModelIDInput | null,
  personID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelConsumableTransactionFilterInput | null > | null,
  or?: Array< ModelConsumableTransactionFilterInput | null > | null,
  not?: ModelConsumableTransactionFilterInput | null,
};

export type SearchableConsumableTransactionFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  date?: SearchableStringFilterInput | null,
  isRefunded?: SearchableBooleanFilterInput | null,
  personHandle?: SearchableStringFilterInput | null,
  price?: SearchableFloatFilterInput | null,
  collaboratorID?: SearchableIDFilterInput | null,
  personID?: SearchableIDFilterInput | null,
  eventID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableConsumableTransactionFilterInput | null > | null,
  or?: Array< SearchableConsumableTransactionFilterInput | null > | null,
  not?: SearchableConsumableTransactionFilterInput | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableConsumableTransactionSortInput = {
  field?: SearchableConsumableTransactionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableConsumableTransactionSortableFields {
  id = "id",
  owner = "owner",
  date = "date",
  isRefunded = "isRefunded",
  personHandle = "personHandle",
  price = "price",
  collaboratorID = "collaboratorID",
  personID = "personID",
  eventID = "eventID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableConsumableTransactionAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableConsumableTransactionAggregateField,
};

export enum SearchableConsumableTransactionAggregateField {
  id = "id",
  owner = "owner",
  date = "date",
  isRefunded = "isRefunded",
  personHandle = "personHandle",
  price = "price",
  collaboratorID = "collaboratorID",
  personID = "personID",
  eventID = "eventID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableConsumableTransactionConnection = {
  __typename: "SearchableConsumableTransactionConnection",
  items:  Array<ConsumableTransaction | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPersonTicketFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  readDates?: ModelStringInput | null,
  privacy?: ModelPrivacyInput | null,
  eventID?: ModelIDInput | null,
  collaboratorID?: ModelIDInput | null,
  personID?: ModelIDInput | null,
  and?: Array< ModelPersonTicketFilterInput | null > | null,
  or?: Array< ModelPersonTicketFilterInput | null > | null,
  not?: ModelPersonTicketFilterInput | null,
  personTicketTicketId?: ModelIDInput | null,
  personTicketRefundId?: ModelIDInput | null,
  personTicketTransactionId?: ModelIDInput | null,
};

export type SearchablePersonTicketFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  readDates?: SearchableStringFilterInput | null,
  eventID?: SearchableIDFilterInput | null,
  collaboratorID?: SearchableIDFilterInput | null,
  personID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  personTicketTicketId?: SearchableIDFilterInput | null,
  personTicketRefundId?: SearchableIDFilterInput | null,
  personTicketTransactionId?: SearchableIDFilterInput | null,
  privacy?: SearchableStringFilterInput | null,
  and?: Array< SearchablePersonTicketFilterInput | null > | null,
  or?: Array< SearchablePersonTicketFilterInput | null > | null,
  not?: SearchablePersonTicketFilterInput | null,
};

export type SearchablePersonTicketSortInput = {
  field?: SearchablePersonTicketSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePersonTicketSortableFields {
  id = "id",
  owner = "owner",
  readDates = "readDates",
  eventID = "eventID",
  collaboratorID = "collaboratorID",
  personID = "personID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  personTicketTicketId = "personTicketTicketId",
  personTicketRefundId = "personTicketRefundId",
  personTicketTransactionId = "personTicketTransactionId",
}


export type SearchablePersonTicketAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePersonTicketAggregateField,
};

export enum SearchablePersonTicketAggregateField {
  id = "id",
  owner = "owner",
  readDates = "readDates",
  privacy = "privacy",
  eventID = "eventID",
  collaboratorID = "collaboratorID",
  personID = "personID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  personTicketTicketId = "personTicketTicketId",
  personTicketRefundId = "personTicketRefundId",
  personTicketTransactionId = "personTicketTransactionId",
}


export type SearchablePersonTicketConnection = {
  __typename: "SearchablePersonTicketConnection",
  items:  Array<PersonTicket | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelTicketRefundFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  refundedAmount?: ModelFloatInput | null,
  refundedPercentage?: ModelFloatInput | null,
  ticketID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  personID?: ModelIDInput | null,
  and?: Array< ModelTicketRefundFilterInput | null > | null,
  or?: Array< ModelTicketRefundFilterInput | null > | null,
  not?: ModelTicketRefundFilterInput | null,
  ticketRefundPersonTicketId?: ModelIDInput | null,
  ticketRefundTransactionId?: ModelIDInput | null,
};

export type SearchableTicketRefundFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  refundedAmount?: SearchableFloatFilterInput | null,
  refundedPercentage?: SearchableFloatFilterInput | null,
  ticketID?: SearchableIDFilterInput | null,
  eventID?: SearchableIDFilterInput | null,
  personID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  ticketRefundPersonTicketId?: SearchableIDFilterInput | null,
  ticketRefundTransactionId?: SearchableIDFilterInput | null,
  and?: Array< SearchableTicketRefundFilterInput | null > | null,
  or?: Array< SearchableTicketRefundFilterInput | null > | null,
  not?: SearchableTicketRefundFilterInput | null,
};

export type SearchableTicketRefundSortInput = {
  field?: SearchableTicketRefundSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTicketRefundSortableFields {
  id = "id",
  owner = "owner",
  refundedAmount = "refundedAmount",
  refundedPercentage = "refundedPercentage",
  ticketID = "ticketID",
  eventID = "eventID",
  personID = "personID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  ticketRefundPersonTicketId = "ticketRefundPersonTicketId",
  ticketRefundTransactionId = "ticketRefundTransactionId",
}


export type SearchableTicketRefundAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableTicketRefundAggregateField,
};

export enum SearchableTicketRefundAggregateField {
  id = "id",
  owner = "owner",
  refundedAmount = "refundedAmount",
  refundedPercentage = "refundedPercentage",
  ticketID = "ticketID",
  eventID = "eventID",
  personID = "personID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  ticketRefundPersonTicketId = "ticketRefundPersonTicketId",
  ticketRefundTransactionId = "ticketRefundTransactionId",
}


export type SearchableTicketRefundConnection = {
  __typename: "SearchableTicketRefundConnection",
  items:  Array<TicketRefund | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPersonFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  wallet?: ModelFloatInput | null,
  privacy?: ModelPrivacyInput | null,
  handle?: ModelStringInput | null,
  currentEventID?: ModelIDInput | null,
  and?: Array< ModelPersonFilterInput | null > | null,
  or?: Array< ModelPersonFilterInput | null > | null,
  not?: ModelPersonFilterInput | null,
  personAuxIdentityId?: ModelIDInput | null,
  personAvatarId?: ModelIDInput | null,
};

export type ModelPersonConnection = {
  __typename: "ModelPersonConnection",
  items:  Array<Person | null >,
  nextToken?: string | null,
};

export type SearchablePersonFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  dateOfBirth?: SearchableStringFilterInput | null,
  wallet?: SearchableFloatFilterInput | null,
  handle?: SearchableStringFilterInput | null,
  currentEventID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  personAuxIdentityId?: SearchableIDFilterInput | null,
  personAvatarId?: SearchableIDFilterInput | null,
  privacy?: SearchableStringFilterInput | null,
  and?: Array< SearchablePersonFilterInput | null > | null,
  or?: Array< SearchablePersonFilterInput | null > | null,
  not?: SearchablePersonFilterInput | null,
};

export type SearchablePersonSortInput = {
  field?: SearchablePersonSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePersonSortableFields {
  id = "id",
  owner = "owner",
  name = "name",
  email = "email",
  phone = "phone",
  dateOfBirth = "dateOfBirth",
  wallet = "wallet",
  handle = "handle",
  currentEventID = "currentEventID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  personAuxIdentityId = "personAuxIdentityId",
  personAvatarId = "personAvatarId",
}


export type SearchablePersonAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePersonAggregateField,
};

export enum SearchablePersonAggregateField {
  id = "id",
  owner = "owner",
  name = "name",
  email = "email",
  phone = "phone",
  dateOfBirth = "dateOfBirth",
  wallet = "wallet",
  privacy = "privacy",
  handle = "handle",
  currentEventID = "currentEventID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  personAuxIdentityId = "personAuxIdentityId",
  personAvatarId = "personAvatarId",
}


export type SearchablePersonConnection = {
  __typename: "SearchablePersonConnection",
  items:  Array<Person | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelAuxiliaryIdentityFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  validDate?: ModelStringInput | null,
  personID?: ModelIDInput | null,
  and?: Array< ModelAuxiliaryIdentityFilterInput | null > | null,
  or?: Array< ModelAuxiliaryIdentityFilterInput | null > | null,
  not?: ModelAuxiliaryIdentityFilterInput | null,
};

export type ModelAuxiliaryIdentityConnection = {
  __typename: "ModelAuxiliaryIdentityConnection",
  items:  Array<AuxiliaryIdentity | null >,
  nextToken?: string | null,
};

export type ModelFriendFilterInput = {
  id?: ModelIDInput | null,
  personID?: ModelIDInput | null,
  friendID?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  request?: ModelFriendRequestInput | null,
  and?: Array< ModelFriendFilterInput | null > | null,
  or?: Array< ModelFriendFilterInput | null > | null,
  not?: ModelFriendFilterInput | null,
};

export type ModelConsumableFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  type?: ModelConsumableTypeInput | null,
  size?: ModelConsumableSizeInput | null,
  name?: ModelStringInput | null,
  companyID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelConsumableFilterInput | null > | null,
  or?: Array< ModelConsumableFilterInput | null > | null,
  not?: ModelConsumableFilterInput | null,
  consumableSmallOrLargeRefId?: ModelIDInput | null,
};

export type ModelStreakFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  name?: ModelStringInput | null,
  isRepeatable?: ModelBooleanInput | null,
  promotions?: ModelPromotionsInput | null,
  eventID?: ModelIDInput | null,
  promoPrice?: ModelFloatInput | null,
  and?: Array< ModelStreakFilterInput | null > | null,
  or?: Array< ModelStreakFilterInput | null > | null,
  not?: ModelStreakFilterInput | null,
  streakPromoPairId?: ModelIDInput | null,
};

export type ModelImageFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  eventID?: ModelIDInput | null,
  filename?: ModelStringInput | null,
  identityID?: ModelStringInput | null,
  and?: Array< ModelImageFilterInput | null > | null,
  or?: Array< ModelImageFilterInput | null > | null,
  not?: ModelImageFilterInput | null,
};

export type ModelPairPersonFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  personID?: ModelIDInput | null,
  whoGaveIt?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  eventID?: ModelIDInput | null,
  pricePaid?: ModelFloatInput | null,
  and?: Array< ModelPairPersonFilterInput | null > | null,
  or?: Array< ModelPairPersonFilterInput | null > | null,
  not?: ModelPairPersonFilterInput | null,
  pairPersonConsumableId?: ModelIDInput | null,
};

export type ModelPairStreakFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  streakID?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  and?: Array< ModelPairStreakFilterInput | null > | null,
  or?: Array< ModelPairStreakFilterInput | null > | null,
  not?: ModelPairStreakFilterInput | null,
  pairStreakConsumableId?: ModelIDInput | null,
};

export type ModelPairTransactionFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  personHandle?: ModelStringInput | null,
  transactionID?: ModelIDInput | null,
  amount?: ModelIntInput | null,
  isRefunded?: ModelBooleanInput | null,
  wasUsedInPromo?: ModelBooleanInput | null,
  streakID?: ModelIDInput | null,
  wasPersonConsumable?: ModelBooleanInput | null,
  pricePaid?: ModelFloatInput | null,
  whoGaveIt?: ModelStringInput | null,
  and?: Array< ModelPairTransactionFilterInput | null > | null,
  or?: Array< ModelPairTransactionFilterInput | null > | null,
  not?: ModelPairTransactionFilterInput | null,
  pairTransactionConsumableId?: ModelIDInput | null,
};

export type SearchablePairTransactionFilterInput = {
  id?: SearchableIDFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  personHandle?: SearchableStringFilterInput | null,
  transactionID?: SearchableIDFilterInput | null,
  amount?: SearchableIntFilterInput | null,
  isRefunded?: SearchableBooleanFilterInput | null,
  wasUsedInPromo?: SearchableBooleanFilterInput | null,
  streakID?: SearchableIDFilterInput | null,
  wasPersonConsumable?: SearchableBooleanFilterInput | null,
  pricePaid?: SearchableFloatFilterInput | null,
  whoGaveIt?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  pairTransactionConsumableId?: SearchableIDFilterInput | null,
  and?: Array< SearchablePairTransactionFilterInput | null > | null,
  or?: Array< SearchablePairTransactionFilterInput | null > | null,
  not?: SearchablePairTransactionFilterInput | null,
};

export type SearchablePairTransactionSortInput = {
  field?: SearchablePairTransactionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePairTransactionSortableFields {
  id = "id",
  owner = "owner",
  personHandle = "personHandle",
  transactionID = "transactionID",
  amount = "amount",
  isRefunded = "isRefunded",
  wasUsedInPromo = "wasUsedInPromo",
  streakID = "streakID",
  wasPersonConsumable = "wasPersonConsumable",
  pricePaid = "pricePaid",
  whoGaveIt = "whoGaveIt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  pairTransactionConsumableId = "pairTransactionConsumableId",
}


export type SearchablePairTransactionAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePairTransactionAggregateField,
};

export enum SearchablePairTransactionAggregateField {
  id = "id",
  owner = "owner",
  personHandle = "personHandle",
  transactionID = "transactionID",
  amount = "amount",
  isRefunded = "isRefunded",
  wasUsedInPromo = "wasUsedInPromo",
  streakID = "streakID",
  wasPersonConsumable = "wasPersonConsumable",
  pricePaid = "pricePaid",
  whoGaveIt = "whoGaveIt",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  pairTransactionConsumableId = "pairTransactionConsumableId",
}


export type SearchablePairTransactionConnection = {
  __typename: "SearchablePairTransactionConnection",
  items:  Array<PairTransaction | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelCollaboratorEventConnectionFilterInput = {
  id?: ModelIDInput | null,
  collaboratorID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelCollaboratorEventConnectionFilterInput | null > | null,
  or?: Array< ModelCollaboratorEventConnectionFilterInput | null > | null,
  not?: ModelCollaboratorEventConnectionFilterInput | null,
};

export type ModelPromoterEventConnectionFilterInput = {
  id?: ModelIDInput | null,
  promoterID?: ModelIDInput | null,
  eventID?: ModelIDInput | null,
  and?: Array< ModelPromoterEventConnectionFilterInput | null > | null,
  or?: Array< ModelPromoterEventConnectionFilterInput | null > | null,
  not?: ModelPromoterEventConnectionFilterInput | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    iban: string,
    initials: string,
    handle?: string | null,
    consumablesFee: number,
    ticketsFee: number,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorConnection",
      items:  Array< {
        __typename: "Collaborator",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        handle?: string | null,
        phone: string,
        activity?: string | null,
        companyID: string,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        readTickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        history?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        chargeHistory?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        collaboratorAvatarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterConnection",
      items:  Array< {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner: string,
        name?: string | null,
        internalID?: string | null,
        email?: string | null,
        phone: string,
        companyID: string,
        company:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyAvatarId?: string | null,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    iban: string,
    initials: string,
    handle?: string | null,
    consumablesFee: number,
    ticketsFee: number,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorConnection",
      items:  Array< {
        __typename: "Collaborator",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        handle?: string | null,
        phone: string,
        activity?: string | null,
        companyID: string,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        readTickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        history?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        chargeHistory?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        collaboratorAvatarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterConnection",
      items:  Array< {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner: string,
        name?: string | null,
        internalID?: string | null,
        email?: string | null,
        phone: string,
        companyID: string,
        company:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyAvatarId?: string | null,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    iban: string,
    initials: string,
    handle?: string | null,
    consumablesFee: number,
    ticketsFee: number,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorConnection",
      items:  Array< {
        __typename: "Collaborator",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        handle?: string | null,
        phone: string,
        activity?: string | null,
        companyID: string,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        readTickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        history?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        chargeHistory?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        collaboratorAvatarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterConnection",
      items:  Array< {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner: string,
        name?: string | null,
        internalID?: string | null,
        email?: string | null,
        phone: string,
        companyID: string,
        company:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyAvatarId?: string | null,
  } | null,
};

export type CreateMemberMutationVariables = {
  input: CreateMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type CreateMemberMutation = {
  createMember?:  {
    __typename: "Member",
    id: string,
    owner: string,
    name?: string | null,
    internalID?: string | null,
    email?: string | null,
    phone: string,
    companyID: string,
    company:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMemberMutationVariables = {
  input: UpdateMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type UpdateMemberMutation = {
  updateMember?:  {
    __typename: "Member",
    id: string,
    owner: string,
    name?: string | null,
    internalID?: string | null,
    email?: string | null,
    phone: string,
    companyID: string,
    company:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMemberMutationVariables = {
  input: DeleteMemberInput,
  condition?: ModelMemberConditionInput | null,
};

export type DeleteMemberMutation = {
  deleteMember?:  {
    __typename: "Member",
    id: string,
    owner: string,
    name?: string | null,
    internalID?: string | null,
    email?: string | null,
    phone: string,
    companyID: string,
    company:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCollaboratorMutationVariables = {
  input: CreateCollaboratorInput,
  condition?: ModelCollaboratorConditionInput | null,
};

export type CreateCollaboratorMutation = {
  createCollaborator?:  {
    __typename: "Collaborator",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    handle?: string | null,
    phone: string,
    activity?: string | null,
    companyID: string,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    readTickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chargeHistory?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    collaboratorAvatarId?: string | null,
  } | null,
};

export type UpdateCollaboratorMutationVariables = {
  input: UpdateCollaboratorInput,
  condition?: ModelCollaboratorConditionInput | null,
};

export type UpdateCollaboratorMutation = {
  updateCollaborator?:  {
    __typename: "Collaborator",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    handle?: string | null,
    phone: string,
    activity?: string | null,
    companyID: string,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    readTickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chargeHistory?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    collaboratorAvatarId?: string | null,
  } | null,
};

export type DeleteCollaboratorMutationVariables = {
  input: DeleteCollaboratorInput,
  condition?: ModelCollaboratorConditionInput | null,
};

export type DeleteCollaboratorMutation = {
  deleteCollaborator?:  {
    __typename: "Collaborator",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    handle?: string | null,
    phone: string,
    activity?: string | null,
    companyID: string,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    readTickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chargeHistory?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    collaboratorAvatarId?: string | null,
  } | null,
};

export type CreatePromoterMutationVariables = {
  input: CreatePromoterInput,
  condition?: ModelPromoterConditionInput | null,
};

export type CreatePromoterMutation = {
  createPromoter?:  {
    __typename: "Promoter",
    id: string,
    owner: string,
    name: string,
    companyID: string,
    events?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePromoterMutationVariables = {
  input: UpdatePromoterInput,
  condition?: ModelPromoterConditionInput | null,
};

export type UpdatePromoterMutation = {
  updatePromoter?:  {
    __typename: "Promoter",
    id: string,
    owner: string,
    name: string,
    companyID: string,
    events?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePromoterMutationVariables = {
  input: DeletePromoterInput,
  condition?: ModelPromoterConditionInput | null,
};

export type DeletePromoterMutation = {
  deletePromoter?:  {
    __typename: "Promoter",
    id: string,
    owner: string,
    name: string,
    companyID: string,
    events?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    owner: string,
    name: string,
    promotions: Array< Promotions >,
    location:  {
      __typename: "Location",
      lon: number,
      lat: number,
    },
    addressAlias: string,
    companyID: string,
    description: string,
    startDate: string,
    endDate: string,
    date:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    maxCapacity?: number | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    } | null,
    banner:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    },
    poster?:  {
      __typename: "Poster",
      id: string,
      owner: string,
      artists:  Array< {
        __typename: "Artist",
        name: string,
        performanceDateTime: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    streaks?:  {
      __typename: "ModelStreakConnection",
      items:  Array< {
        __typename: "Streak",
        id: string,
        owner: string,
        name: string,
        isRepeatable: boolean,
        promotions: Array< Promotions >,
        eventID: string,
        promoPrice: number,
        promoPair:  {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        },
        set?:  {
          __typename: "ModelPairStreakConnection",
          items:  Array< {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        streakPromoPairId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelConsumableConnection",
      items:  Array< {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    peopleState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    eventBannerId: string,
    eventPosterId?: string | null,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    owner: string,
    name: string,
    promotions: Array< Promotions >,
    location:  {
      __typename: "Location",
      lon: number,
      lat: number,
    },
    addressAlias: string,
    companyID: string,
    description: string,
    startDate: string,
    endDate: string,
    date:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    maxCapacity?: number | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    } | null,
    banner:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    },
    poster?:  {
      __typename: "Poster",
      id: string,
      owner: string,
      artists:  Array< {
        __typename: "Artist",
        name: string,
        performanceDateTime: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    streaks?:  {
      __typename: "ModelStreakConnection",
      items:  Array< {
        __typename: "Streak",
        id: string,
        owner: string,
        name: string,
        isRepeatable: boolean,
        promotions: Array< Promotions >,
        eventID: string,
        promoPrice: number,
        promoPair:  {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        },
        set?:  {
          __typename: "ModelPairStreakConnection",
          items:  Array< {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        streakPromoPairId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelConsumableConnection",
      items:  Array< {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    peopleState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    eventBannerId: string,
    eventPosterId?: string | null,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    owner: string,
    name: string,
    promotions: Array< Promotions >,
    location:  {
      __typename: "Location",
      lon: number,
      lat: number,
    },
    addressAlias: string,
    companyID: string,
    description: string,
    startDate: string,
    endDate: string,
    date:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    maxCapacity?: number | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    } | null,
    banner:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    },
    poster?:  {
      __typename: "Poster",
      id: string,
      owner: string,
      artists:  Array< {
        __typename: "Artist",
        name: string,
        performanceDateTime: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    streaks?:  {
      __typename: "ModelStreakConnection",
      items:  Array< {
        __typename: "Streak",
        id: string,
        owner: string,
        name: string,
        isRepeatable: boolean,
        promotions: Array< Promotions >,
        eventID: string,
        promoPrice: number,
        promoPair:  {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        },
        set?:  {
          __typename: "ModelPairStreakConnection",
          items:  Array< {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        streakPromoPairId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelConsumableConnection",
      items:  Array< {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    peopleState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    eventBannerId: string,
    eventPosterId?: string | null,
  } | null,
};

export type CreatePersonEventStateMutationVariables = {
  input: CreatePersonEventStateInput,
  condition?: ModelPersonEventStateConditionInput | null,
};

export type CreatePersonEventStateMutation = {
  createPersonEventState?:  {
    __typename: "PersonEventState",
    id: string,
    owner: string,
    personID: string,
    eventID: string,
    score: number,
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    streaks?:  {
      __typename: "ModelStreakStateConnection",
      items:  Array< {
        __typename: "StreakState",
        id: string,
        owner: string,
        personStateID: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streak:  {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        },
        createdAt: string,
        updatedAt: string,
        streakStateStreakId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    previous?:  {
      __typename: "PreviousEventState",
      id: string,
      owner: string,
      score: number,
      streaks:  Array< {
        __typename: "PreviousStreakState",
        id: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streakID: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    personEventStatePreviousId?: string | null,
  } | null,
};

export type UpdatePersonEventStateMutationVariables = {
  input: UpdatePersonEventStateInput,
  condition?: ModelPersonEventStateConditionInput | null,
};

export type UpdatePersonEventStateMutation = {
  updatePersonEventState?:  {
    __typename: "PersonEventState",
    id: string,
    owner: string,
    personID: string,
    eventID: string,
    score: number,
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    streaks?:  {
      __typename: "ModelStreakStateConnection",
      items:  Array< {
        __typename: "StreakState",
        id: string,
        owner: string,
        personStateID: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streak:  {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        },
        createdAt: string,
        updatedAt: string,
        streakStateStreakId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    previous?:  {
      __typename: "PreviousEventState",
      id: string,
      owner: string,
      score: number,
      streaks:  Array< {
        __typename: "PreviousStreakState",
        id: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streakID: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    personEventStatePreviousId?: string | null,
  } | null,
};

export type DeletePersonEventStateMutationVariables = {
  input: DeletePersonEventStateInput,
  condition?: ModelPersonEventStateConditionInput | null,
};

export type DeletePersonEventStateMutation = {
  deletePersonEventState?:  {
    __typename: "PersonEventState",
    id: string,
    owner: string,
    personID: string,
    eventID: string,
    score: number,
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    streaks?:  {
      __typename: "ModelStreakStateConnection",
      items:  Array< {
        __typename: "StreakState",
        id: string,
        owner: string,
        personStateID: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streak:  {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        },
        createdAt: string,
        updatedAt: string,
        streakStateStreakId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    previous?:  {
      __typename: "PreviousEventState",
      id: string,
      owner: string,
      score: number,
      streaks:  Array< {
        __typename: "PreviousStreakState",
        id: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streakID: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    personEventStatePreviousId?: string | null,
  } | null,
};

export type CreateStreakStateMutationVariables = {
  input: CreateStreakStateInput,
  condition?: ModelStreakStateConditionInput | null,
};

export type CreateStreakStateMutation = {
  createStreakState?:  {
    __typename: "StreakState",
    id: string,
    owner: string,
    personStateID: string,
    currentState: Array< string >,
    prizeState?: Array< string > | null,
    nrOfTimesCompleted: number,
    streak:  {
      __typename: "Streak",
      id: string,
      owner: string,
      name: string,
      isRepeatable: boolean,
      promotions: Array< Promotions >,
      eventID: string,
      promoPrice: number,
      promoPair:  {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      },
      set?:  {
        __typename: "ModelPairStreakConnection",
        items:  Array< {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      streakPromoPairId: string,
    },
    createdAt: string,
    updatedAt: string,
    streakStateStreakId: string,
  } | null,
};

export type UpdateStreakStateMutationVariables = {
  input: UpdateStreakStateInput,
  condition?: ModelStreakStateConditionInput | null,
};

export type UpdateStreakStateMutation = {
  updateStreakState?:  {
    __typename: "StreakState",
    id: string,
    owner: string,
    personStateID: string,
    currentState: Array< string >,
    prizeState?: Array< string > | null,
    nrOfTimesCompleted: number,
    streak:  {
      __typename: "Streak",
      id: string,
      owner: string,
      name: string,
      isRepeatable: boolean,
      promotions: Array< Promotions >,
      eventID: string,
      promoPrice: number,
      promoPair:  {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      },
      set?:  {
        __typename: "ModelPairStreakConnection",
        items:  Array< {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      streakPromoPairId: string,
    },
    createdAt: string,
    updatedAt: string,
    streakStateStreakId: string,
  } | null,
};

export type DeleteStreakStateMutationVariables = {
  input: DeleteStreakStateInput,
  condition?: ModelStreakStateConditionInput | null,
};

export type DeleteStreakStateMutation = {
  deleteStreakState?:  {
    __typename: "StreakState",
    id: string,
    owner: string,
    personStateID: string,
    currentState: Array< string >,
    prizeState?: Array< string > | null,
    nrOfTimesCompleted: number,
    streak:  {
      __typename: "Streak",
      id: string,
      owner: string,
      name: string,
      isRepeatable: boolean,
      promotions: Array< Promotions >,
      eventID: string,
      promoPrice: number,
      promoPair:  {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      },
      set?:  {
        __typename: "ModelPairStreakConnection",
        items:  Array< {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      streakPromoPairId: string,
    },
    createdAt: string,
    updatedAt: string,
    streakStateStreakId: string,
  } | null,
};

export type CreatePreviousEventStateMutationVariables = {
  input: CreatePreviousEventStateInput,
  condition?: ModelPreviousEventStateConditionInput | null,
};

export type CreatePreviousEventStateMutation = {
  createPreviousEventState?:  {
    __typename: "PreviousEventState",
    id: string,
    owner: string,
    score: number,
    streaks:  Array< {
      __typename: "PreviousStreakState",
      id: string,
      currentState: Array< string >,
      prizeState?: Array< string > | null,
      nrOfTimesCompleted: number,
      streakID: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePreviousEventStateMutationVariables = {
  input: UpdatePreviousEventStateInput,
  condition?: ModelPreviousEventStateConditionInput | null,
};

export type UpdatePreviousEventStateMutation = {
  updatePreviousEventState?:  {
    __typename: "PreviousEventState",
    id: string,
    owner: string,
    score: number,
    streaks:  Array< {
      __typename: "PreviousStreakState",
      id: string,
      currentState: Array< string >,
      prizeState?: Array< string > | null,
      nrOfTimesCompleted: number,
      streakID: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePreviousEventStateMutationVariables = {
  input: DeletePreviousEventStateInput,
  condition?: ModelPreviousEventStateConditionInput | null,
};

export type DeletePreviousEventStateMutation = {
  deletePreviousEventState?:  {
    __typename: "PreviousEventState",
    id: string,
    owner: string,
    score: number,
    streaks:  Array< {
      __typename: "PreviousStreakState",
      id: string,
      currentState: Array< string >,
      prizeState?: Array< string > | null,
      nrOfTimesCompleted: number,
      streakID: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTicketMutationVariables = {
  input: CreateTicketInput,
  condition?: ModelTicketConditionInput | null,
};

export type CreateTicketMutation = {
  createTicket?:  {
    __typename: "Ticket",
    id: string,
    owner: string,
    name?: string | null,
    initialStock?: number | null,
    stock?: number | null,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    validDates:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    eventName: string,
    metadata?: string | null,
    eventID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTicketMutationVariables = {
  input: UpdateTicketInput,
  condition?: ModelTicketConditionInput | null,
};

export type UpdateTicketMutation = {
  updateTicket?:  {
    __typename: "Ticket",
    id: string,
    owner: string,
    name?: string | null,
    initialStock?: number | null,
    stock?: number | null,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    validDates:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    eventName: string,
    metadata?: string | null,
    eventID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTicketMutationVariables = {
  input: DeleteTicketInput,
  condition?: ModelTicketConditionInput | null,
};

export type DeleteTicketMutation = {
  deleteTicket?:  {
    __typename: "Ticket",
    id: string,
    owner: string,
    name?: string | null,
    initialStock?: number | null,
    stock?: number | null,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    validDates:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    eventName: string,
    metadata?: string | null,
    eventID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePosterMutationVariables = {
  input: CreatePosterInput,
  condition?: ModelPosterConditionInput | null,
};

export type CreatePosterMutation = {
  createPoster?:  {
    __typename: "Poster",
    id: string,
    owner: string,
    artists:  Array< {
      __typename: "Artist",
      name: string,
      performanceDateTime: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePosterMutationVariables = {
  input: UpdatePosterInput,
  condition?: ModelPosterConditionInput | null,
};

export type UpdatePosterMutation = {
  updatePoster?:  {
    __typename: "Poster",
    id: string,
    owner: string,
    artists:  Array< {
      __typename: "Artist",
      name: string,
      performanceDateTime: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePosterMutationVariables = {
  input: DeletePosterInput,
  condition?: ModelPosterConditionInput | null,
};

export type DeletePosterMutation = {
  deletePoster?:  {
    __typename: "Poster",
    id: string,
    owner: string,
    artists:  Array< {
      __typename: "Artist",
      name: string,
      performanceDateTime: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTicketTransactionMutationVariables = {
  input: CreateTicketTransactionInput,
  condition?: ModelTicketTransactionConditionInput | null,
};

export type CreateTicketTransactionMutation = {
  createTicketTransaction?:  {
    __typename: "TicketTransaction",
    id: string,
    owner: string,
    date: string,
    fee?: number | null,
    price: number,
    personID: string,
    eventID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    personTicket?:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null,
    promoter?:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    ticketTransactionTicketId: string,
    ticketTransactionRefundId?: string | null,
    ticketTransactionPersonTicketId?: string | null,
    ticketTransactionPromoterId?: string | null,
  } | null,
};

export type UpdateTicketTransactionMutationVariables = {
  input: UpdateTicketTransactionInput,
  condition?: ModelTicketTransactionConditionInput | null,
};

export type UpdateTicketTransactionMutation = {
  updateTicketTransaction?:  {
    __typename: "TicketTransaction",
    id: string,
    owner: string,
    date: string,
    fee?: number | null,
    price: number,
    personID: string,
    eventID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    personTicket?:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null,
    promoter?:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    ticketTransactionTicketId: string,
    ticketTransactionRefundId?: string | null,
    ticketTransactionPersonTicketId?: string | null,
    ticketTransactionPromoterId?: string | null,
  } | null,
};

export type DeleteTicketTransactionMutationVariables = {
  input: DeleteTicketTransactionInput,
  condition?: ModelTicketTransactionConditionInput | null,
};

export type DeleteTicketTransactionMutation = {
  deleteTicketTransaction?:  {
    __typename: "TicketTransaction",
    id: string,
    owner: string,
    date: string,
    fee?: number | null,
    price: number,
    personID: string,
    eventID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    personTicket?:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null,
    promoter?:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    ticketTransactionTicketId: string,
    ticketTransactionRefundId?: string | null,
    ticketTransactionPersonTicketId?: string | null,
    ticketTransactionPromoterId?: string | null,
  } | null,
};

export type CreateWalletTransactionMutationVariables = {
  input: CreateWalletTransactionInput,
  condition?: ModelWalletTransactionConditionInput | null,
};

export type CreateWalletTransactionMutation = {
  createWalletTransaction?:  {
    __typename: "WalletTransaction",
    id: string,
    owner: string,
    date: string,
    amount: number,
    method?: PaymentMethod | null,
    status: PaymentStatus,
    metadata?: string | null,
    sourceID?: string | null,
    transactionID: string,
    personID: string,
    provider?: PaymentProviders | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWalletTransactionMutationVariables = {
  input: UpdateWalletTransactionInput,
  condition?: ModelWalletTransactionConditionInput | null,
};

export type UpdateWalletTransactionMutation = {
  updateWalletTransaction?:  {
    __typename: "WalletTransaction",
    id: string,
    owner: string,
    date: string,
    amount: number,
    method?: PaymentMethod | null,
    status: PaymentStatus,
    metadata?: string | null,
    sourceID?: string | null,
    transactionID: string,
    personID: string,
    provider?: PaymentProviders | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWalletTransactionMutationVariables = {
  input: DeleteWalletTransactionInput,
  condition?: ModelWalletTransactionConditionInput | null,
};

export type DeleteWalletTransactionMutation = {
  deleteWalletTransaction?:  {
    __typename: "WalletTransaction",
    id: string,
    owner: string,
    date: string,
    amount: number,
    method?: PaymentMethod | null,
    status: PaymentStatus,
    metadata?: string | null,
    sourceID?: string | null,
    transactionID: string,
    personID: string,
    provider?: PaymentProviders | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateConsumableTransactionMutationVariables = {
  input: CreateConsumableTransactionInput,
  condition?: ModelConsumableTransactionConditionInput | null,
};

export type CreateConsumableTransactionMutation = {
  createConsumableTransaction?:  {
    __typename: "ConsumableTransaction",
    id: string,
    owner: string,
    date: string,
    isRefunded?: boolean | null,
    personHandle?: string | null,
    price: number,
    collaboratorID: string,
    personID: string,
    eventID: string,
    cart?:  {
      __typename: "ModelPairTransactionConnection",
      items:  Array< {
        __typename: "PairTransaction",
        id: string,
        owner: string,
        personHandle?: string | null,
        transactionID: string,
        amount: number,
        isRefunded?: boolean | null,
        wasUsedInPromo: boolean,
        streakID?: string | null,
        wasPersonConsumable: boolean,
        pricePaid: number,
        whoGaveIt?: string | null,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairTransactionConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    person?:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateConsumableTransactionMutationVariables = {
  input: UpdateConsumableTransactionInput,
  condition?: ModelConsumableTransactionConditionInput | null,
};

export type UpdateConsumableTransactionMutation = {
  updateConsumableTransaction?:  {
    __typename: "ConsumableTransaction",
    id: string,
    owner: string,
    date: string,
    isRefunded?: boolean | null,
    personHandle?: string | null,
    price: number,
    collaboratorID: string,
    personID: string,
    eventID: string,
    cart?:  {
      __typename: "ModelPairTransactionConnection",
      items:  Array< {
        __typename: "PairTransaction",
        id: string,
        owner: string,
        personHandle?: string | null,
        transactionID: string,
        amount: number,
        isRefunded?: boolean | null,
        wasUsedInPromo: boolean,
        streakID?: string | null,
        wasPersonConsumable: boolean,
        pricePaid: number,
        whoGaveIt?: string | null,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairTransactionConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    person?:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteConsumableTransactionMutationVariables = {
  input: DeleteConsumableTransactionInput,
  condition?: ModelConsumableTransactionConditionInput | null,
};

export type DeleteConsumableTransactionMutation = {
  deleteConsumableTransaction?:  {
    __typename: "ConsumableTransaction",
    id: string,
    owner: string,
    date: string,
    isRefunded?: boolean | null,
    personHandle?: string | null,
    price: number,
    collaboratorID: string,
    personID: string,
    eventID: string,
    cart?:  {
      __typename: "ModelPairTransactionConnection",
      items:  Array< {
        __typename: "PairTransaction",
        id: string,
        owner: string,
        personHandle?: string | null,
        transactionID: string,
        amount: number,
        isRefunded?: boolean | null,
        wasUsedInPromo: boolean,
        streakID?: string | null,
        wasPersonConsumable: boolean,
        pricePaid: number,
        whoGaveIt?: string | null,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairTransactionConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    person?:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePersonTicketMutationVariables = {
  input: CreatePersonTicketInput,
  condition?: ModelPersonTicketConditionInput | null,
};

export type CreatePersonTicketMutation = {
  createPersonTicket?:  {
    __typename: "PersonTicket",
    id: string,
    owner: string,
    readDates: Array< string >,
    privacy: Privacy,
    eventID: string,
    collaboratorID?: string | null,
    personID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    transaction?:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personTicketTicketId: string,
    personTicketRefundId?: string | null,
    personTicketTransactionId?: string | null,
  } | null,
};

export type UpdatePersonTicketMutationVariables = {
  input: UpdatePersonTicketInput,
  condition?: ModelPersonTicketConditionInput | null,
};

export type UpdatePersonTicketMutation = {
  updatePersonTicket?:  {
    __typename: "PersonTicket",
    id: string,
    owner: string,
    readDates: Array< string >,
    privacy: Privacy,
    eventID: string,
    collaboratorID?: string | null,
    personID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    transaction?:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personTicketTicketId: string,
    personTicketRefundId?: string | null,
    personTicketTransactionId?: string | null,
  } | null,
};

export type DeletePersonTicketMutationVariables = {
  input: DeletePersonTicketInput,
  condition?: ModelPersonTicketConditionInput | null,
};

export type DeletePersonTicketMutation = {
  deletePersonTicket?:  {
    __typename: "PersonTicket",
    id: string,
    owner: string,
    readDates: Array< string >,
    privacy: Privacy,
    eventID: string,
    collaboratorID?: string | null,
    personID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    transaction?:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personTicketTicketId: string,
    personTicketRefundId?: string | null,
    personTicketTransactionId?: string | null,
  } | null,
};

export type CreateTicketRefundMutationVariables = {
  input: CreateTicketRefundInput,
  condition?: ModelTicketRefundConditionInput | null,
};

export type CreateTicketRefundMutation = {
  createTicketRefund?:  {
    __typename: "TicketRefund",
    id: string,
    owner: string,
    refundedAmount: number,
    refundedPercentage: number,
    ticketID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    personTicket:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    },
    transaction:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    },
    eventID: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
    ticketRefundPersonTicketId: string,
    ticketRefundTransactionId: string,
  } | null,
};

export type UpdateTicketRefundMutationVariables = {
  input: UpdateTicketRefundInput,
  condition?: ModelTicketRefundConditionInput | null,
};

export type UpdateTicketRefundMutation = {
  updateTicketRefund?:  {
    __typename: "TicketRefund",
    id: string,
    owner: string,
    refundedAmount: number,
    refundedPercentage: number,
    ticketID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    personTicket:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    },
    transaction:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    },
    eventID: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
    ticketRefundPersonTicketId: string,
    ticketRefundTransactionId: string,
  } | null,
};

export type DeleteTicketRefundMutationVariables = {
  input: DeleteTicketRefundInput,
  condition?: ModelTicketRefundConditionInput | null,
};

export type DeleteTicketRefundMutation = {
  deleteTicketRefund?:  {
    __typename: "TicketRefund",
    id: string,
    owner: string,
    refundedAmount: number,
    refundedPercentage: number,
    ticketID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    personTicket:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    },
    transaction:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    },
    eventID: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
    ticketRefundPersonTicketId: string,
    ticketRefundTransactionId: string,
  } | null,
};

export type CreatePersonMutationVariables = {
  input: CreatePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type CreatePersonMutation = {
  createPerson?:  {
    __typename: "Person",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    phone: string,
    dateOfBirth: string,
    wallet: number,
    privacy: Privacy,
    handle?: string | null,
    currentEventID?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      zipCode: string,
      city: string,
      country: string,
    } | null,
    auxIdentity?:  {
      __typename: "AuxiliaryIdentity",
      id: string,
      owner: string,
      validDate: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelPairPersonConnection",
      items:  Array< {
        __typename: "PairPerson",
        id: string,
        owner: string,
        personID: string,
        whoGaveIt: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        eventID: string,
        pricePaid?: number | null,
        createdAt: string,
        updatedAt: string,
        pairPersonConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyWallet?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    friends?:  {
      __typename: "ModelFriendConnection",
      items:  Array< {
        __typename: "Friend",
        id: string,
        personID: string,
        friendID: string,
        owner: string,
        friend:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        request: FriendRequest,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personAuxIdentityId?: string | null,
    personAvatarId?: string | null,
  } | null,
};

export type UpdatePersonMutationVariables = {
  input: UpdatePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type UpdatePersonMutation = {
  updatePerson?:  {
    __typename: "Person",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    phone: string,
    dateOfBirth: string,
    wallet: number,
    privacy: Privacy,
    handle?: string | null,
    currentEventID?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      zipCode: string,
      city: string,
      country: string,
    } | null,
    auxIdentity?:  {
      __typename: "AuxiliaryIdentity",
      id: string,
      owner: string,
      validDate: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelPairPersonConnection",
      items:  Array< {
        __typename: "PairPerson",
        id: string,
        owner: string,
        personID: string,
        whoGaveIt: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        eventID: string,
        pricePaid?: number | null,
        createdAt: string,
        updatedAt: string,
        pairPersonConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyWallet?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    friends?:  {
      __typename: "ModelFriendConnection",
      items:  Array< {
        __typename: "Friend",
        id: string,
        personID: string,
        friendID: string,
        owner: string,
        friend:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        request: FriendRequest,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personAuxIdentityId?: string | null,
    personAvatarId?: string | null,
  } | null,
};

export type DeletePersonMutationVariables = {
  input: DeletePersonInput,
  condition?: ModelPersonConditionInput | null,
};

export type DeletePersonMutation = {
  deletePerson?:  {
    __typename: "Person",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    phone: string,
    dateOfBirth: string,
    wallet: number,
    privacy: Privacy,
    handle?: string | null,
    currentEventID?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      zipCode: string,
      city: string,
      country: string,
    } | null,
    auxIdentity?:  {
      __typename: "AuxiliaryIdentity",
      id: string,
      owner: string,
      validDate: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelPairPersonConnection",
      items:  Array< {
        __typename: "PairPerson",
        id: string,
        owner: string,
        personID: string,
        whoGaveIt: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        eventID: string,
        pricePaid?: number | null,
        createdAt: string,
        updatedAt: string,
        pairPersonConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyWallet?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    friends?:  {
      __typename: "ModelFriendConnection",
      items:  Array< {
        __typename: "Friend",
        id: string,
        personID: string,
        friendID: string,
        owner: string,
        friend:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        request: FriendRequest,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personAuxIdentityId?: string | null,
    personAvatarId?: string | null,
  } | null,
};

export type CreateAuxiliaryIdentityMutationVariables = {
  input: CreateAuxiliaryIdentityInput,
  condition?: ModelAuxiliaryIdentityConditionInput | null,
};

export type CreateAuxiliaryIdentityMutation = {
  createAuxiliaryIdentity?:  {
    __typename: "AuxiliaryIdentity",
    id: string,
    owner: string,
    validDate: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAuxiliaryIdentityMutationVariables = {
  input: UpdateAuxiliaryIdentityInput,
  condition?: ModelAuxiliaryIdentityConditionInput | null,
};

export type UpdateAuxiliaryIdentityMutation = {
  updateAuxiliaryIdentity?:  {
    __typename: "AuxiliaryIdentity",
    id: string,
    owner: string,
    validDate: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAuxiliaryIdentityMutationVariables = {
  input: DeleteAuxiliaryIdentityInput,
  condition?: ModelAuxiliaryIdentityConditionInput | null,
};

export type DeleteAuxiliaryIdentityMutation = {
  deleteAuxiliaryIdentity?:  {
    __typename: "AuxiliaryIdentity",
    id: string,
    owner: string,
    validDate: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFriendMutationVariables = {
  input: CreateFriendInput,
  condition?: ModelFriendConditionInput | null,
};

export type CreateFriendMutation = {
  createFriend?:  {
    __typename: "Friend",
    id: string,
    personID: string,
    friendID: string,
    owner: string,
    friend:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    request: FriendRequest,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFriendMutationVariables = {
  input: UpdateFriendInput,
  condition?: ModelFriendConditionInput | null,
};

export type UpdateFriendMutation = {
  updateFriend?:  {
    __typename: "Friend",
    id: string,
    personID: string,
    friendID: string,
    owner: string,
    friend:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    request: FriendRequest,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFriendMutationVariables = {
  input: DeleteFriendInput,
  condition?: ModelFriendConditionInput | null,
};

export type DeleteFriendMutation = {
  deleteFriend?:  {
    __typename: "Friend",
    id: string,
    personID: string,
    friendID: string,
    owner: string,
    friend:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    request: FriendRequest,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateConsumableMutationVariables = {
  input: CreateConsumableInput,
  condition?: ModelConsumableConditionInput | null,
};

export type CreateConsumableMutation = {
  createConsumable?:  {
    __typename: "Consumable",
    id: string,
    owner: string,
    type: ConsumableType,
    size: ConsumableSize,
    name: string,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    companyID?: string | null,
    eventID: string,
    smallOrLargeRef?:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    consumableSmallOrLargeRefId?: string | null,
  } | null,
};

export type UpdateConsumableMutationVariables = {
  input: UpdateConsumableInput,
  condition?: ModelConsumableConditionInput | null,
};

export type UpdateConsumableMutation = {
  updateConsumable?:  {
    __typename: "Consumable",
    id: string,
    owner: string,
    type: ConsumableType,
    size: ConsumableSize,
    name: string,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    companyID?: string | null,
    eventID: string,
    smallOrLargeRef?:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    consumableSmallOrLargeRefId?: string | null,
  } | null,
};

export type DeleteConsumableMutationVariables = {
  input: DeleteConsumableInput,
  condition?: ModelConsumableConditionInput | null,
};

export type DeleteConsumableMutation = {
  deleteConsumable?:  {
    __typename: "Consumable",
    id: string,
    owner: string,
    type: ConsumableType,
    size: ConsumableSize,
    name: string,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    companyID?: string | null,
    eventID: string,
    smallOrLargeRef?:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    consumableSmallOrLargeRefId?: string | null,
  } | null,
};

export type CreateStreakMutationVariables = {
  input: CreateStreakInput,
  condition?: ModelStreakConditionInput | null,
};

export type CreateStreakMutation = {
  createStreak?:  {
    __typename: "Streak",
    id: string,
    owner: string,
    name: string,
    isRepeatable: boolean,
    promotions: Array< Promotions >,
    eventID: string,
    promoPrice: number,
    promoPair:  {
      __typename: "PairStreak",
      id: string,
      owner: string,
      streakID: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairStreakConsumableId: string,
    },
    set?:  {
      __typename: "ModelPairStreakConnection",
      items:  Array< {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    streakPromoPairId: string,
  } | null,
};

export type UpdateStreakMutationVariables = {
  input: UpdateStreakInput,
  condition?: ModelStreakConditionInput | null,
};

export type UpdateStreakMutation = {
  updateStreak?:  {
    __typename: "Streak",
    id: string,
    owner: string,
    name: string,
    isRepeatable: boolean,
    promotions: Array< Promotions >,
    eventID: string,
    promoPrice: number,
    promoPair:  {
      __typename: "PairStreak",
      id: string,
      owner: string,
      streakID: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairStreakConsumableId: string,
    },
    set?:  {
      __typename: "ModelPairStreakConnection",
      items:  Array< {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    streakPromoPairId: string,
  } | null,
};

export type DeleteStreakMutationVariables = {
  input: DeleteStreakInput,
  condition?: ModelStreakConditionInput | null,
};

export type DeleteStreakMutation = {
  deleteStreak?:  {
    __typename: "Streak",
    id: string,
    owner: string,
    name: string,
    isRepeatable: boolean,
    promotions: Array< Promotions >,
    eventID: string,
    promoPrice: number,
    promoPair:  {
      __typename: "PairStreak",
      id: string,
      owner: string,
      streakID: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairStreakConsumableId: string,
    },
    set?:  {
      __typename: "ModelPairStreakConnection",
      items:  Array< {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    streakPromoPairId: string,
  } | null,
};

export type CreateImageMutationVariables = {
  input: CreateImageInput,
  condition?: ModelImageConditionInput | null,
};

export type CreateImageMutation = {
  createImage?:  {
    __typename: "Image",
    id: string,
    owner: string,
    eventID?: string | null,
    filename: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateImageMutationVariables = {
  input: UpdateImageInput,
  condition?: ModelImageConditionInput | null,
};

export type UpdateImageMutation = {
  updateImage?:  {
    __typename: "Image",
    id: string,
    owner: string,
    eventID?: string | null,
    filename: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteImageMutationVariables = {
  input: DeleteImageInput,
  condition?: ModelImageConditionInput | null,
};

export type DeleteImageMutation = {
  deleteImage?:  {
    __typename: "Image",
    id: string,
    owner: string,
    eventID?: string | null,
    filename: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePairPersonMutationVariables = {
  input: CreatePairPersonInput,
  condition?: ModelPairPersonConditionInput | null,
};

export type CreatePairPersonMutation = {
  createPairPerson?:  {
    __typename: "PairPerson",
    id: string,
    owner: string,
    personID: string,
    whoGaveIt: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    eventID: string,
    pricePaid?: number | null,
    createdAt: string,
    updatedAt: string,
    pairPersonConsumableId: string,
  } | null,
};

export type UpdatePairPersonMutationVariables = {
  input: UpdatePairPersonInput,
  condition?: ModelPairPersonConditionInput | null,
};

export type UpdatePairPersonMutation = {
  updatePairPerson?:  {
    __typename: "PairPerson",
    id: string,
    owner: string,
    personID: string,
    whoGaveIt: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    eventID: string,
    pricePaid?: number | null,
    createdAt: string,
    updatedAt: string,
    pairPersonConsumableId: string,
  } | null,
};

export type DeletePairPersonMutationVariables = {
  input: DeletePairPersonInput,
  condition?: ModelPairPersonConditionInput | null,
};

export type DeletePairPersonMutation = {
  deletePairPerson?:  {
    __typename: "PairPerson",
    id: string,
    owner: string,
    personID: string,
    whoGaveIt: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    eventID: string,
    pricePaid?: number | null,
    createdAt: string,
    updatedAt: string,
    pairPersonConsumableId: string,
  } | null,
};

export type CreatePairStreakMutationVariables = {
  input: CreatePairStreakInput,
  condition?: ModelPairStreakConditionInput | null,
};

export type CreatePairStreakMutation = {
  createPairStreak?:  {
    __typename: "PairStreak",
    id: string,
    owner: string,
    streakID: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairStreakConsumableId: string,
  } | null,
};

export type UpdatePairStreakMutationVariables = {
  input: UpdatePairStreakInput,
  condition?: ModelPairStreakConditionInput | null,
};

export type UpdatePairStreakMutation = {
  updatePairStreak?:  {
    __typename: "PairStreak",
    id: string,
    owner: string,
    streakID: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairStreakConsumableId: string,
  } | null,
};

export type DeletePairStreakMutationVariables = {
  input: DeletePairStreakInput,
  condition?: ModelPairStreakConditionInput | null,
};

export type DeletePairStreakMutation = {
  deletePairStreak?:  {
    __typename: "PairStreak",
    id: string,
    owner: string,
    streakID: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairStreakConsumableId: string,
  } | null,
};

export type CreatePairTransactionMutationVariables = {
  input: CreatePairTransactionInput,
  condition?: ModelPairTransactionConditionInput | null,
};

export type CreatePairTransactionMutation = {
  createPairTransaction?:  {
    __typename: "PairTransaction",
    id: string,
    owner: string,
    personHandle?: string | null,
    transactionID: string,
    amount: number,
    isRefunded?: boolean | null,
    wasUsedInPromo: boolean,
    streakID?: string | null,
    wasPersonConsumable: boolean,
    pricePaid: number,
    whoGaveIt?: string | null,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairTransactionConsumableId: string,
  } | null,
};

export type UpdatePairTransactionMutationVariables = {
  input: UpdatePairTransactionInput,
  condition?: ModelPairTransactionConditionInput | null,
};

export type UpdatePairTransactionMutation = {
  updatePairTransaction?:  {
    __typename: "PairTransaction",
    id: string,
    owner: string,
    personHandle?: string | null,
    transactionID: string,
    amount: number,
    isRefunded?: boolean | null,
    wasUsedInPromo: boolean,
    streakID?: string | null,
    wasPersonConsumable: boolean,
    pricePaid: number,
    whoGaveIt?: string | null,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairTransactionConsumableId: string,
  } | null,
};

export type DeletePairTransactionMutationVariables = {
  input: DeletePairTransactionInput,
  condition?: ModelPairTransactionConditionInput | null,
};

export type DeletePairTransactionMutation = {
  deletePairTransaction?:  {
    __typename: "PairTransaction",
    id: string,
    owner: string,
    personHandle?: string | null,
    transactionID: string,
    amount: number,
    isRefunded?: boolean | null,
    wasUsedInPromo: boolean,
    streakID?: string | null,
    wasPersonConsumable: boolean,
    pricePaid: number,
    whoGaveIt?: string | null,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairTransactionConsumableId: string,
  } | null,
};

export type CreateCollaboratorEventConnectionMutationVariables = {
  input: CreateCollaboratorEventConnectionInput,
  condition?: ModelCollaboratorEventConnectionConditionInput | null,
};

export type CreateCollaboratorEventConnectionMutation = {
  createCollaboratorEventConnection?:  {
    __typename: "CollaboratorEventConnection",
    id: string,
    collaboratorID: string,
    eventID: string,
    collaborator:  {
      __typename: "Collaborator",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      handle?: string | null,
      phone: string,
      activity?: string | null,
      companyID: string,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      readTickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      chargeHistory?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      collaboratorAvatarId?: string | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCollaboratorEventConnectionMutationVariables = {
  input: UpdateCollaboratorEventConnectionInput,
  condition?: ModelCollaboratorEventConnectionConditionInput | null,
};

export type UpdateCollaboratorEventConnectionMutation = {
  updateCollaboratorEventConnection?:  {
    __typename: "CollaboratorEventConnection",
    id: string,
    collaboratorID: string,
    eventID: string,
    collaborator:  {
      __typename: "Collaborator",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      handle?: string | null,
      phone: string,
      activity?: string | null,
      companyID: string,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      readTickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      chargeHistory?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      collaboratorAvatarId?: string | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCollaboratorEventConnectionMutationVariables = {
  input: DeleteCollaboratorEventConnectionInput,
  condition?: ModelCollaboratorEventConnectionConditionInput | null,
};

export type DeleteCollaboratorEventConnectionMutation = {
  deleteCollaboratorEventConnection?:  {
    __typename: "CollaboratorEventConnection",
    id: string,
    collaboratorID: string,
    eventID: string,
    collaborator:  {
      __typename: "Collaborator",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      handle?: string | null,
      phone: string,
      activity?: string | null,
      companyID: string,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      readTickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      chargeHistory?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      collaboratorAvatarId?: string | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePromoterEventConnectionMutationVariables = {
  input: CreatePromoterEventConnectionInput,
  condition?: ModelPromoterEventConnectionConditionInput | null,
};

export type CreatePromoterEventConnectionMutation = {
  createPromoterEventConnection?:  {
    __typename: "PromoterEventConnection",
    id: string,
    promoterID: string,
    eventID: string,
    promoter:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePromoterEventConnectionMutationVariables = {
  input: UpdatePromoterEventConnectionInput,
  condition?: ModelPromoterEventConnectionConditionInput | null,
};

export type UpdatePromoterEventConnectionMutation = {
  updatePromoterEventConnection?:  {
    __typename: "PromoterEventConnection",
    id: string,
    promoterID: string,
    eventID: string,
    promoter:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePromoterEventConnectionMutationVariables = {
  input: DeletePromoterEventConnectionInput,
  condition?: ModelPromoterEventConnectionConditionInput | null,
};

export type DeletePromoterEventConnectionMutation = {
  deletePromoterEventConnection?:  {
    __typename: "PromoterEventConnection",
    id: string,
    promoterID: string,
    eventID: string,
    promoter:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type TicketPurchaseMutationVariables = {
  input: TicketPurchaseInput,
};

export type TicketPurchaseMutation = {
  ticketPurchase?:  {
    __typename: "Response",
    statusCode?: number | null,
    headers?: string | null,
    body?: string | null,
    status?: Status | null,
  } | null,
};

export type ConsumablePurchaseMutationVariables = {
  input: ConsumablePurchaseInput,
};

export type ConsumablePurchaseMutation = {
  consumablePurchase?:  {
    __typename: "Response",
    statusCode?: number | null,
    headers?: string | null,
    body?: string | null,
    status?: Status | null,
  } | null,
};

export type ConsumablePurchaseV2MutationVariables = {
  input: ConsumablePurchaseInput,
};

export type ConsumablePurchaseV2Mutation = {
  consumablePurchaseV2?:  {
    __typename: "ConsumablePurchaseResponse",
    reply?:  {
      __typename: "Reply",
      status?: boolean | null,
      error?: string | null,
      metadata?: string | null,
    } | null,
    personName?: string | null,
    personHandle?: string | null,
    personWallet?: number | null,
    transactionID?: string | null,
  } | null,
};

export type HasTicketMutationVariables = {
  input: HasTicketInput,
};

export type HasTicketMutation = {
  hasTicket?:  {
    __typename: "Response",
    statusCode?: number | null,
    headers?: string | null,
    body?: string | null,
    status?: Status | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "Response",
    statusCode?: number | null,
    headers?: string | null,
    body?: string | null,
    status?: Status | null,
  } | null,
};

export type ScannerEncryptionMutationVariables = {
  input: ScannerEncryptionInput,
};

export type ScannerEncryptionMutation = {
  scannerEncryption?: string | null,
};

export type ScannerDecryptionMutationVariables = {
  input: ScannerDecryptionInput,
};

export type ScannerDecryptionMutation = {
  scannerDecryption?: string | null,
};

export type SendConsumableMutationVariables = {
  input: SendConsumableInput,
};

export type SendConsumableMutation = {
  sendConsumable?:  {
    __typename: "Response",
    statusCode?: number | null,
    headers?: string | null,
    body?: string | null,
    status?: Status | null,
  } | null,
};

export type CheckoutMBwayMutationVariables = {
  input: CheckoutMBWayInput,
};

export type CheckoutMBwayMutation = {
  checkoutMBway?:  {
    __typename: "CheckoutMBwayResponse",
    formContext?: string | null,
    transactionID?: string | null,
    amount?: number | null,
  } | null,
};

export type DoStatusMBWayMutationVariables = {
  input: StatusMBWayInput,
};

export type DoStatusMBWayMutation = {
  doStatusMBWay?: boolean | null,
};

export type DoFriendRequestMutationVariables = {
  input: FriendRequestInput,
};

export type DoFriendRequestMutation = {
  doFriendRequest?:  {
    __typename: "Response",
    statusCode?: number | null,
    headers?: string | null,
    body?: string | null,
    status?: Status | null,
  } | null,
};

export type DoAddFriendWithIDMutationVariables = {
  input: AddFriendWithIDInput,
};

export type DoAddFriendWithIDMutation = {
  doAddFriendWithID?:  {
    __typename: "Response",
    statusCode?: number | null,
    headers?: string | null,
    body?: string | null,
    status?: Status | null,
  } | null,
};

export type DoLinkPersonWithAuxMutationVariables = {
  input: LinkPersonWithAuxInput,
};

export type DoLinkPersonWithAuxMutation = {
  doLinkPersonWithAux?: boolean | null,
};

export type DoSibsPingMutationVariables = {
  input: SibsPingInput,
};

export type DoSibsPingMutation = {
  doSibsPing?: string | null,
};

export type ScannerEncryptionV2MutationVariables = {
  input: ScannerEncryptionInput,
};

export type ScannerEncryptionV2Mutation = {
  scannerEncryptionV2?: string | null,
};

export type ScannerDecryptionV2MutationVariables = {
  input: ScannerDecryptionInput,
};

export type ScannerDecryptionV2Mutation = {
  scannerDecryptionV2?: string | null,
};

export type DoRefundTicketMutationVariables = {
  input: RefundTicketsInput,
};

export type DoRefundTicketMutation = {
  doRefundTicket?:  {
    __typename: "Reply",
    status?: boolean | null,
    error?: string | null,
    metadata?: string | null,
  } | null,
};

export type DoCheckMinimumVersionMutationVariables = {
  input?: CheckMinimumVersion | null,
};

export type DoCheckMinimumVersionMutation = {
  doCheckMinimumVersion?: boolean | null,
};

export type DoPaymentMBWayMutationVariables = {
  input?: DoPaymentMBWay | null,
};

export type DoPaymentMBWayMutation = {
  doPaymentMBWay?:  {
    __typename: "CheckoutMBwayResponse",
    formContext?: string | null,
    transactionID?: string | null,
    amount?: number | null,
  } | null,
};

export type DoPingMBWayMutationVariables = {
  input: PingMBWayInput,
};

export type DoPingMBWayMutation = {
  doPingMBWay?: string | null,
};

export type DoConsumableRollbackMutationVariables = {
  input: ConsumablePurchaseRollbackInput,
};

export type DoConsumableRollbackMutation = {
  doConsumableRollback?:  {
    __typename: "ConsumablePurchaseRollbackResponse",
    reply?:  {
      __typename: "Reply",
      status?: boolean | null,
      error?: string | null,
      metadata?: string | null,
    } | null,
    personName?: string | null,
    personHandle?: string | null,
    personWallet?: number | null,
    transactionID?: string | null,
  } | null,
};

export type DoConsumableRefundMutationVariables = {
  input: ConsumableRefundInput,
};

export type DoConsumableRefundMutation = {
  doConsumableRefund?:  {
    __typename: "ConsumableRefundResponse",
    reply?:  {
      __typename: "Reply",
      status?: boolean | null,
      error?: string | null,
      metadata?: string | null,
    } | null,
    personName?: string | null,
    personHandle?: string | null,
    personWallet?: number | null,
    transactionID?: string | null,
  } | null,
};

export type NearbyEventsQueryVariables = {
  byLocation: LocationQueryInput,
  endDate: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type NearbyEventsQuery = {
  nearbyEvents?:  {
    __typename: "ModelNearbyEventConnection",
    items?:  Array< {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null > | null,
    total?: number | null,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    iban: string,
    initials: string,
    handle?: string | null,
    consumablesFee: number,
    ticketsFee: number,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorConnection",
      items:  Array< {
        __typename: "Collaborator",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        handle?: string | null,
        phone: string,
        activity?: string | null,
        companyID: string,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        readTickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        history?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        chargeHistory?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        collaboratorAvatarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterConnection",
      items:  Array< {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner: string,
        name?: string | null,
        internalID?: string | null,
        email?: string | null,
        phone: string,
        companyID: string,
        company:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyAvatarId?: string | null,
  } | null,
};

export type ListCompaniesQueryVariables = {
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompaniesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMemberQueryVariables = {
  id: string,
};

export type GetMemberQuery = {
  getMember?:  {
    __typename: "Member",
    id: string,
    owner: string,
    name?: string | null,
    internalID?: string | null,
    email?: string | null,
    phone: string,
    companyID: string,
    company:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMembersQueryVariables = {
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMembersQuery = {
  listMembers?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner: string,
      name?: string | null,
      internalID?: string | null,
      email?: string | null,
      phone: string,
      companyID: string,
      company:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberByPhoneQueryVariables = {
  phone: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberByPhoneQuery = {
  memberByPhone?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner: string,
      name?: string | null,
      internalID?: string | null,
      email?: string | null,
      phone: string,
      companyID: string,
      company:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MemberByCompanyQueryVariables = {
  companyID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MemberByCompanyQuery = {
  memberByCompany?:  {
    __typename: "ModelMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner: string,
      name?: string | null,
      internalID?: string | null,
      email?: string | null,
      phone: string,
      companyID: string,
      company:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchMembersQueryVariables = {
  filter?: SearchableMemberFilterInput | null,
  sort?: Array< SearchableMemberSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableMemberAggregationInput | null > | null,
};

export type SearchMembersQuery = {
  searchMembers?:  {
    __typename: "SearchableMemberConnection",
    items:  Array< {
      __typename: "Member",
      id: string,
      owner: string,
      name?: string | null,
      internalID?: string | null,
      email?: string | null,
      phone: string,
      companyID: string,
      company:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetCollaboratorQueryVariables = {
  id: string,
};

export type GetCollaboratorQuery = {
  getCollaborator?:  {
    __typename: "Collaborator",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    handle?: string | null,
    phone: string,
    activity?: string | null,
    companyID: string,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    readTickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chargeHistory?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    collaboratorAvatarId?: string | null,
  } | null,
};

export type ListCollaboratorsQueryVariables = {
  filter?: ModelCollaboratorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollaboratorsQuery = {
  listCollaborators?:  {
    __typename: "ModelCollaboratorConnection",
    items:  Array< {
      __typename: "Collaborator",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      handle?: string | null,
      phone: string,
      activity?: string | null,
      companyID: string,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      readTickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      chargeHistory?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      collaboratorAvatarId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CollaboratorByCompanyQueryVariables = {
  companyID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollaboratorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CollaboratorByCompanyQuery = {
  collaboratorByCompany?:  {
    __typename: "ModelCollaboratorConnection",
    items:  Array< {
      __typename: "Collaborator",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      handle?: string | null,
      phone: string,
      activity?: string | null,
      companyID: string,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      readTickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      chargeHistory?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      collaboratorAvatarId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPromoterQueryVariables = {
  id: string,
};

export type GetPromoterQuery = {
  getPromoter?:  {
    __typename: "Promoter",
    id: string,
    owner: string,
    name: string,
    companyID: string,
    events?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPromotersQueryVariables = {
  filter?: ModelPromoterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromotersQuery = {
  listPromoters?:  {
    __typename: "ModelPromoterConnection",
    items:  Array< {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PromoterByCompanyQueryVariables = {
  companyID: string,
  name?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromoterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PromoterByCompanyQuery = {
  promoterByCompany?:  {
    __typename: "ModelPromoterConnection",
    items:  Array< {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPromotersQueryVariables = {
  filter?: SearchablePromoterFilterInput | null,
  sort?: Array< SearchablePromoterSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePromoterAggregationInput | null > | null,
};

export type SearchPromotersQuery = {
  searchPromoters?:  {
    __typename: "SearchablePromoterConnection",
    items:  Array< {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    owner: string,
    name: string,
    promotions: Array< Promotions >,
    location:  {
      __typename: "Location",
      lon: number,
      lat: number,
    },
    addressAlias: string,
    companyID: string,
    description: string,
    startDate: string,
    endDate: string,
    date:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    maxCapacity?: number | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    } | null,
    banner:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    },
    poster?:  {
      __typename: "Poster",
      id: string,
      owner: string,
      artists:  Array< {
        __typename: "Artist",
        name: string,
        performanceDateTime: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    streaks?:  {
      __typename: "ModelStreakConnection",
      items:  Array< {
        __typename: "Streak",
        id: string,
        owner: string,
        name: string,
        isRepeatable: boolean,
        promotions: Array< Promotions >,
        eventID: string,
        promoPrice: number,
        promoPair:  {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        },
        set?:  {
          __typename: "ModelPairStreakConnection",
          items:  Array< {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        streakPromoPairId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelConsumableConnection",
      items:  Array< {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    peopleState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    eventBannerId: string,
    eventPosterId?: string | null,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EventsByCompanyByDateQueryVariables = {
  companyID: string,
  startDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EventsByCompanyByDateQuery = {
  eventsByCompanyByDate?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchEventsQueryVariables = {
  filter?: SearchableEventFilterInput | null,
  sort?: Array< SearchableEventSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableEventAggregationInput | null > | null,
};

export type SearchEventsQuery = {
  searchEvents?:  {
    __typename: "SearchableEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPersonEventStateQueryVariables = {
  id: string,
};

export type GetPersonEventStateQuery = {
  getPersonEventState?:  {
    __typename: "PersonEventState",
    id: string,
    owner: string,
    personID: string,
    eventID: string,
    score: number,
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    streaks?:  {
      __typename: "ModelStreakStateConnection",
      items:  Array< {
        __typename: "StreakState",
        id: string,
        owner: string,
        personStateID: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streak:  {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        },
        createdAt: string,
        updatedAt: string,
        streakStateStreakId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    previous?:  {
      __typename: "PreviousEventState",
      id: string,
      owner: string,
      score: number,
      streaks:  Array< {
        __typename: "PreviousStreakState",
        id: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streakID: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    personEventStatePreviousId?: string | null,
  } | null,
};

export type ListPersonEventStatesQueryVariables = {
  filter?: ModelPersonEventStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPersonEventStatesQuery = {
  listPersonEventStates?:  {
    __typename: "ModelPersonEventStateConnection",
    items:  Array< {
      __typename: "PersonEventState",
      id: string,
      owner: string,
      personID: string,
      eventID: string,
      score: number,
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      streaks?:  {
        __typename: "ModelStreakStateConnection",
        items:  Array< {
          __typename: "StreakState",
          id: string,
          owner: string,
          personStateID: string,
          currentState: Array< string >,
          prizeState?: Array< string > | null,
          nrOfTimesCompleted: number,
          streak:  {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          },
          createdAt: string,
          updatedAt: string,
          streakStateStreakId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      previous?:  {
        __typename: "PreviousEventState",
        id: string,
        owner: string,
        score: number,
        streaks:  Array< {
          __typename: "PreviousStreakState",
          id: string,
          currentState: Array< string >,
          prizeState?: Array< string > | null,
          nrOfTimesCompleted: number,
          streakID: string,
        } >,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      personEventStatePreviousId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PersonStateByPersonByScoreQueryVariables = {
  personID: string,
  score?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonEventStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PersonStateByPersonByScoreQuery = {
  personStateByPersonByScore?:  {
    __typename: "ModelPersonEventStateConnection",
    items:  Array< {
      __typename: "PersonEventState",
      id: string,
      owner: string,
      personID: string,
      eventID: string,
      score: number,
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      streaks?:  {
        __typename: "ModelStreakStateConnection",
        items:  Array< {
          __typename: "StreakState",
          id: string,
          owner: string,
          personStateID: string,
          currentState: Array< string >,
          prizeState?: Array< string > | null,
          nrOfTimesCompleted: number,
          streak:  {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          },
          createdAt: string,
          updatedAt: string,
          streakStateStreakId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      previous?:  {
        __typename: "PreviousEventState",
        id: string,
        owner: string,
        score: number,
        streaks:  Array< {
          __typename: "PreviousStreakState",
          id: string,
          currentState: Array< string >,
          prizeState?: Array< string > | null,
          nrOfTimesCompleted: number,
          streakID: string,
        } >,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      personEventStatePreviousId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PersonStateByEventByScoreQueryVariables = {
  eventID: string,
  score?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonEventStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PersonStateByEventByScoreQuery = {
  personStateByEventByScore?:  {
    __typename: "ModelPersonEventStateConnection",
    items:  Array< {
      __typename: "PersonEventState",
      id: string,
      owner: string,
      personID: string,
      eventID: string,
      score: number,
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      streaks?:  {
        __typename: "ModelStreakStateConnection",
        items:  Array< {
          __typename: "StreakState",
          id: string,
          owner: string,
          personStateID: string,
          currentState: Array< string >,
          prizeState?: Array< string > | null,
          nrOfTimesCompleted: number,
          streak:  {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          },
          createdAt: string,
          updatedAt: string,
          streakStateStreakId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      previous?:  {
        __typename: "PreviousEventState",
        id: string,
        owner: string,
        score: number,
        streaks:  Array< {
          __typename: "PreviousStreakState",
          id: string,
          currentState: Array< string >,
          prizeState?: Array< string > | null,
          nrOfTimesCompleted: number,
          streakID: string,
        } >,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      personEventStatePreviousId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPersonEventStatesQueryVariables = {
  filter?: SearchablePersonEventStateFilterInput | null,
  sort?: Array< SearchablePersonEventStateSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePersonEventStateAggregationInput | null > | null,
};

export type SearchPersonEventStatesQuery = {
  searchPersonEventStates?:  {
    __typename: "SearchablePersonEventStateConnection",
    items:  Array< {
      __typename: "PersonEventState",
      id: string,
      owner: string,
      personID: string,
      eventID: string,
      score: number,
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      streaks?:  {
        __typename: "ModelStreakStateConnection",
        items:  Array< {
          __typename: "StreakState",
          id: string,
          owner: string,
          personStateID: string,
          currentState: Array< string >,
          prizeState?: Array< string > | null,
          nrOfTimesCompleted: number,
          streak:  {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          },
          createdAt: string,
          updatedAt: string,
          streakStateStreakId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      previous?:  {
        __typename: "PreviousEventState",
        id: string,
        owner: string,
        score: number,
        streaks:  Array< {
          __typename: "PreviousStreakState",
          id: string,
          currentState: Array< string >,
          prizeState?: Array< string > | null,
          nrOfTimesCompleted: number,
          streakID: string,
        } >,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      personEventStatePreviousId?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetStreakStateQueryVariables = {
  id: string,
};

export type GetStreakStateQuery = {
  getStreakState?:  {
    __typename: "StreakState",
    id: string,
    owner: string,
    personStateID: string,
    currentState: Array< string >,
    prizeState?: Array< string > | null,
    nrOfTimesCompleted: number,
    streak:  {
      __typename: "Streak",
      id: string,
      owner: string,
      name: string,
      isRepeatable: boolean,
      promotions: Array< Promotions >,
      eventID: string,
      promoPrice: number,
      promoPair:  {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      },
      set?:  {
        __typename: "ModelPairStreakConnection",
        items:  Array< {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      streakPromoPairId: string,
    },
    createdAt: string,
    updatedAt: string,
    streakStateStreakId: string,
  } | null,
};

export type ListStreakStatesQueryVariables = {
  filter?: ModelStreakStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStreakStatesQuery = {
  listStreakStates?:  {
    __typename: "ModelStreakStateConnection",
    items:  Array< {
      __typename: "StreakState",
      id: string,
      owner: string,
      personStateID: string,
      currentState: Array< string >,
      prizeState?: Array< string > | null,
      nrOfTimesCompleted: number,
      streak:  {
        __typename: "Streak",
        id: string,
        owner: string,
        name: string,
        isRepeatable: boolean,
        promotions: Array< Promotions >,
        eventID: string,
        promoPrice: number,
        promoPair:  {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        },
        set?:  {
          __typename: "ModelPairStreakConnection",
          items:  Array< {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        streakPromoPairId: string,
      },
      createdAt: string,
      updatedAt: string,
      streakStateStreakId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StreakStateByPersonStateQueryVariables = {
  personStateID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStreakStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StreakStateByPersonStateQuery = {
  streakStateByPersonState?:  {
    __typename: "ModelStreakStateConnection",
    items:  Array< {
      __typename: "StreakState",
      id: string,
      owner: string,
      personStateID: string,
      currentState: Array< string >,
      prizeState?: Array< string > | null,
      nrOfTimesCompleted: number,
      streak:  {
        __typename: "Streak",
        id: string,
        owner: string,
        name: string,
        isRepeatable: boolean,
        promotions: Array< Promotions >,
        eventID: string,
        promoPrice: number,
        promoPair:  {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        },
        set?:  {
          __typename: "ModelPairStreakConnection",
          items:  Array< {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        streakPromoPairId: string,
      },
      createdAt: string,
      updatedAt: string,
      streakStateStreakId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchStreakStatesQueryVariables = {
  filter?: SearchableStreakStateFilterInput | null,
  sort?: Array< SearchableStreakStateSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableStreakStateAggregationInput | null > | null,
};

export type SearchStreakStatesQuery = {
  searchStreakStates?:  {
    __typename: "SearchableStreakStateConnection",
    items:  Array< {
      __typename: "StreakState",
      id: string,
      owner: string,
      personStateID: string,
      currentState: Array< string >,
      prizeState?: Array< string > | null,
      nrOfTimesCompleted: number,
      streak:  {
        __typename: "Streak",
        id: string,
        owner: string,
        name: string,
        isRepeatable: boolean,
        promotions: Array< Promotions >,
        eventID: string,
        promoPrice: number,
        promoPair:  {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        },
        set?:  {
          __typename: "ModelPairStreakConnection",
          items:  Array< {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        streakPromoPairId: string,
      },
      createdAt: string,
      updatedAt: string,
      streakStateStreakId: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPreviousEventStateQueryVariables = {
  id: string,
};

export type GetPreviousEventStateQuery = {
  getPreviousEventState?:  {
    __typename: "PreviousEventState",
    id: string,
    owner: string,
    score: number,
    streaks:  Array< {
      __typename: "PreviousStreakState",
      id: string,
      currentState: Array< string >,
      prizeState?: Array< string > | null,
      nrOfTimesCompleted: number,
      streakID: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPreviousEventStatesQueryVariables = {
  filter?: ModelPreviousEventStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPreviousEventStatesQuery = {
  listPreviousEventStates?:  {
    __typename: "ModelPreviousEventStateConnection",
    items:  Array< {
      __typename: "PreviousEventState",
      id: string,
      owner: string,
      score: number,
      streaks:  Array< {
        __typename: "PreviousStreakState",
        id: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streakID: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTicketQueryVariables = {
  id: string,
};

export type GetTicketQuery = {
  getTicket?:  {
    __typename: "Ticket",
    id: string,
    owner: string,
    name?: string | null,
    initialStock?: number | null,
    stock?: number | null,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    validDates:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    eventName: string,
    metadata?: string | null,
    eventID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTicketsQueryVariables = {
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketsQuery = {
  listTickets?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TicketsByEventQueryVariables = {
  eventID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TicketsByEventQuery = {
  ticketsByEvent?:  {
    __typename: "ModelTicketConnection",
    items:  Array< {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPosterQueryVariables = {
  id: string,
};

export type GetPosterQuery = {
  getPoster?:  {
    __typename: "Poster",
    id: string,
    owner: string,
    artists:  Array< {
      __typename: "Artist",
      name: string,
      performanceDateTime: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPostersQueryVariables = {
  filter?: ModelPosterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPostersQuery = {
  listPosters?:  {
    __typename: "ModelPosterConnection",
    items:  Array< {
      __typename: "Poster",
      id: string,
      owner: string,
      artists:  Array< {
        __typename: "Artist",
        name: string,
        performanceDateTime: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTicketTransactionQueryVariables = {
  id: string,
};

export type GetTicketTransactionQuery = {
  getTicketTransaction?:  {
    __typename: "TicketTransaction",
    id: string,
    owner: string,
    date: string,
    fee?: number | null,
    price: number,
    personID: string,
    eventID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    personTicket?:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null,
    promoter?:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    ticketTransactionTicketId: string,
    ticketTransactionRefundId?: string | null,
    ticketTransactionPersonTicketId?: string | null,
    ticketTransactionPromoterId?: string | null,
  } | null,
};

export type ListTicketTransactionsQueryVariables = {
  filter?: ModelTicketTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketTransactionsQuery = {
  listTicketTransactions?:  {
    __typename: "ModelTicketTransactionConnection",
    items:  Array< {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TicketTransactionByPersonQueryVariables = {
  personID: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TicketTransactionByPersonQuery = {
  ticketTransactionByPerson?:  {
    __typename: "ModelTicketTransactionConnection",
    items:  Array< {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TicketTransactionByEventQueryVariables = {
  eventID: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TicketTransactionByEventQuery = {
  ticketTransactionByEvent?:  {
    __typename: "ModelTicketTransactionConnection",
    items:  Array< {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchTicketTransactionsQueryVariables = {
  filter?: SearchableTicketTransactionFilterInput | null,
  sort?: Array< SearchableTicketTransactionSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableTicketTransactionAggregationInput | null > | null,
};

export type SearchTicketTransactionsQuery = {
  searchTicketTransactions?:  {
    __typename: "SearchableTicketTransactionConnection",
    items:  Array< {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetWalletTransactionQueryVariables = {
  id: string,
};

export type GetWalletTransactionQuery = {
  getWalletTransaction?:  {
    __typename: "WalletTransaction",
    id: string,
    owner: string,
    date: string,
    amount: number,
    method?: PaymentMethod | null,
    status: PaymentStatus,
    metadata?: string | null,
    sourceID?: string | null,
    transactionID: string,
    personID: string,
    provider?: PaymentProviders | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWalletTransactionsQueryVariables = {
  filter?: ModelWalletTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWalletTransactionsQuery = {
  listWalletTransactions?:  {
    __typename: "ModelWalletTransactionConnection",
    items:  Array< {
      __typename: "WalletTransaction",
      id: string,
      owner: string,
      date: string,
      amount: number,
      method?: PaymentMethod | null,
      status: PaymentStatus,
      metadata?: string | null,
      sourceID?: string | null,
      transactionID: string,
      personID: string,
      provider?: PaymentProviders | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WalletTransactionBySourceIDQueryVariables = {
  sourceID: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWalletTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WalletTransactionBySourceIDQuery = {
  walletTransactionBySourceID?:  {
    __typename: "ModelWalletTransactionConnection",
    items:  Array< {
      __typename: "WalletTransaction",
      id: string,
      owner: string,
      date: string,
      amount: number,
      method?: PaymentMethod | null,
      status: PaymentStatus,
      metadata?: string | null,
      sourceID?: string | null,
      transactionID: string,
      personID: string,
      provider?: PaymentProviders | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WalletTransactionByTransactionIDQueryVariables = {
  transactionID: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWalletTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WalletTransactionByTransactionIDQuery = {
  walletTransactionByTransactionID?:  {
    __typename: "ModelWalletTransactionConnection",
    items:  Array< {
      __typename: "WalletTransaction",
      id: string,
      owner: string,
      date: string,
      amount: number,
      method?: PaymentMethod | null,
      status: PaymentStatus,
      metadata?: string | null,
      sourceID?: string | null,
      transactionID: string,
      personID: string,
      provider?: PaymentProviders | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type WalletTransactionByPersonQueryVariables = {
  personID: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWalletTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WalletTransactionByPersonQuery = {
  walletTransactionByPerson?:  {
    __typename: "ModelWalletTransactionConnection",
    items:  Array< {
      __typename: "WalletTransaction",
      id: string,
      owner: string,
      date: string,
      amount: number,
      method?: PaymentMethod | null,
      status: PaymentStatus,
      metadata?: string | null,
      sourceID?: string | null,
      transactionID: string,
      personID: string,
      provider?: PaymentProviders | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchWalletTransactionsQueryVariables = {
  filter?: SearchableWalletTransactionFilterInput | null,
  sort?: Array< SearchableWalletTransactionSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableWalletTransactionAggregationInput | null > | null,
};

export type SearchWalletTransactionsQuery = {
  searchWalletTransactions?:  {
    __typename: "SearchableWalletTransactionConnection",
    items:  Array< {
      __typename: "WalletTransaction",
      id: string,
      owner: string,
      date: string,
      amount: number,
      method?: PaymentMethod | null,
      status: PaymentStatus,
      metadata?: string | null,
      sourceID?: string | null,
      transactionID: string,
      personID: string,
      provider?: PaymentProviders | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetConsumableTransactionQueryVariables = {
  id: string,
};

export type GetConsumableTransactionQuery = {
  getConsumableTransaction?:  {
    __typename: "ConsumableTransaction",
    id: string,
    owner: string,
    date: string,
    isRefunded?: boolean | null,
    personHandle?: string | null,
    price: number,
    collaboratorID: string,
    personID: string,
    eventID: string,
    cart?:  {
      __typename: "ModelPairTransactionConnection",
      items:  Array< {
        __typename: "PairTransaction",
        id: string,
        owner: string,
        personHandle?: string | null,
        transactionID: string,
        amount: number,
        isRefunded?: boolean | null,
        wasUsedInPromo: boolean,
        streakID?: string | null,
        wasPersonConsumable: boolean,
        pricePaid: number,
        whoGaveIt?: string | null,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairTransactionConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    person?:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListConsumableTransactionsQueryVariables = {
  filter?: ModelConsumableTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsumableTransactionsQuery = {
  listConsumableTransactions?:  {
    __typename: "ModelConsumableTransactionConnection",
    items:  Array< {
      __typename: "ConsumableTransaction",
      id: string,
      owner: string,
      date: string,
      isRefunded?: boolean | null,
      personHandle?: string | null,
      price: number,
      collaboratorID: string,
      personID: string,
      eventID: string,
      cart?:  {
        __typename: "ModelPairTransactionConnection",
        items:  Array< {
          __typename: "PairTransaction",
          id: string,
          owner: string,
          personHandle?: string | null,
          transactionID: string,
          amount: number,
          isRefunded?: boolean | null,
          wasUsedInPromo: boolean,
          streakID?: string | null,
          wasPersonConsumable: boolean,
          pricePaid: number,
          whoGaveIt?: string | null,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairTransactionConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      person?:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ConsumableTransactionByCollaboratorQueryVariables = {
  collaboratorID: string,
  eventID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsumableTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsumableTransactionByCollaboratorQuery = {
  consumableTransactionByCollaborator?:  {
    __typename: "ModelConsumableTransactionConnection",
    items:  Array< {
      __typename: "ConsumableTransaction",
      id: string,
      owner: string,
      date: string,
      isRefunded?: boolean | null,
      personHandle?: string | null,
      price: number,
      collaboratorID: string,
      personID: string,
      eventID: string,
      cart?:  {
        __typename: "ModelPairTransactionConnection",
        items:  Array< {
          __typename: "PairTransaction",
          id: string,
          owner: string,
          personHandle?: string | null,
          transactionID: string,
          amount: number,
          isRefunded?: boolean | null,
          wasUsedInPromo: boolean,
          streakID?: string | null,
          wasPersonConsumable: boolean,
          pricePaid: number,
          whoGaveIt?: string | null,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairTransactionConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      person?:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ConsumableTransactionByPersonQueryVariables = {
  personID: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsumableTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsumableTransactionByPersonQuery = {
  consumableTransactionByPerson?:  {
    __typename: "ModelConsumableTransactionConnection",
    items:  Array< {
      __typename: "ConsumableTransaction",
      id: string,
      owner: string,
      date: string,
      isRefunded?: boolean | null,
      personHandle?: string | null,
      price: number,
      collaboratorID: string,
      personID: string,
      eventID: string,
      cart?:  {
        __typename: "ModelPairTransactionConnection",
        items:  Array< {
          __typename: "PairTransaction",
          id: string,
          owner: string,
          personHandle?: string | null,
          transactionID: string,
          amount: number,
          isRefunded?: boolean | null,
          wasUsedInPromo: boolean,
          streakID?: string | null,
          wasPersonConsumable: boolean,
          pricePaid: number,
          whoGaveIt?: string | null,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairTransactionConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      person?:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ConsumableTransactionByEventQueryVariables = {
  eventID: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsumableTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsumableTransactionByEventQuery = {
  consumableTransactionByEvent?:  {
    __typename: "ModelConsumableTransactionConnection",
    items:  Array< {
      __typename: "ConsumableTransaction",
      id: string,
      owner: string,
      date: string,
      isRefunded?: boolean | null,
      personHandle?: string | null,
      price: number,
      collaboratorID: string,
      personID: string,
      eventID: string,
      cart?:  {
        __typename: "ModelPairTransactionConnection",
        items:  Array< {
          __typename: "PairTransaction",
          id: string,
          owner: string,
          personHandle?: string | null,
          transactionID: string,
          amount: number,
          isRefunded?: boolean | null,
          wasUsedInPromo: boolean,
          streakID?: string | null,
          wasPersonConsumable: boolean,
          pricePaid: number,
          whoGaveIt?: string | null,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairTransactionConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      person?:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchConsumableTransactionsQueryVariables = {
  filter?: SearchableConsumableTransactionFilterInput | null,
  sort?: Array< SearchableConsumableTransactionSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableConsumableTransactionAggregationInput | null > | null,
};

export type SearchConsumableTransactionsQuery = {
  searchConsumableTransactions?:  {
    __typename: "SearchableConsumableTransactionConnection",
    items:  Array< {
      __typename: "ConsumableTransaction",
      id: string,
      owner: string,
      date: string,
      isRefunded?: boolean | null,
      personHandle?: string | null,
      price: number,
      collaboratorID: string,
      personID: string,
      eventID: string,
      cart?:  {
        __typename: "ModelPairTransactionConnection",
        items:  Array< {
          __typename: "PairTransaction",
          id: string,
          owner: string,
          personHandle?: string | null,
          transactionID: string,
          amount: number,
          isRefunded?: boolean | null,
          wasUsedInPromo: boolean,
          streakID?: string | null,
          wasPersonConsumable: boolean,
          pricePaid: number,
          whoGaveIt?: string | null,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairTransactionConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      person?:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPersonTicketQueryVariables = {
  id: string,
};

export type GetPersonTicketQuery = {
  getPersonTicket?:  {
    __typename: "PersonTicket",
    id: string,
    owner: string,
    readDates: Array< string >,
    privacy: Privacy,
    eventID: string,
    collaboratorID?: string | null,
    personID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    transaction?:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personTicketTicketId: string,
    personTicketRefundId?: string | null,
    personTicketTransactionId?: string | null,
  } | null,
};

export type ListPersonTicketsQueryVariables = {
  filter?: ModelPersonTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPersonTicketsQuery = {
  listPersonTickets?:  {
    __typename: "ModelPersonTicketConnection",
    items:  Array< {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PersonTicketsByEventQueryVariables = {
  eventID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PersonTicketsByEventQuery = {
  personTicketsByEvent?:  {
    __typename: "ModelPersonTicketConnection",
    items:  Array< {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PersonTicketsByCollaboratorQueryVariables = {
  collaboratorID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PersonTicketsByCollaboratorQuery = {
  personTicketsByCollaborator?:  {
    __typename: "ModelPersonTicketConnection",
    items:  Array< {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PersonTicketsByPersonQueryVariables = {
  personID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonTicketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PersonTicketsByPersonQuery = {
  personTicketsByPerson?:  {
    __typename: "ModelPersonTicketConnection",
    items:  Array< {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPersonTicketsQueryVariables = {
  filter?: SearchablePersonTicketFilterInput | null,
  sort?: Array< SearchablePersonTicketSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePersonTicketAggregationInput | null > | null,
};

export type SearchPersonTicketsQuery = {
  searchPersonTickets?:  {
    __typename: "SearchablePersonTicketConnection",
    items:  Array< {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetTicketRefundQueryVariables = {
  id: string,
};

export type GetTicketRefundQuery = {
  getTicketRefund?:  {
    __typename: "TicketRefund",
    id: string,
    owner: string,
    refundedAmount: number,
    refundedPercentage: number,
    ticketID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    personTicket:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    },
    transaction:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    },
    eventID: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
    ticketRefundPersonTicketId: string,
    ticketRefundTransactionId: string,
  } | null,
};

export type ListTicketRefundsQueryVariables = {
  filter?: ModelTicketRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTicketRefundsQuery = {
  listTicketRefunds?:  {
    __typename: "ModelTicketRefundConnection",
    items:  Array< {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RefundsByTicketQueryVariables = {
  ticketID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RefundsByTicketQuery = {
  refundsByTicket?:  {
    __typename: "ModelTicketRefundConnection",
    items:  Array< {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RefundsByEventQueryVariables = {
  eventID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RefundsByEventQuery = {
  refundsByEvent?:  {
    __typename: "ModelTicketRefundConnection",
    items:  Array< {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RefundsByPersonQueryVariables = {
  personID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTicketRefundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RefundsByPersonQuery = {
  refundsByPerson?:  {
    __typename: "ModelTicketRefundConnection",
    items:  Array< {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchTicketRefundsQueryVariables = {
  filter?: SearchableTicketRefundFilterInput | null,
  sort?: Array< SearchableTicketRefundSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableTicketRefundAggregationInput | null > | null,
};

export type SearchTicketRefundsQuery = {
  searchTicketRefunds?:  {
    __typename: "SearchableTicketRefundConnection",
    items:  Array< {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPersonQueryVariables = {
  id: string,
};

export type GetPersonQuery = {
  getPerson?:  {
    __typename: "Person",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    phone: string,
    dateOfBirth: string,
    wallet: number,
    privacy: Privacy,
    handle?: string | null,
    currentEventID?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      zipCode: string,
      city: string,
      country: string,
    } | null,
    auxIdentity?:  {
      __typename: "AuxiliaryIdentity",
      id: string,
      owner: string,
      validDate: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelPairPersonConnection",
      items:  Array< {
        __typename: "PairPerson",
        id: string,
        owner: string,
        personID: string,
        whoGaveIt: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        eventID: string,
        pricePaid?: number | null,
        createdAt: string,
        updatedAt: string,
        pairPersonConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyWallet?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    friends?:  {
      __typename: "ModelFriendConnection",
      items:  Array< {
        __typename: "Friend",
        id: string,
        personID: string,
        friendID: string,
        owner: string,
        friend:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        request: FriendRequest,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personAuxIdentityId?: string | null,
    personAvatarId?: string | null,
  } | null,
};

export type ListPeopleQueryVariables = {
  filter?: ModelPersonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPeopleQuery = {
  listPeople?:  {
    __typename: "ModelPersonConnection",
    items:  Array< {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PersonByPhoneQueryVariables = {
  phone: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PersonByPhoneQuery = {
  personByPhone?:  {
    __typename: "ModelPersonConnection",
    items:  Array< {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PersonByHandleQueryVariables = {
  handle: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPersonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PersonByHandleQuery = {
  personByHandle?:  {
    __typename: "ModelPersonConnection",
    items:  Array< {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPeopleQueryVariables = {
  filter?: SearchablePersonFilterInput | null,
  sort?: Array< SearchablePersonSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePersonAggregationInput | null > | null,
};

export type SearchPeopleQuery = {
  searchPeople?:  {
    __typename: "SearchablePersonConnection",
    items:  Array< {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetAuxiliaryIdentityQueryVariables = {
  id: string,
};

export type GetAuxiliaryIdentityQuery = {
  getAuxiliaryIdentity?:  {
    __typename: "AuxiliaryIdentity",
    id: string,
    owner: string,
    validDate: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAuxiliaryIdentitiesQueryVariables = {
  filter?: ModelAuxiliaryIdentityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuxiliaryIdentitiesQuery = {
  listAuxiliaryIdentities?:  {
    __typename: "ModelAuxiliaryIdentityConnection",
    items:  Array< {
      __typename: "AuxiliaryIdentity",
      id: string,
      owner: string,
      validDate: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFriendQueryVariables = {
  id: string,
};

export type GetFriendQuery = {
  getFriend?:  {
    __typename: "Friend",
    id: string,
    personID: string,
    friendID: string,
    owner: string,
    friend:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    request: FriendRequest,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFriendsQueryVariables = {
  filter?: ModelFriendFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFriendsQuery = {
  listFriends?:  {
    __typename: "ModelFriendConnection",
    items:  Array< {
      __typename: "Friend",
      id: string,
      personID: string,
      friendID: string,
      owner: string,
      friend:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      request: FriendRequest,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PersonFriendsByPersonQueryVariables = {
  personID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFriendFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PersonFriendsByPersonQuery = {
  personFriendsByPerson?:  {
    __typename: "ModelFriendConnection",
    items:  Array< {
      __typename: "Friend",
      id: string,
      personID: string,
      friendID: string,
      owner: string,
      friend:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      request: FriendRequest,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetConsumableQueryVariables = {
  id: string,
};

export type GetConsumableQuery = {
  getConsumable?:  {
    __typename: "Consumable",
    id: string,
    owner: string,
    type: ConsumableType,
    size: ConsumableSize,
    name: string,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    companyID?: string | null,
    eventID: string,
    smallOrLargeRef?:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    consumableSmallOrLargeRefId?: string | null,
  } | null,
};

export type ListConsumablesQueryVariables = {
  filter?: ModelConsumableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsumablesQuery = {
  listConsumables?:  {
    __typename: "ModelConsumableConnection",
    items:  Array< {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ConsumablesByCompanyQueryVariables = {
  companyID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsumableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsumablesByCompanyQuery = {
  consumablesByCompany?:  {
    __typename: "ModelConsumableConnection",
    items:  Array< {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ConsumablesByEventQueryVariables = {
  eventID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConsumableFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConsumablesByEventQuery = {
  consumablesByEvent?:  {
    __typename: "ModelConsumableConnection",
    items:  Array< {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStreakQueryVariables = {
  id: string,
};

export type GetStreakQuery = {
  getStreak?:  {
    __typename: "Streak",
    id: string,
    owner: string,
    name: string,
    isRepeatable: boolean,
    promotions: Array< Promotions >,
    eventID: string,
    promoPrice: number,
    promoPair:  {
      __typename: "PairStreak",
      id: string,
      owner: string,
      streakID: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairStreakConsumableId: string,
    },
    set?:  {
      __typename: "ModelPairStreakConnection",
      items:  Array< {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    streakPromoPairId: string,
  } | null,
};

export type ListStreaksQueryVariables = {
  filter?: ModelStreakFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStreaksQuery = {
  listStreaks?:  {
    __typename: "ModelStreakConnection",
    items:  Array< {
      __typename: "Streak",
      id: string,
      owner: string,
      name: string,
      isRepeatable: boolean,
      promotions: Array< Promotions >,
      eventID: string,
      promoPrice: number,
      promoPair:  {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      },
      set?:  {
        __typename: "ModelPairStreakConnection",
        items:  Array< {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      streakPromoPairId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StreakByEventQueryVariables = {
  eventID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStreakFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StreakByEventQuery = {
  streakByEvent?:  {
    __typename: "ModelStreakConnection",
    items:  Array< {
      __typename: "Streak",
      id: string,
      owner: string,
      name: string,
      isRepeatable: boolean,
      promotions: Array< Promotions >,
      eventID: string,
      promoPrice: number,
      promoPair:  {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      },
      set?:  {
        __typename: "ModelPairStreakConnection",
        items:  Array< {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      streakPromoPairId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetImageQueryVariables = {
  id: string,
};

export type GetImageQuery = {
  getImage?:  {
    __typename: "Image",
    id: string,
    owner: string,
    eventID?: string | null,
    filename: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListImagesQueryVariables = {
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImagesQuery = {
  listImages?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ImageByEventQueryVariables = {
  eventID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ImageByEventQuery = {
  imageByEvent?:  {
    __typename: "ModelImageConnection",
    items:  Array< {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPairPersonQueryVariables = {
  id: string,
};

export type GetPairPersonQuery = {
  getPairPerson?:  {
    __typename: "PairPerson",
    id: string,
    owner: string,
    personID: string,
    whoGaveIt: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    eventID: string,
    pricePaid?: number | null,
    createdAt: string,
    updatedAt: string,
    pairPersonConsumableId: string,
  } | null,
};

export type ListPairPeopleQueryVariables = {
  filter?: ModelPairPersonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPairPeopleQuery = {
  listPairPeople?:  {
    __typename: "ModelPairPersonConnection",
    items:  Array< {
      __typename: "PairPerson",
      id: string,
      owner: string,
      personID: string,
      whoGaveIt: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      eventID: string,
      pricePaid?: number | null,
      createdAt: string,
      updatedAt: string,
      pairPersonConsumableId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PairsByPersonByAmountQueryVariables = {
  personID: string,
  amount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPairPersonFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PairsByPersonByAmountQuery = {
  pairsByPersonByAmount?:  {
    __typename: "ModelPairPersonConnection",
    items:  Array< {
      __typename: "PairPerson",
      id: string,
      owner: string,
      personID: string,
      whoGaveIt: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      eventID: string,
      pricePaid?: number | null,
      createdAt: string,
      updatedAt: string,
      pairPersonConsumableId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPairStreakQueryVariables = {
  id: string,
};

export type GetPairStreakQuery = {
  getPairStreak?:  {
    __typename: "PairStreak",
    id: string,
    owner: string,
    streakID: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairStreakConsumableId: string,
  } | null,
};

export type ListPairStreaksQueryVariables = {
  filter?: ModelPairStreakFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPairStreaksQuery = {
  listPairStreaks?:  {
    __typename: "ModelPairStreakConnection",
    items:  Array< {
      __typename: "PairStreak",
      id: string,
      owner: string,
      streakID: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairStreakConsumableId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PairStreakByStreakQueryVariables = {
  streakID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPairStreakFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PairStreakByStreakQuery = {
  pairStreakByStreak?:  {
    __typename: "ModelPairStreakConnection",
    items:  Array< {
      __typename: "PairStreak",
      id: string,
      owner: string,
      streakID: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairStreakConsumableId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPairTransactionQueryVariables = {
  id: string,
};

export type GetPairTransactionQuery = {
  getPairTransaction?:  {
    __typename: "PairTransaction",
    id: string,
    owner: string,
    personHandle?: string | null,
    transactionID: string,
    amount: number,
    isRefunded?: boolean | null,
    wasUsedInPromo: boolean,
    streakID?: string | null,
    wasPersonConsumable: boolean,
    pricePaid: number,
    whoGaveIt?: string | null,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairTransactionConsumableId: string,
  } | null,
};

export type ListPairTransactionsQueryVariables = {
  filter?: ModelPairTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPairTransactionsQuery = {
  listPairTransactions?:  {
    __typename: "ModelPairTransactionConnection",
    items:  Array< {
      __typename: "PairTransaction",
      id: string,
      owner: string,
      personHandle?: string | null,
      transactionID: string,
      amount: number,
      isRefunded?: boolean | null,
      wasUsedInPromo: boolean,
      streakID?: string | null,
      wasPersonConsumable: boolean,
      pricePaid: number,
      whoGaveIt?: string | null,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairTransactionConsumableId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PairTransactionByTransactionQueryVariables = {
  transactionID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPairTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PairTransactionByTransactionQuery = {
  pairTransactionByTransaction?:  {
    __typename: "ModelPairTransactionConnection",
    items:  Array< {
      __typename: "PairTransaction",
      id: string,
      owner: string,
      personHandle?: string | null,
      transactionID: string,
      amount: number,
      isRefunded?: boolean | null,
      wasUsedInPromo: boolean,
      streakID?: string | null,
      wasPersonConsumable: boolean,
      pricePaid: number,
      whoGaveIt?: string | null,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairTransactionConsumableId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPairTransactionsQueryVariables = {
  filter?: SearchablePairTransactionFilterInput | null,
  sort?: Array< SearchablePairTransactionSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePairTransactionAggregationInput | null > | null,
};

export type SearchPairTransactionsQuery = {
  searchPairTransactions?:  {
    __typename: "SearchablePairTransactionConnection",
    items:  Array< {
      __typename: "PairTransaction",
      id: string,
      owner: string,
      personHandle?: string | null,
      transactionID: string,
      amount: number,
      isRefunded?: boolean | null,
      wasUsedInPromo: boolean,
      streakID?: string | null,
      wasPersonConsumable: boolean,
      pricePaid: number,
      whoGaveIt?: string | null,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairTransactionConsumableId: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetCollaboratorEventConnectionQueryVariables = {
  id: string,
};

export type GetCollaboratorEventConnectionQuery = {
  getCollaboratorEventConnection?:  {
    __typename: "CollaboratorEventConnection",
    id: string,
    collaboratorID: string,
    eventID: string,
    collaborator:  {
      __typename: "Collaborator",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      handle?: string | null,
      phone: string,
      activity?: string | null,
      companyID: string,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      readTickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      chargeHistory?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      collaboratorAvatarId?: string | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCollaboratorEventConnectionsQueryVariables = {
  filter?: ModelCollaboratorEventConnectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollaboratorEventConnectionsQuery = {
  listCollaboratorEventConnections?:  {
    __typename: "ModelCollaboratorEventConnectionConnection",
    items:  Array< {
      __typename: "CollaboratorEventConnection",
      id: string,
      collaboratorID: string,
      eventID: string,
      collaborator:  {
        __typename: "Collaborator",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        handle?: string | null,
        phone: string,
        activity?: string | null,
        companyID: string,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        readTickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        history?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        chargeHistory?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        collaboratorAvatarId?: string | null,
      },
      event:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPromoterEventConnectionQueryVariables = {
  id: string,
};

export type GetPromoterEventConnectionQuery = {
  getPromoterEventConnection?:  {
    __typename: "PromoterEventConnection",
    id: string,
    promoterID: string,
    eventID: string,
    promoter:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPromoterEventConnectionsQueryVariables = {
  filter?: ModelPromoterEventConnectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromoterEventConnectionsQuery = {
  listPromoterEventConnections?:  {
    __typename: "ModelPromoterEventConnectionConnection",
    items:  Array< {
      __typename: "PromoterEventConnection",
      id: string,
      promoterID: string,
      eventID: string,
      promoter:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      event:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnPersonUpdateSubscriptionVariables = {
  id: string,
};

export type OnPersonUpdateSubscription = {
  onPersonUpdate?:  {
    __typename: "Person",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    phone: string,
    dateOfBirth: string,
    wallet: number,
    privacy: Privacy,
    handle?: string | null,
    currentEventID?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      zipCode: string,
      city: string,
      country: string,
    } | null,
    auxIdentity?:  {
      __typename: "AuxiliaryIdentity",
      id: string,
      owner: string,
      validDate: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelPairPersonConnection",
      items:  Array< {
        __typename: "PairPerson",
        id: string,
        owner: string,
        personID: string,
        whoGaveIt: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        eventID: string,
        pricePaid?: number | null,
        createdAt: string,
        updatedAt: string,
        pairPersonConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyWallet?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    friends?:  {
      __typename: "ModelFriendConnection",
      items:  Array< {
        __typename: "Friend",
        id: string,
        personID: string,
        friendID: string,
        owner: string,
        friend:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        request: FriendRequest,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personAuxIdentityId?: string | null,
    personAvatarId?: string | null,
  } | null,
};

export type OnCollaboratorUpdateSubscriptionVariables = {
  companyID: string,
};

export type OnCollaboratorUpdateSubscription = {
  onCollaboratorUpdate?:  {
    __typename: "Collaborator",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    handle?: string | null,
    phone: string,
    activity?: string | null,
    companyID: string,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    readTickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chargeHistory?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    collaboratorAvatarId?: string | null,
  } | null,
};

export type OnWalletTransactionUpdateSubscriptionVariables = {
  personID: string,
};

export type OnWalletTransactionUpdateSubscription = {
  onWalletTransactionUpdate?:  {
    __typename: "WalletTransaction",
    id: string,
    owner: string,
    date: string,
    amount: number,
    method?: PaymentMethod | null,
    status: PaymentStatus,
    metadata?: string | null,
    sourceID?: string | null,
    transactionID: string,
    personID: string,
    provider?: PaymentProviders | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnFriendUpdateSubscriptionVariables = {
  personID: string,
};

export type OnFriendUpdateSubscription = {
  onFriendUpdate?:  {
    __typename: "Friend",
    id: string,
    personID: string,
    friendID: string,
    owner: string,
    friend:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    request: FriendRequest,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnFriendCreateSubscriptionVariables = {
  friendID: string,
};

export type OnFriendCreateSubscription = {
  onFriendCreate?:  {
    __typename: "Friend",
    id: string,
    personID: string,
    friendID: string,
    owner: string,
    friend:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    request: FriendRequest,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCompanySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCompanySubscription = {
  onCreateCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    iban: string,
    initials: string,
    handle?: string | null,
    consumablesFee: number,
    ticketsFee: number,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorConnection",
      items:  Array< {
        __typename: "Collaborator",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        handle?: string | null,
        phone: string,
        activity?: string | null,
        companyID: string,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        readTickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        history?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        chargeHistory?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        collaboratorAvatarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterConnection",
      items:  Array< {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner: string,
        name?: string | null,
        internalID?: string | null,
        email?: string | null,
        phone: string,
        companyID: string,
        company:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyAvatarId?: string | null,
  } | null,
};

export type OnUpdateCompanySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    iban: string,
    initials: string,
    handle?: string | null,
    consumablesFee: number,
    ticketsFee: number,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorConnection",
      items:  Array< {
        __typename: "Collaborator",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        handle?: string | null,
        phone: string,
        activity?: string | null,
        companyID: string,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        readTickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        history?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        chargeHistory?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        collaboratorAvatarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterConnection",
      items:  Array< {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner: string,
        name?: string | null,
        internalID?: string | null,
        email?: string | null,
        phone: string,
        companyID: string,
        company:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyAvatarId?: string | null,
  } | null,
};

export type OnDeleteCompanySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?:  {
    __typename: "Company",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    iban: string,
    initials: string,
    handle?: string | null,
    consumablesFee: number,
    ticketsFee: number,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorConnection",
      items:  Array< {
        __typename: "Collaborator",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        handle?: string | null,
        phone: string,
        activity?: string | null,
        companyID: string,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        readTickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        history?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        chargeHistory?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        collaboratorAvatarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelEventConnection",
      items:  Array< {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterConnection",
      items:  Array< {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelMemberConnection",
      items:  Array< {
        __typename: "Member",
        id: string,
        owner: string,
        name?: string | null,
        internalID?: string | null,
        email?: string | null,
        phone: string,
        companyID: string,
        company:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyAvatarId?: string | null,
  } | null,
};

export type OnCreateMemberSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateMemberSubscription = {
  onCreateMember?:  {
    __typename: "Member",
    id: string,
    owner: string,
    name?: string | null,
    internalID?: string | null,
    email?: string | null,
    phone: string,
    companyID: string,
    company:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMemberSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateMemberSubscription = {
  onUpdateMember?:  {
    __typename: "Member",
    id: string,
    owner: string,
    name?: string | null,
    internalID?: string | null,
    email?: string | null,
    phone: string,
    companyID: string,
    company:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMemberSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteMemberSubscription = {
  onDeleteMember?:  {
    __typename: "Member",
    id: string,
    owner: string,
    name?: string | null,
    internalID?: string | null,
    email?: string | null,
    phone: string,
    companyID: string,
    company:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCollaboratorSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCollaboratorSubscription = {
  onCreateCollaborator?:  {
    __typename: "Collaborator",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    handle?: string | null,
    phone: string,
    activity?: string | null,
    companyID: string,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    readTickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chargeHistory?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    collaboratorAvatarId?: string | null,
  } | null,
};

export type OnUpdateCollaboratorSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCollaboratorSubscription = {
  onUpdateCollaborator?:  {
    __typename: "Collaborator",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    handle?: string | null,
    phone: string,
    activity?: string | null,
    companyID: string,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    readTickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chargeHistory?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    collaboratorAvatarId?: string | null,
  } | null,
};

export type OnDeleteCollaboratorSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCollaboratorSubscription = {
  onDeleteCollaborator?:  {
    __typename: "Collaborator",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    handle?: string | null,
    phone: string,
    activity?: string | null,
    companyID: string,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    readTickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    events?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chargeHistory?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    collaboratorAvatarId?: string | null,
  } | null,
};

export type OnCreatePromoterSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePromoterSubscription = {
  onCreatePromoter?:  {
    __typename: "Promoter",
    id: string,
    owner: string,
    name: string,
    companyID: string,
    events?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePromoterSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePromoterSubscription = {
  onUpdatePromoter?:  {
    __typename: "Promoter",
    id: string,
    owner: string,
    name: string,
    companyID: string,
    events?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePromoterSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePromoterSubscription = {
  onDeletePromoter?:  {
    __typename: "Promoter",
    id: string,
    owner: string,
    name: string,
    companyID: string,
    events?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEventSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    owner: string,
    name: string,
    promotions: Array< Promotions >,
    location:  {
      __typename: "Location",
      lon: number,
      lat: number,
    },
    addressAlias: string,
    companyID: string,
    description: string,
    startDate: string,
    endDate: string,
    date:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    maxCapacity?: number | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    } | null,
    banner:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    },
    poster?:  {
      __typename: "Poster",
      id: string,
      owner: string,
      artists:  Array< {
        __typename: "Artist",
        name: string,
        performanceDateTime: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    streaks?:  {
      __typename: "ModelStreakConnection",
      items:  Array< {
        __typename: "Streak",
        id: string,
        owner: string,
        name: string,
        isRepeatable: boolean,
        promotions: Array< Promotions >,
        eventID: string,
        promoPrice: number,
        promoPair:  {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        },
        set?:  {
          __typename: "ModelPairStreakConnection",
          items:  Array< {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        streakPromoPairId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelConsumableConnection",
      items:  Array< {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    peopleState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    eventBannerId: string,
    eventPosterId?: string | null,
  } | null,
};

export type OnUpdateEventSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    owner: string,
    name: string,
    promotions: Array< Promotions >,
    location:  {
      __typename: "Location",
      lon: number,
      lat: number,
    },
    addressAlias: string,
    companyID: string,
    description: string,
    startDate: string,
    endDate: string,
    date:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    maxCapacity?: number | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    } | null,
    banner:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    },
    poster?:  {
      __typename: "Poster",
      id: string,
      owner: string,
      artists:  Array< {
        __typename: "Artist",
        name: string,
        performanceDateTime: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    streaks?:  {
      __typename: "ModelStreakConnection",
      items:  Array< {
        __typename: "Streak",
        id: string,
        owner: string,
        name: string,
        isRepeatable: boolean,
        promotions: Array< Promotions >,
        eventID: string,
        promoPrice: number,
        promoPair:  {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        },
        set?:  {
          __typename: "ModelPairStreakConnection",
          items:  Array< {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        streakPromoPairId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelConsumableConnection",
      items:  Array< {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    peopleState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    eventBannerId: string,
    eventPosterId?: string | null,
  } | null,
};

export type OnDeleteEventSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    owner: string,
    name: string,
    promotions: Array< Promotions >,
    location:  {
      __typename: "Location",
      lon: number,
      lat: number,
    },
    addressAlias: string,
    companyID: string,
    description: string,
    startDate: string,
    endDate: string,
    date:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    maxCapacity?: number | null,
    company?:  {
      __typename: "Company",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      iban: string,
      initials: string,
      handle?: string | null,
      consumablesFee: number,
      ticketsFee: number,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorConnection",
        items:  Array< {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelEventConnection",
        items:  Array< {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterConnection",
        items:  Array< {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelMemberConnection",
        items:  Array< {
          __typename: "Member",
          id: string,
          owner: string,
          name?: string | null,
          internalID?: string | null,
          email?: string | null,
          phone: string,
          companyID: string,
          company:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyAvatarId?: string | null,
    } | null,
    banner:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    },
    poster?:  {
      __typename: "Poster",
      id: string,
      owner: string,
      artists:  Array< {
        __typename: "Artist",
        name: string,
        performanceDateTime: string,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null,
    streaks?:  {
      __typename: "ModelStreakConnection",
      items:  Array< {
        __typename: "Streak",
        id: string,
        owner: string,
        name: string,
        isRepeatable: boolean,
        promotions: Array< Promotions >,
        eventID: string,
        promoPrice: number,
        promoPair:  {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        },
        set?:  {
          __typename: "ModelPairStreakConnection",
          items:  Array< {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        streakPromoPairId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelImageConnection",
      items:  Array< {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelTicketConnection",
      items:  Array< {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    collaborators?:  {
      __typename: "ModelCollaboratorEventConnectionConnection",
      items:  Array< {
        __typename: "CollaboratorEventConnection",
        id: string,
        collaboratorID: string,
        eventID: string,
        collaborator:  {
          __typename: "Collaborator",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          handle?: string | null,
          phone: string,
          activity?: string | null,
          companyID: string,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          readTickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          history?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          chargeHistory?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          collaboratorAvatarId?: string | null,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelConsumableConnection",
      items:  Array< {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    peopleState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    transactionsConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    promoters?:  {
      __typename: "ModelPromoterEventConnectionConnection",
      items:  Array< {
        __typename: "PromoterEventConnection",
        id: string,
        promoterID: string,
        eventID: string,
        promoter:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        },
        event:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    eventBannerId: string,
    eventPosterId?: string | null,
  } | null,
};

export type OnCreatePersonEventStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePersonEventStateSubscription = {
  onCreatePersonEventState?:  {
    __typename: "PersonEventState",
    id: string,
    owner: string,
    personID: string,
    eventID: string,
    score: number,
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    streaks?:  {
      __typename: "ModelStreakStateConnection",
      items:  Array< {
        __typename: "StreakState",
        id: string,
        owner: string,
        personStateID: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streak:  {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        },
        createdAt: string,
        updatedAt: string,
        streakStateStreakId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    previous?:  {
      __typename: "PreviousEventState",
      id: string,
      owner: string,
      score: number,
      streaks:  Array< {
        __typename: "PreviousStreakState",
        id: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streakID: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    personEventStatePreviousId?: string | null,
  } | null,
};

export type OnUpdatePersonEventStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePersonEventStateSubscription = {
  onUpdatePersonEventState?:  {
    __typename: "PersonEventState",
    id: string,
    owner: string,
    personID: string,
    eventID: string,
    score: number,
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    streaks?:  {
      __typename: "ModelStreakStateConnection",
      items:  Array< {
        __typename: "StreakState",
        id: string,
        owner: string,
        personStateID: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streak:  {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        },
        createdAt: string,
        updatedAt: string,
        streakStateStreakId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    previous?:  {
      __typename: "PreviousEventState",
      id: string,
      owner: string,
      score: number,
      streaks:  Array< {
        __typename: "PreviousStreakState",
        id: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streakID: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    personEventStatePreviousId?: string | null,
  } | null,
};

export type OnDeletePersonEventStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePersonEventStateSubscription = {
  onDeletePersonEventState?:  {
    __typename: "PersonEventState",
    id: string,
    owner: string,
    personID: string,
    eventID: string,
    score: number,
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    streaks?:  {
      __typename: "ModelStreakStateConnection",
      items:  Array< {
        __typename: "StreakState",
        id: string,
        owner: string,
        personStateID: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streak:  {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        },
        createdAt: string,
        updatedAt: string,
        streakStateStreakId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    previous?:  {
      __typename: "PreviousEventState",
      id: string,
      owner: string,
      score: number,
      streaks:  Array< {
        __typename: "PreviousStreakState",
        id: string,
        currentState: Array< string >,
        prizeState?: Array< string > | null,
        nrOfTimesCompleted: number,
        streakID: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    personEventStatePreviousId?: string | null,
  } | null,
};

export type OnCreateStreakStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateStreakStateSubscription = {
  onCreateStreakState?:  {
    __typename: "StreakState",
    id: string,
    owner: string,
    personStateID: string,
    currentState: Array< string >,
    prizeState?: Array< string > | null,
    nrOfTimesCompleted: number,
    streak:  {
      __typename: "Streak",
      id: string,
      owner: string,
      name: string,
      isRepeatable: boolean,
      promotions: Array< Promotions >,
      eventID: string,
      promoPrice: number,
      promoPair:  {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      },
      set?:  {
        __typename: "ModelPairStreakConnection",
        items:  Array< {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      streakPromoPairId: string,
    },
    createdAt: string,
    updatedAt: string,
    streakStateStreakId: string,
  } | null,
};

export type OnUpdateStreakStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateStreakStateSubscription = {
  onUpdateStreakState?:  {
    __typename: "StreakState",
    id: string,
    owner: string,
    personStateID: string,
    currentState: Array< string >,
    prizeState?: Array< string > | null,
    nrOfTimesCompleted: number,
    streak:  {
      __typename: "Streak",
      id: string,
      owner: string,
      name: string,
      isRepeatable: boolean,
      promotions: Array< Promotions >,
      eventID: string,
      promoPrice: number,
      promoPair:  {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      },
      set?:  {
        __typename: "ModelPairStreakConnection",
        items:  Array< {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      streakPromoPairId: string,
    },
    createdAt: string,
    updatedAt: string,
    streakStateStreakId: string,
  } | null,
};

export type OnDeleteStreakStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteStreakStateSubscription = {
  onDeleteStreakState?:  {
    __typename: "StreakState",
    id: string,
    owner: string,
    personStateID: string,
    currentState: Array< string >,
    prizeState?: Array< string > | null,
    nrOfTimesCompleted: number,
    streak:  {
      __typename: "Streak",
      id: string,
      owner: string,
      name: string,
      isRepeatable: boolean,
      promotions: Array< Promotions >,
      eventID: string,
      promoPrice: number,
      promoPair:  {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      },
      set?:  {
        __typename: "ModelPairStreakConnection",
        items:  Array< {
          __typename: "PairStreak",
          id: string,
          owner: string,
          streakID: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          pairStreakConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      streakPromoPairId: string,
    },
    createdAt: string,
    updatedAt: string,
    streakStateStreakId: string,
  } | null,
};

export type OnCreatePreviousEventStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePreviousEventStateSubscription = {
  onCreatePreviousEventState?:  {
    __typename: "PreviousEventState",
    id: string,
    owner: string,
    score: number,
    streaks:  Array< {
      __typename: "PreviousStreakState",
      id: string,
      currentState: Array< string >,
      prizeState?: Array< string > | null,
      nrOfTimesCompleted: number,
      streakID: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePreviousEventStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePreviousEventStateSubscription = {
  onUpdatePreviousEventState?:  {
    __typename: "PreviousEventState",
    id: string,
    owner: string,
    score: number,
    streaks:  Array< {
      __typename: "PreviousStreakState",
      id: string,
      currentState: Array< string >,
      prizeState?: Array< string > | null,
      nrOfTimesCompleted: number,
      streakID: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePreviousEventStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePreviousEventStateSubscription = {
  onDeletePreviousEventState?:  {
    __typename: "PreviousEventState",
    id: string,
    owner: string,
    score: number,
    streaks:  Array< {
      __typename: "PreviousStreakState",
      id: string,
      currentState: Array< string >,
      prizeState?: Array< string > | null,
      nrOfTimesCompleted: number,
      streakID: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTicketSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTicketSubscription = {
  onCreateTicket?:  {
    __typename: "Ticket",
    id: string,
    owner: string,
    name?: string | null,
    initialStock?: number | null,
    stock?: number | null,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    validDates:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    eventName: string,
    metadata?: string | null,
    eventID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTicketSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTicketSubscription = {
  onUpdateTicket?:  {
    __typename: "Ticket",
    id: string,
    owner: string,
    name?: string | null,
    initialStock?: number | null,
    stock?: number | null,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    validDates:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    eventName: string,
    metadata?: string | null,
    eventID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTicketSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTicketSubscription = {
  onDeleteTicket?:  {
    __typename: "Ticket",
    id: string,
    owner: string,
    name?: string | null,
    initialStock?: number | null,
    stock?: number | null,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    validDates:  Array< {
      __typename: "DateSpan",
      start: string,
      end: string,
    } >,
    eventName: string,
    metadata?: string | null,
    eventID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePosterSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePosterSubscription = {
  onCreatePoster?:  {
    __typename: "Poster",
    id: string,
    owner: string,
    artists:  Array< {
      __typename: "Artist",
      name: string,
      performanceDateTime: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePosterSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePosterSubscription = {
  onUpdatePoster?:  {
    __typename: "Poster",
    id: string,
    owner: string,
    artists:  Array< {
      __typename: "Artist",
      name: string,
      performanceDateTime: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePosterSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePosterSubscription = {
  onDeletePoster?:  {
    __typename: "Poster",
    id: string,
    owner: string,
    artists:  Array< {
      __typename: "Artist",
      name: string,
      performanceDateTime: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTicketTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTicketTransactionSubscription = {
  onCreateTicketTransaction?:  {
    __typename: "TicketTransaction",
    id: string,
    owner: string,
    date: string,
    fee?: number | null,
    price: number,
    personID: string,
    eventID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    personTicket?:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null,
    promoter?:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    ticketTransactionTicketId: string,
    ticketTransactionRefundId?: string | null,
    ticketTransactionPersonTicketId?: string | null,
    ticketTransactionPromoterId?: string | null,
  } | null,
};

export type OnUpdateTicketTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTicketTransactionSubscription = {
  onUpdateTicketTransaction?:  {
    __typename: "TicketTransaction",
    id: string,
    owner: string,
    date: string,
    fee?: number | null,
    price: number,
    personID: string,
    eventID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    personTicket?:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null,
    promoter?:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    ticketTransactionTicketId: string,
    ticketTransactionRefundId?: string | null,
    ticketTransactionPersonTicketId?: string | null,
    ticketTransactionPromoterId?: string | null,
  } | null,
};

export type OnDeleteTicketTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTicketTransactionSubscription = {
  onDeleteTicketTransaction?:  {
    __typename: "TicketTransaction",
    id: string,
    owner: string,
    date: string,
    fee?: number | null,
    price: number,
    personID: string,
    eventID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    personTicket?:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    } | null,
    promoter?:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    ticketTransactionTicketId: string,
    ticketTransactionRefundId?: string | null,
    ticketTransactionPersonTicketId?: string | null,
    ticketTransactionPromoterId?: string | null,
  } | null,
};

export type OnCreateWalletTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateWalletTransactionSubscription = {
  onCreateWalletTransaction?:  {
    __typename: "WalletTransaction",
    id: string,
    owner: string,
    date: string,
    amount: number,
    method?: PaymentMethod | null,
    status: PaymentStatus,
    metadata?: string | null,
    sourceID?: string | null,
    transactionID: string,
    personID: string,
    provider?: PaymentProviders | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWalletTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateWalletTransactionSubscription = {
  onUpdateWalletTransaction?:  {
    __typename: "WalletTransaction",
    id: string,
    owner: string,
    date: string,
    amount: number,
    method?: PaymentMethod | null,
    status: PaymentStatus,
    metadata?: string | null,
    sourceID?: string | null,
    transactionID: string,
    personID: string,
    provider?: PaymentProviders | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWalletTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteWalletTransactionSubscription = {
  onDeleteWalletTransaction?:  {
    __typename: "WalletTransaction",
    id: string,
    owner: string,
    date: string,
    amount: number,
    method?: PaymentMethod | null,
    status: PaymentStatus,
    metadata?: string | null,
    sourceID?: string | null,
    transactionID: string,
    personID: string,
    provider?: PaymentProviders | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConsumableTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateConsumableTransactionSubscription = {
  onCreateConsumableTransaction?:  {
    __typename: "ConsumableTransaction",
    id: string,
    owner: string,
    date: string,
    isRefunded?: boolean | null,
    personHandle?: string | null,
    price: number,
    collaboratorID: string,
    personID: string,
    eventID: string,
    cart?:  {
      __typename: "ModelPairTransactionConnection",
      items:  Array< {
        __typename: "PairTransaction",
        id: string,
        owner: string,
        personHandle?: string | null,
        transactionID: string,
        amount: number,
        isRefunded?: boolean | null,
        wasUsedInPromo: boolean,
        streakID?: string | null,
        wasPersonConsumable: boolean,
        pricePaid: number,
        whoGaveIt?: string | null,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairTransactionConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    person?:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConsumableTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateConsumableTransactionSubscription = {
  onUpdateConsumableTransaction?:  {
    __typename: "ConsumableTransaction",
    id: string,
    owner: string,
    date: string,
    isRefunded?: boolean | null,
    personHandle?: string | null,
    price: number,
    collaboratorID: string,
    personID: string,
    eventID: string,
    cart?:  {
      __typename: "ModelPairTransactionConnection",
      items:  Array< {
        __typename: "PairTransaction",
        id: string,
        owner: string,
        personHandle?: string | null,
        transactionID: string,
        amount: number,
        isRefunded?: boolean | null,
        wasUsedInPromo: boolean,
        streakID?: string | null,
        wasPersonConsumable: boolean,
        pricePaid: number,
        whoGaveIt?: string | null,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairTransactionConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    person?:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConsumableTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteConsumableTransactionSubscription = {
  onDeleteConsumableTransaction?:  {
    __typename: "ConsumableTransaction",
    id: string,
    owner: string,
    date: string,
    isRefunded?: boolean | null,
    personHandle?: string | null,
    price: number,
    collaboratorID: string,
    personID: string,
    eventID: string,
    cart?:  {
      __typename: "ModelPairTransactionConnection",
      items:  Array< {
        __typename: "PairTransaction",
        id: string,
        owner: string,
        personHandle?: string | null,
        transactionID: string,
        amount: number,
        isRefunded?: boolean | null,
        wasUsedInPromo: boolean,
        streakID?: string | null,
        wasPersonConsumable: boolean,
        pricePaid: number,
        whoGaveIt?: string | null,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairTransactionConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    person?:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePersonTicketSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePersonTicketSubscription = {
  onCreatePersonTicket?:  {
    __typename: "PersonTicket",
    id: string,
    owner: string,
    readDates: Array< string >,
    privacy: Privacy,
    eventID: string,
    collaboratorID?: string | null,
    personID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    transaction?:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personTicketTicketId: string,
    personTicketRefundId?: string | null,
    personTicketTransactionId?: string | null,
  } | null,
};

export type OnUpdatePersonTicketSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePersonTicketSubscription = {
  onUpdatePersonTicket?:  {
    __typename: "PersonTicket",
    id: string,
    owner: string,
    readDates: Array< string >,
    privacy: Privacy,
    eventID: string,
    collaboratorID?: string | null,
    personID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    transaction?:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personTicketTicketId: string,
    personTicketRefundId?: string | null,
    personTicketTransactionId?: string | null,
  } | null,
};

export type OnDeletePersonTicketSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePersonTicketSubscription = {
  onDeletePersonTicket?:  {
    __typename: "PersonTicket",
    id: string,
    owner: string,
    readDates: Array< string >,
    privacy: Privacy,
    eventID: string,
    collaboratorID?: string | null,
    personID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    person:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    refund?:  {
      __typename: "TicketRefund",
      id: string,
      owner: string,
      refundedAmount: number,
      refundedPercentage: number,
      ticketID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      personTicket:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      },
      transaction:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      },
      eventID: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
      ticketRefundPersonTicketId: string,
      ticketRefundTransactionId: string,
    } | null,
    transaction?:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personTicketTicketId: string,
    personTicketRefundId?: string | null,
    personTicketTransactionId?: string | null,
  } | null,
};

export type OnCreateTicketRefundSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTicketRefundSubscription = {
  onCreateTicketRefund?:  {
    __typename: "TicketRefund",
    id: string,
    owner: string,
    refundedAmount: number,
    refundedPercentage: number,
    ticketID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    personTicket:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    },
    transaction:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    },
    eventID: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
    ticketRefundPersonTicketId: string,
    ticketRefundTransactionId: string,
  } | null,
};

export type OnUpdateTicketRefundSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTicketRefundSubscription = {
  onUpdateTicketRefund?:  {
    __typename: "TicketRefund",
    id: string,
    owner: string,
    refundedAmount: number,
    refundedPercentage: number,
    ticketID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    personTicket:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    },
    transaction:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    },
    eventID: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
    ticketRefundPersonTicketId: string,
    ticketRefundTransactionId: string,
  } | null,
};

export type OnDeleteTicketRefundSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTicketRefundSubscription = {
  onDeleteTicketRefund?:  {
    __typename: "TicketRefund",
    id: string,
    owner: string,
    refundedAmount: number,
    refundedPercentage: number,
    ticketID: string,
    ticket:  {
      __typename: "Ticket",
      id: string,
      owner: string,
      name?: string | null,
      initialStock?: number | null,
      stock?: number | null,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      validDates:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      eventName: string,
      metadata?: string | null,
      eventID: string,
      createdAt: string,
      updatedAt: string,
    },
    personTicket:  {
      __typename: "PersonTicket",
      id: string,
      owner: string,
      readDates: Array< string >,
      privacy: Privacy,
      eventID: string,
      collaboratorID?: string | null,
      personID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      person:  {
        __typename: "Person",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        phone: string,
        dateOfBirth: string,
        wallet: number,
        privacy: Privacy,
        handle?: string | null,
        currentEventID?: string | null,
        address?:  {
          __typename: "Address",
          street: string,
          zipCode: string,
          city: string,
          country: string,
        } | null,
        auxIdentity?:  {
          __typename: "AuxiliaryIdentity",
          id: string,
          owner: string,
          validDate: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelPairPersonConnection",
          items:  Array< {
            __typename: "PairPerson",
            id: string,
            owner: string,
            personID: string,
            whoGaveIt: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            eventID: string,
            pricePaid?: number | null,
            createdAt: string,
            updatedAt: string,
            pairPersonConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelPersonTicketConnection",
          items:  Array< {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        eventState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        historyWallet?:  {
          __typename: "ModelWalletTransactionConnection",
          items:  Array< {
            __typename: "WalletTransaction",
            id: string,
            owner: string,
            date: string,
            amount: number,
            method?: PaymentMethod | null,
            status: PaymentStatus,
            metadata?: string | null,
            sourceID?: string | null,
            transactionID: string,
            personID: string,
            provider?: PaymentProviders | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        friends?:  {
          __typename: "ModelFriendConnection",
          items:  Array< {
            __typename: "Friend",
            id: string,
            personID: string,
            friendID: string,
            owner: string,
            friend:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            request: FriendRequest,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personAuxIdentityId?: string | null,
        personAvatarId?: string | null,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      transaction?:  {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personTicketTicketId: string,
      personTicketRefundId?: string | null,
      personTicketTransactionId?: string | null,
    },
    transaction:  {
      __typename: "TicketTransaction",
      id: string,
      owner: string,
      date: string,
      fee?: number | null,
      price: number,
      personID: string,
      eventID: string,
      ticket:  {
        __typename: "Ticket",
        id: string,
        owner: string,
        name?: string | null,
        initialStock?: number | null,
        stock?: number | null,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        validDates:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        eventName: string,
        metadata?: string | null,
        eventID: string,
        createdAt: string,
        updatedAt: string,
      },
      refund?:  {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null,
      personTicket?:  {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null,
      promoter?:  {
        __typename: "Promoter",
        id: string,
        owner: string,
        name: string,
        companyID: string,
        events?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      ticketTransactionTicketId: string,
      ticketTransactionRefundId?: string | null,
      ticketTransactionPersonTicketId?: string | null,
      ticketTransactionPromoterId?: string | null,
    },
    eventID: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
    ticketRefundPersonTicketId: string,
    ticketRefundTransactionId: string,
  } | null,
};

export type OnCreatePersonSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePersonSubscription = {
  onCreatePerson?:  {
    __typename: "Person",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    phone: string,
    dateOfBirth: string,
    wallet: number,
    privacy: Privacy,
    handle?: string | null,
    currentEventID?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      zipCode: string,
      city: string,
      country: string,
    } | null,
    auxIdentity?:  {
      __typename: "AuxiliaryIdentity",
      id: string,
      owner: string,
      validDate: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelPairPersonConnection",
      items:  Array< {
        __typename: "PairPerson",
        id: string,
        owner: string,
        personID: string,
        whoGaveIt: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        eventID: string,
        pricePaid?: number | null,
        createdAt: string,
        updatedAt: string,
        pairPersonConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyWallet?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    friends?:  {
      __typename: "ModelFriendConnection",
      items:  Array< {
        __typename: "Friend",
        id: string,
        personID: string,
        friendID: string,
        owner: string,
        friend:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        request: FriendRequest,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personAuxIdentityId?: string | null,
    personAvatarId?: string | null,
  } | null,
};

export type OnUpdatePersonSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePersonSubscription = {
  onUpdatePerson?:  {
    __typename: "Person",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    phone: string,
    dateOfBirth: string,
    wallet: number,
    privacy: Privacy,
    handle?: string | null,
    currentEventID?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      zipCode: string,
      city: string,
      country: string,
    } | null,
    auxIdentity?:  {
      __typename: "AuxiliaryIdentity",
      id: string,
      owner: string,
      validDate: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelPairPersonConnection",
      items:  Array< {
        __typename: "PairPerson",
        id: string,
        owner: string,
        personID: string,
        whoGaveIt: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        eventID: string,
        pricePaid?: number | null,
        createdAt: string,
        updatedAt: string,
        pairPersonConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyWallet?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    friends?:  {
      __typename: "ModelFriendConnection",
      items:  Array< {
        __typename: "Friend",
        id: string,
        personID: string,
        friendID: string,
        owner: string,
        friend:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        request: FriendRequest,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personAuxIdentityId?: string | null,
    personAvatarId?: string | null,
  } | null,
};

export type OnDeletePersonSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePersonSubscription = {
  onDeletePerson?:  {
    __typename: "Person",
    id: string,
    owner: string,
    name: string,
    email?: string | null,
    phone: string,
    dateOfBirth: string,
    wallet: number,
    privacy: Privacy,
    handle?: string | null,
    currentEventID?: string | null,
    address?:  {
      __typename: "Address",
      street: string,
      zipCode: string,
      city: string,
      country: string,
    } | null,
    auxIdentity?:  {
      __typename: "AuxiliaryIdentity",
      id: string,
      owner: string,
      validDate: string,
      personID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    avatar?:  {
      __typename: "Image",
      id: string,
      owner: string,
      eventID?: string | null,
      filename: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    event?:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    } | null,
    consumables?:  {
      __typename: "ModelPairPersonConnection",
      items:  Array< {
        __typename: "PairPerson",
        id: string,
        owner: string,
        personID: string,
        whoGaveIt: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        eventID: string,
        pricePaid?: number | null,
        createdAt: string,
        updatedAt: string,
        pairPersonConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    tickets?:  {
      __typename: "ModelPersonTicketConnection",
      items:  Array< {
        __typename: "PersonTicket",
        id: string,
        owner: string,
        readDates: Array< string >,
        privacy: Privacy,
        eventID: string,
        collaboratorID?: string | null,
        personID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        transaction?:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        personTicketTicketId: string,
        personTicketRefundId?: string | null,
        personTicketTransactionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    eventState?:  {
      __typename: "ModelPersonEventStateConnection",
      items:  Array< {
        __typename: "PersonEventState",
        id: string,
        owner: string,
        personID: string,
        eventID: string,
        score: number,
        person:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        streaks?:  {
          __typename: "ModelStreakStateConnection",
          items:  Array< {
            __typename: "StreakState",
            id: string,
            owner: string,
            personStateID: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streak:  {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            },
            createdAt: string,
            updatedAt: string,
            streakStateStreakId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        previous?:  {
          __typename: "PreviousEventState",
          id: string,
          owner: string,
          score: number,
          streaks:  Array< {
            __typename: "PreviousStreakState",
            id: string,
            currentState: Array< string >,
            prizeState?: Array< string > | null,
            nrOfTimesCompleted: number,
            streakID: string,
          } >,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        personEventStatePreviousId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyTickets?:  {
      __typename: "ModelTicketTransactionConnection",
      items:  Array< {
        __typename: "TicketTransaction",
        id: string,
        owner: string,
        date: string,
        fee?: number | null,
        price: number,
        personID: string,
        eventID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        refund?:  {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null,
        personTicket?:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null,
        promoter?:  {
          __typename: "Promoter",
          id: string,
          owner: string,
          name: string,
          companyID: string,
          events?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        ticketTransactionTicketId: string,
        ticketTransactionRefundId?: string | null,
        ticketTransactionPersonTicketId?: string | null,
        ticketTransactionPromoterId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyConsumables?:  {
      __typename: "ModelConsumableTransactionConnection",
      items:  Array< {
        __typename: "ConsumableTransaction",
        id: string,
        owner: string,
        date: string,
        isRefunded?: boolean | null,
        personHandle?: string | null,
        price: number,
        collaboratorID: string,
        personID: string,
        eventID: string,
        cart?:  {
          __typename: "ModelPairTransactionConnection",
          items:  Array< {
            __typename: "PairTransaction",
            id: string,
            owner: string,
            personHandle?: string | null,
            transactionID: string,
            amount: number,
            isRefunded?: boolean | null,
            wasUsedInPromo: boolean,
            streakID?: string | null,
            wasPersonConsumable: boolean,
            pricePaid: number,
            whoGaveIt?: string | null,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairTransactionConsumableId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        person?:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        } | null,
        event?:  {
          __typename: "Event",
          id: string,
          owner: string,
          name: string,
          promotions: Array< Promotions >,
          location:  {
            __typename: "Location",
            lon: number,
            lat: number,
          },
          addressAlias: string,
          companyID: string,
          description: string,
          startDate: string,
          endDate: string,
          date:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          maxCapacity?: number | null,
          company?:  {
            __typename: "Company",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            iban: string,
            initials: string,
            handle?: string | null,
            consumablesFee: number,
            ticketsFee: number,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelEventConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterConnection",
              nextToken?: string | null,
            } | null,
            members?:  {
              __typename: "ModelMemberConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            companyAvatarId?: string | null,
          } | null,
          banner:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          },
          poster?:  {
            __typename: "Poster",
            id: string,
            owner: string,
            artists:  Array< {
              __typename: "Artist",
              name: string,
              performanceDateTime: string,
            } | null >,
            createdAt: string,
            updatedAt: string,
          } | null,
          streaks?:  {
            __typename: "ModelStreakConnection",
            items:  Array< {
              __typename: "Streak",
              id: string,
              owner: string,
              name: string,
              isRepeatable: boolean,
              promotions: Array< Promotions >,
              eventID: string,
              promoPrice: number,
              createdAt: string,
              updatedAt: string,
              streakPromoPairId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          photos?:  {
            __typename: "ModelImageConnection",
            items:  Array< {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelTicketConnection",
            items:  Array< {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorEventConnectionConnection",
            items:  Array< {
              __typename: "CollaboratorEventConnection",
              id: string,
              collaboratorID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelConsumableConnection",
            items:  Array< {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          peopleState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          transactionsConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterEventConnectionConnection",
            items:  Array< {
              __typename: "PromoterEventConnection",
              id: string,
              promoterID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          eventBannerId: string,
          eventPosterId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    historyWallet?:  {
      __typename: "ModelWalletTransactionConnection",
      items:  Array< {
        __typename: "WalletTransaction",
        id: string,
        owner: string,
        date: string,
        amount: number,
        method?: PaymentMethod | null,
        status: PaymentStatus,
        metadata?: string | null,
        sourceID?: string | null,
        transactionID: string,
        personID: string,
        provider?: PaymentProviders | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    friends?:  {
      __typename: "ModelFriendConnection",
      items:  Array< {
        __typename: "Friend",
        id: string,
        personID: string,
        friendID: string,
        owner: string,
        friend:  {
          __typename: "Person",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          phone: string,
          dateOfBirth: string,
          wallet: number,
          privacy: Privacy,
          handle?: string | null,
          currentEventID?: string | null,
          address?:  {
            __typename: "Address",
            street: string,
            zipCode: string,
            city: string,
            country: string,
          } | null,
          auxIdentity?:  {
            __typename: "AuxiliaryIdentity",
            id: string,
            owner: string,
            validDate: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          consumables?:  {
            __typename: "ModelPairPersonConnection",
            items:  Array< {
              __typename: "PairPerson",
              id: string,
              owner: string,
              personID: string,
              whoGaveIt: string,
              amount: number,
              eventID: string,
              pricePaid?: number | null,
              createdAt: string,
              updatedAt: string,
              pairPersonConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          tickets?:  {
            __typename: "ModelPersonTicketConnection",
            items:  Array< {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          eventState?:  {
            __typename: "ModelPersonEventStateConnection",
            items:  Array< {
              __typename: "PersonEventState",
              id: string,
              owner: string,
              personID: string,
              eventID: string,
              score: number,
              createdAt: string,
              updatedAt: string,
              personEventStatePreviousId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyTickets?:  {
            __typename: "ModelTicketTransactionConnection",
            items:  Array< {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyConsumables?:  {
            __typename: "ModelConsumableTransactionConnection",
            items:  Array< {
              __typename: "ConsumableTransaction",
              id: string,
              owner: string,
              date: string,
              isRefunded?: boolean | null,
              personHandle?: string | null,
              price: number,
              collaboratorID: string,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          historyWallet?:  {
            __typename: "ModelWalletTransactionConnection",
            items:  Array< {
              __typename: "WalletTransaction",
              id: string,
              owner: string,
              date: string,
              amount: number,
              method?: PaymentMethod | null,
              status: PaymentStatus,
              metadata?: string | null,
              sourceID?: string | null,
              transactionID: string,
              personID: string,
              provider?: PaymentProviders | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          friends?:  {
            __typename: "ModelFriendConnection",
            items:  Array< {
              __typename: "Friend",
              id: string,
              personID: string,
              friendID: string,
              owner: string,
              request: FriendRequest,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          ticketRefunds?:  {
            __typename: "ModelTicketRefundConnection",
            items:  Array< {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personAuxIdentityId?: string | null,
          personAvatarId?: string | null,
        },
        request: FriendRequest,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    ticketRefunds?:  {
      __typename: "ModelTicketRefundConnection",
      items:  Array< {
        __typename: "TicketRefund",
        id: string,
        owner: string,
        refundedAmount: number,
        refundedPercentage: number,
        ticketID: string,
        ticket:  {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        },
        personTicket:  {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        },
        transaction:  {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        },
        eventID: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
        ticketRefundPersonTicketId: string,
        ticketRefundTransactionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    personAuxIdentityId?: string | null,
    personAvatarId?: string | null,
  } | null,
};

export type OnCreateAuxiliaryIdentitySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateAuxiliaryIdentitySubscription = {
  onCreateAuxiliaryIdentity?:  {
    __typename: "AuxiliaryIdentity",
    id: string,
    owner: string,
    validDate: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAuxiliaryIdentitySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateAuxiliaryIdentitySubscription = {
  onUpdateAuxiliaryIdentity?:  {
    __typename: "AuxiliaryIdentity",
    id: string,
    owner: string,
    validDate: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAuxiliaryIdentitySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteAuxiliaryIdentitySubscription = {
  onDeleteAuxiliaryIdentity?:  {
    __typename: "AuxiliaryIdentity",
    id: string,
    owner: string,
    validDate: string,
    personID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFriendSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateFriendSubscription = {
  onCreateFriend?:  {
    __typename: "Friend",
    id: string,
    personID: string,
    friendID: string,
    owner: string,
    friend:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    request: FriendRequest,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFriendSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateFriendSubscription = {
  onUpdateFriend?:  {
    __typename: "Friend",
    id: string,
    personID: string,
    friendID: string,
    owner: string,
    friend:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    request: FriendRequest,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFriendSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteFriendSubscription = {
  onDeleteFriend?:  {
    __typename: "Friend",
    id: string,
    personID: string,
    friendID: string,
    owner: string,
    friend:  {
      __typename: "Person",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      phone: string,
      dateOfBirth: string,
      wallet: number,
      privacy: Privacy,
      handle?: string | null,
      currentEventID?: string | null,
      address?:  {
        __typename: "Address",
        street: string,
        zipCode: string,
        city: string,
        country: string,
      } | null,
      auxIdentity?:  {
        __typename: "AuxiliaryIdentity",
        id: string,
        owner: string,
        validDate: string,
        personID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      event?:  {
        __typename: "Event",
        id: string,
        owner: string,
        name: string,
        promotions: Array< Promotions >,
        location:  {
          __typename: "Location",
          lon: number,
          lat: number,
        },
        addressAlias: string,
        companyID: string,
        description: string,
        startDate: string,
        endDate: string,
        date:  Array< {
          __typename: "DateSpan",
          start: string,
          end: string,
        } >,
        maxCapacity?: number | null,
        company?:  {
          __typename: "Company",
          id: string,
          owner: string,
          name: string,
          email?: string | null,
          iban: string,
          initials: string,
          handle?: string | null,
          consumablesFee: number,
          ticketsFee: number,
          avatar?:  {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          collaborators?:  {
            __typename: "ModelCollaboratorConnection",
            items:  Array< {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          events?:  {
            __typename: "ModelEventConnection",
            items:  Array< {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          promoters?:  {
            __typename: "ModelPromoterConnection",
            items:  Array< {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelMemberConnection",
            items:  Array< {
              __typename: "Member",
              id: string,
              owner: string,
              name?: string | null,
              internalID?: string | null,
              email?: string | null,
              phone: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          companyAvatarId?: string | null,
        } | null,
        banner:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        },
        poster?:  {
          __typename: "Poster",
          id: string,
          owner: string,
          artists:  Array< {
            __typename: "Artist",
            name: string,
            performanceDateTime: string,
          } | null >,
          createdAt: string,
          updatedAt: string,
        } | null,
        streaks?:  {
          __typename: "ModelStreakConnection",
          items:  Array< {
            __typename: "Streak",
            id: string,
            owner: string,
            name: string,
            isRepeatable: boolean,
            promotions: Array< Promotions >,
            eventID: string,
            promoPrice: number,
            promoPair:  {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            },
            set?:  {
              __typename: "ModelPairStreakConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            streakPromoPairId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        photos?:  {
          __typename: "ModelImageConnection",
          items:  Array< {
            __typename: "Image",
            id: string,
            owner: string,
            eventID?: string | null,
            filename: string,
            identityID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        tickets?:  {
          __typename: "ModelTicketConnection",
          items:  Array< {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorEventConnectionConnection",
          items:  Array< {
            __typename: "CollaboratorEventConnection",
            id: string,
            collaboratorID: string,
            eventID: string,
            collaborator:  {
              __typename: "Collaborator",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              handle?: string | null,
              phone: string,
              activity?: string | null,
              companyID: string,
              createdAt: string,
              updatedAt: string,
              collaboratorAvatarId?: string | null,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        consumables?:  {
          __typename: "ModelConsumableConnection",
          items:  Array< {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        peopleState?:  {
          __typename: "ModelPersonEventStateConnection",
          items:  Array< {
            __typename: "PersonEventState",
            id: string,
            owner: string,
            personID: string,
            eventID: string,
            score: number,
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            streaks?:  {
              __typename: "ModelStreakStateConnection",
              nextToken?: string | null,
            } | null,
            previous?:  {
              __typename: "PreviousEventState",
              id: string,
              owner: string,
              score: number,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            personEventStatePreviousId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsTickets?:  {
          __typename: "ModelTicketTransactionConnection",
          items:  Array< {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        transactionsConsumables?:  {
          __typename: "ModelConsumableTransactionConnection",
          items:  Array< {
            __typename: "ConsumableTransaction",
            id: string,
            owner: string,
            date: string,
            isRefunded?: boolean | null,
            personHandle?: string | null,
            price: number,
            collaboratorID: string,
            personID: string,
            eventID: string,
            cart?:  {
              __typename: "ModelPairTransactionConnection",
              nextToken?: string | null,
            } | null,
            person?:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        ticketRefunds?:  {
          __typename: "ModelTicketRefundConnection",
          items:  Array< {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterEventConnectionConnection",
          items:  Array< {
            __typename: "PromoterEventConnection",
            id: string,
            promoterID: string,
            eventID: string,
            promoter:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            },
            event:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        eventBannerId: string,
        eventPosterId?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelPairPersonConnection",
        items:  Array< {
          __typename: "PairPerson",
          id: string,
          owner: string,
          personID: string,
          whoGaveIt: string,
          amount: number,
          consumable:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          },
          eventID: string,
          pricePaid?: number | null,
          createdAt: string,
          updatedAt: string,
          pairPersonConsumableId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      eventState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      historyWallet?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      friends?:  {
        __typename: "ModelFriendConnection",
        items:  Array< {
          __typename: "Friend",
          id: string,
          personID: string,
          friendID: string,
          owner: string,
          friend:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          request: FriendRequest,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      personAuxIdentityId?: string | null,
      personAvatarId?: string | null,
    },
    request: FriendRequest,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConsumableSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateConsumableSubscription = {
  onCreateConsumable?:  {
    __typename: "Consumable",
    id: string,
    owner: string,
    type: ConsumableType,
    size: ConsumableSize,
    name: string,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    companyID?: string | null,
    eventID: string,
    smallOrLargeRef?:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    consumableSmallOrLargeRefId?: string | null,
  } | null,
};

export type OnUpdateConsumableSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateConsumableSubscription = {
  onUpdateConsumable?:  {
    __typename: "Consumable",
    id: string,
    owner: string,
    type: ConsumableType,
    size: ConsumableSize,
    name: string,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    companyID?: string | null,
    eventID: string,
    smallOrLargeRef?:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    consumableSmallOrLargeRefId?: string | null,
  } | null,
};

export type OnDeleteConsumableSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteConsumableSubscription = {
  onDeleteConsumable?:  {
    __typename: "Consumable",
    id: string,
    owner: string,
    type: ConsumableType,
    size: ConsumableSize,
    name: string,
    prices:  {
      __typename: "Prices",
      price: number,
      memberPrice?: number | null,
      promoPrices:  Array< {
        __typename: "PromoPrices",
        dateSpan:  {
          __typename: "DateSpan",
          start: string,
          end: string,
        },
        memberPrice?: number | null,
        price: number,
      } >,
    },
    companyID?: string | null,
    eventID: string,
    smallOrLargeRef?:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    consumableSmallOrLargeRefId?: string | null,
  } | null,
};

export type OnCreateStreakSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateStreakSubscription = {
  onCreateStreak?:  {
    __typename: "Streak",
    id: string,
    owner: string,
    name: string,
    isRepeatable: boolean,
    promotions: Array< Promotions >,
    eventID: string,
    promoPrice: number,
    promoPair:  {
      __typename: "PairStreak",
      id: string,
      owner: string,
      streakID: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairStreakConsumableId: string,
    },
    set?:  {
      __typename: "ModelPairStreakConnection",
      items:  Array< {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    streakPromoPairId: string,
  } | null,
};

export type OnUpdateStreakSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateStreakSubscription = {
  onUpdateStreak?:  {
    __typename: "Streak",
    id: string,
    owner: string,
    name: string,
    isRepeatable: boolean,
    promotions: Array< Promotions >,
    eventID: string,
    promoPrice: number,
    promoPair:  {
      __typename: "PairStreak",
      id: string,
      owner: string,
      streakID: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairStreakConsumableId: string,
    },
    set?:  {
      __typename: "ModelPairStreakConnection",
      items:  Array< {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    streakPromoPairId: string,
  } | null,
};

export type OnDeleteStreakSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteStreakSubscription = {
  onDeleteStreak?:  {
    __typename: "Streak",
    id: string,
    owner: string,
    name: string,
    isRepeatable: boolean,
    promotions: Array< Promotions >,
    eventID: string,
    promoPrice: number,
    promoPair:  {
      __typename: "PairStreak",
      id: string,
      owner: string,
      streakID: string,
      amount: number,
      consumable:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      pairStreakConsumableId: string,
    },
    set?:  {
      __typename: "ModelPairStreakConnection",
      items:  Array< {
        __typename: "PairStreak",
        id: string,
        owner: string,
        streakID: string,
        amount: number,
        consumable:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        pairStreakConsumableId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    streakPromoPairId: string,
  } | null,
};

export type OnCreateImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateImageSubscription = {
  onCreateImage?:  {
    __typename: "Image",
    id: string,
    owner: string,
    eventID?: string | null,
    filename: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateImageSubscription = {
  onUpdateImage?:  {
    __typename: "Image",
    id: string,
    owner: string,
    eventID?: string | null,
    filename: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteImageSubscription = {
  onDeleteImage?:  {
    __typename: "Image",
    id: string,
    owner: string,
    eventID?: string | null,
    filename: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePairPersonSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePairPersonSubscription = {
  onCreatePairPerson?:  {
    __typename: "PairPerson",
    id: string,
    owner: string,
    personID: string,
    whoGaveIt: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    eventID: string,
    pricePaid?: number | null,
    createdAt: string,
    updatedAt: string,
    pairPersonConsumableId: string,
  } | null,
};

export type OnUpdatePairPersonSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePairPersonSubscription = {
  onUpdatePairPerson?:  {
    __typename: "PairPerson",
    id: string,
    owner: string,
    personID: string,
    whoGaveIt: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    eventID: string,
    pricePaid?: number | null,
    createdAt: string,
    updatedAt: string,
    pairPersonConsumableId: string,
  } | null,
};

export type OnDeletePairPersonSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePairPersonSubscription = {
  onDeletePairPerson?:  {
    __typename: "PairPerson",
    id: string,
    owner: string,
    personID: string,
    whoGaveIt: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    eventID: string,
    pricePaid?: number | null,
    createdAt: string,
    updatedAt: string,
    pairPersonConsumableId: string,
  } | null,
};

export type OnCreatePairStreakSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePairStreakSubscription = {
  onCreatePairStreak?:  {
    __typename: "PairStreak",
    id: string,
    owner: string,
    streakID: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairStreakConsumableId: string,
  } | null,
};

export type OnUpdatePairStreakSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePairStreakSubscription = {
  onUpdatePairStreak?:  {
    __typename: "PairStreak",
    id: string,
    owner: string,
    streakID: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairStreakConsumableId: string,
  } | null,
};

export type OnDeletePairStreakSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePairStreakSubscription = {
  onDeletePairStreak?:  {
    __typename: "PairStreak",
    id: string,
    owner: string,
    streakID: string,
    amount: number,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairStreakConsumableId: string,
  } | null,
};

export type OnCreatePairTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePairTransactionSubscription = {
  onCreatePairTransaction?:  {
    __typename: "PairTransaction",
    id: string,
    owner: string,
    personHandle?: string | null,
    transactionID: string,
    amount: number,
    isRefunded?: boolean | null,
    wasUsedInPromo: boolean,
    streakID?: string | null,
    wasPersonConsumable: boolean,
    pricePaid: number,
    whoGaveIt?: string | null,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairTransactionConsumableId: string,
  } | null,
};

export type OnUpdatePairTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePairTransactionSubscription = {
  onUpdatePairTransaction?:  {
    __typename: "PairTransaction",
    id: string,
    owner: string,
    personHandle?: string | null,
    transactionID: string,
    amount: number,
    isRefunded?: boolean | null,
    wasUsedInPromo: boolean,
    streakID?: string | null,
    wasPersonConsumable: boolean,
    pricePaid: number,
    whoGaveIt?: string | null,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairTransactionConsumableId: string,
  } | null,
};

export type OnDeletePairTransactionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePairTransactionSubscription = {
  onDeletePairTransaction?:  {
    __typename: "PairTransaction",
    id: string,
    owner: string,
    personHandle?: string | null,
    transactionID: string,
    amount: number,
    isRefunded?: boolean | null,
    wasUsedInPromo: boolean,
    streakID?: string | null,
    wasPersonConsumable: boolean,
    pricePaid: number,
    whoGaveIt?: string | null,
    consumable:  {
      __typename: "Consumable",
      id: string,
      owner: string,
      type: ConsumableType,
      size: ConsumableSize,
      name: string,
      prices:  {
        __typename: "Prices",
        price: number,
        memberPrice?: number | null,
        promoPrices:  Array< {
          __typename: "PromoPrices",
          dateSpan:  {
            __typename: "DateSpan",
            start: string,
            end: string,
          },
          memberPrice?: number | null,
          price: number,
        } >,
      },
      companyID?: string | null,
      eventID: string,
      smallOrLargeRef?:  {
        __typename: "Consumable",
        id: string,
        owner: string,
        type: ConsumableType,
        size: ConsumableSize,
        name: string,
        prices:  {
          __typename: "Prices",
          price: number,
          memberPrice?: number | null,
          promoPrices:  Array< {
            __typename: "PromoPrices",
            dateSpan:  {
              __typename: "DateSpan",
              start: string,
              end: string,
            },
            memberPrice?: number | null,
            price: number,
          } >,
        },
        companyID?: string | null,
        eventID: string,
        smallOrLargeRef?:  {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        consumableSmallOrLargeRefId?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      consumableSmallOrLargeRefId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    pairTransactionConsumableId: string,
  } | null,
};

export type OnCreateCollaboratorEventConnectionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCollaboratorEventConnectionSubscription = {
  onCreateCollaboratorEventConnection?:  {
    __typename: "CollaboratorEventConnection",
    id: string,
    collaboratorID: string,
    eventID: string,
    collaborator:  {
      __typename: "Collaborator",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      handle?: string | null,
      phone: string,
      activity?: string | null,
      companyID: string,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      readTickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      chargeHistory?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      collaboratorAvatarId?: string | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCollaboratorEventConnectionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCollaboratorEventConnectionSubscription = {
  onUpdateCollaboratorEventConnection?:  {
    __typename: "CollaboratorEventConnection",
    id: string,
    collaboratorID: string,
    eventID: string,
    collaborator:  {
      __typename: "Collaborator",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      handle?: string | null,
      phone: string,
      activity?: string | null,
      companyID: string,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      readTickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      chargeHistory?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      collaboratorAvatarId?: string | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCollaboratorEventConnectionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCollaboratorEventConnectionSubscription = {
  onDeleteCollaboratorEventConnection?:  {
    __typename: "CollaboratorEventConnection",
    id: string,
    collaboratorID: string,
    eventID: string,
    collaborator:  {
      __typename: "Collaborator",
      id: string,
      owner: string,
      name: string,
      email?: string | null,
      handle?: string | null,
      phone: string,
      activity?: string | null,
      companyID: string,
      avatar?:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      readTickets?:  {
        __typename: "ModelPersonTicketConnection",
        items:  Array< {
          __typename: "PersonTicket",
          id: string,
          owner: string,
          readDates: Array< string >,
          privacy: Privacy,
          eventID: string,
          collaboratorID?: string | null,
          personID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          transaction?:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          personTicketTicketId: string,
          personTicketRefundId?: string | null,
          personTicketTransactionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      history?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      events?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      chargeHistory?:  {
        __typename: "ModelWalletTransactionConnection",
        items:  Array< {
          __typename: "WalletTransaction",
          id: string,
          owner: string,
          date: string,
          amount: number,
          method?: PaymentMethod | null,
          status: PaymentStatus,
          metadata?: string | null,
          sourceID?: string | null,
          transactionID: string,
          personID: string,
          provider?: PaymentProviders | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      collaboratorAvatarId?: string | null,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePromoterEventConnectionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePromoterEventConnectionSubscription = {
  onCreatePromoterEventConnection?:  {
    __typename: "PromoterEventConnection",
    id: string,
    promoterID: string,
    eventID: string,
    promoter:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePromoterEventConnectionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePromoterEventConnectionSubscription = {
  onUpdatePromoterEventConnection?:  {
    __typename: "PromoterEventConnection",
    id: string,
    promoterID: string,
    eventID: string,
    promoter:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePromoterEventConnectionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePromoterEventConnectionSubscription = {
  onDeletePromoterEventConnection?:  {
    __typename: "PromoterEventConnection",
    id: string,
    promoterID: string,
    eventID: string,
    promoter:  {
      __typename: "Promoter",
      id: string,
      owner: string,
      name: string,
      companyID: string,
      events?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    event:  {
      __typename: "Event",
      id: string,
      owner: string,
      name: string,
      promotions: Array< Promotions >,
      location:  {
        __typename: "Location",
        lon: number,
        lat: number,
      },
      addressAlias: string,
      companyID: string,
      description: string,
      startDate: string,
      endDate: string,
      date:  Array< {
        __typename: "DateSpan",
        start: string,
        end: string,
      } >,
      maxCapacity?: number | null,
      company?:  {
        __typename: "Company",
        id: string,
        owner: string,
        name: string,
        email?: string | null,
        iban: string,
        initials: string,
        handle?: string | null,
        consumablesFee: number,
        ticketsFee: number,
        avatar?:  {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        collaborators?:  {
          __typename: "ModelCollaboratorConnection",
          items:  Array< {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        events?:  {
          __typename: "ModelEventConnection",
          items:  Array< {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        promoters?:  {
          __typename: "ModelPromoterConnection",
          items:  Array< {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelMemberConnection",
          items:  Array< {
            __typename: "Member",
            id: string,
            owner: string,
            name?: string | null,
            internalID?: string | null,
            email?: string | null,
            phone: string,
            companyID: string,
            company:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        companyAvatarId?: string | null,
      } | null,
      banner:  {
        __typename: "Image",
        id: string,
        owner: string,
        eventID?: string | null,
        filename: string,
        identityID: string,
        createdAt: string,
        updatedAt: string,
      },
      poster?:  {
        __typename: "Poster",
        id: string,
        owner: string,
        artists:  Array< {
          __typename: "Artist",
          name: string,
          performanceDateTime: string,
        } | null >,
        createdAt: string,
        updatedAt: string,
      } | null,
      streaks?:  {
        __typename: "ModelStreakConnection",
        items:  Array< {
          __typename: "Streak",
          id: string,
          owner: string,
          name: string,
          isRepeatable: boolean,
          promotions: Array< Promotions >,
          eventID: string,
          promoPrice: number,
          promoPair:  {
            __typename: "PairStreak",
            id: string,
            owner: string,
            streakID: string,
            amount: number,
            consumable:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            pairStreakConsumableId: string,
          },
          set?:  {
            __typename: "ModelPairStreakConnection",
            items:  Array< {
              __typename: "PairStreak",
              id: string,
              owner: string,
              streakID: string,
              amount: number,
              createdAt: string,
              updatedAt: string,
              pairStreakConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          streakPromoPairId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      photos?:  {
        __typename: "ModelImageConnection",
        items:  Array< {
          __typename: "Image",
          id: string,
          owner: string,
          eventID?: string | null,
          filename: string,
          identityID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      tickets?:  {
        __typename: "ModelTicketConnection",
        items:  Array< {
          __typename: "Ticket",
          id: string,
          owner: string,
          name?: string | null,
          initialStock?: number | null,
          stock?: number | null,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          validDates:  Array< {
            __typename: "DateSpan",
            start: string,
            end: string,
          } >,
          eventName: string,
          metadata?: string | null,
          eventID: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      collaborators?:  {
        __typename: "ModelCollaboratorEventConnectionConnection",
        items:  Array< {
          __typename: "CollaboratorEventConnection",
          id: string,
          collaboratorID: string,
          eventID: string,
          collaborator:  {
            __typename: "Collaborator",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            handle?: string | null,
            phone: string,
            activity?: string | null,
            companyID: string,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            readTickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            history?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            events?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            chargeHistory?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            collaboratorAvatarId?: string | null,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      consumables?:  {
        __typename: "ModelConsumableConnection",
        items:  Array< {
          __typename: "Consumable",
          id: string,
          owner: string,
          type: ConsumableType,
          size: ConsumableSize,
          name: string,
          prices:  {
            __typename: "Prices",
            price: number,
            memberPrice?: number | null,
            promoPrices:  Array< {
              __typename: "PromoPrices",
              memberPrice?: number | null,
              price: number,
            } >,
          },
          companyID?: string | null,
          eventID: string,
          smallOrLargeRef?:  {
            __typename: "Consumable",
            id: string,
            owner: string,
            type: ConsumableType,
            size: ConsumableSize,
            name: string,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            companyID?: string | null,
            eventID: string,
            smallOrLargeRef?:  {
              __typename: "Consumable",
              id: string,
              owner: string,
              type: ConsumableType,
              size: ConsumableSize,
              name: string,
              companyID?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              consumableSmallOrLargeRefId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            consumableSmallOrLargeRefId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          consumableSmallOrLargeRefId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      peopleState?:  {
        __typename: "ModelPersonEventStateConnection",
        items:  Array< {
          __typename: "PersonEventState",
          id: string,
          owner: string,
          personID: string,
          eventID: string,
          score: number,
          person:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          },
          streaks?:  {
            __typename: "ModelStreakStateConnection",
            items:  Array< {
              __typename: "StreakState",
              id: string,
              owner: string,
              personStateID: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              createdAt: string,
              updatedAt: string,
              streakStateStreakId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          previous?:  {
            __typename: "PreviousEventState",
            id: string,
            owner: string,
            score: number,
            streaks:  Array< {
              __typename: "PreviousStreakState",
              id: string,
              currentState: Array< string >,
              prizeState?: Array< string > | null,
              nrOfTimesCompleted: number,
              streakID: string,
            } >,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          personEventStatePreviousId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsTickets?:  {
        __typename: "ModelTicketTransactionConnection",
        items:  Array< {
          __typename: "TicketTransaction",
          id: string,
          owner: string,
          date: string,
          fee?: number | null,
          price: number,
          personID: string,
          eventID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          refund?:  {
            __typename: "TicketRefund",
            id: string,
            owner: string,
            refundedAmount: number,
            refundedPercentage: number,
            ticketID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            personTicket:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            },
            transaction:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            },
            eventID: string,
            personID: string,
            createdAt: string,
            updatedAt: string,
            ticketRefundPersonTicketId: string,
            ticketRefundTransactionId: string,
          } | null,
          personTicket?:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          } | null,
          promoter?:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          } | null,
          createdAt: string,
          updatedAt: string,
          ticketTransactionTicketId: string,
          ticketTransactionRefundId?: string | null,
          ticketTransactionPersonTicketId?: string | null,
          ticketTransactionPromoterId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      transactionsConsumables?:  {
        __typename: "ModelConsumableTransactionConnection",
        items:  Array< {
          __typename: "ConsumableTransaction",
          id: string,
          owner: string,
          date: string,
          isRefunded?: boolean | null,
          personHandle?: string | null,
          price: number,
          collaboratorID: string,
          personID: string,
          eventID: string,
          cart?:  {
            __typename: "ModelPairTransactionConnection",
            items:  Array< {
              __typename: "PairTransaction",
              id: string,
              owner: string,
              personHandle?: string | null,
              transactionID: string,
              amount: number,
              isRefunded?: boolean | null,
              wasUsedInPromo: boolean,
              streakID?: string | null,
              wasPersonConsumable: boolean,
              pricePaid: number,
              whoGaveIt?: string | null,
              createdAt: string,
              updatedAt: string,
              pairTransactionConsumableId: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          person?:  {
            __typename: "Person",
            id: string,
            owner: string,
            name: string,
            email?: string | null,
            phone: string,
            dateOfBirth: string,
            wallet: number,
            privacy: Privacy,
            handle?: string | null,
            currentEventID?: string | null,
            address?:  {
              __typename: "Address",
              street: string,
              zipCode: string,
              city: string,
              country: string,
            } | null,
            auxIdentity?:  {
              __typename: "AuxiliaryIdentity",
              id: string,
              owner: string,
              validDate: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            avatar?:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            event?:  {
              __typename: "Event",
              id: string,
              owner: string,
              name: string,
              promotions: Array< Promotions >,
              addressAlias: string,
              companyID: string,
              description: string,
              startDate: string,
              endDate: string,
              maxCapacity?: number | null,
              createdAt: string,
              updatedAt: string,
              eventBannerId: string,
              eventPosterId?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelPairPersonConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelPersonTicketConnection",
              nextToken?: string | null,
            } | null,
            eventState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            historyTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            historyWallet?:  {
              __typename: "ModelWalletTransactionConnection",
              nextToken?: string | null,
            } | null,
            friends?:  {
              __typename: "ModelFriendConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personAuxIdentityId?: string | null,
            personAvatarId?: string | null,
          } | null,
          event?:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          } | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      ticketRefunds?:  {
        __typename: "ModelTicketRefundConnection",
        items:  Array< {
          __typename: "TicketRefund",
          id: string,
          owner: string,
          refundedAmount: number,
          refundedPercentage: number,
          ticketID: string,
          ticket:  {
            __typename: "Ticket",
            id: string,
            owner: string,
            name?: string | null,
            initialStock?: number | null,
            stock?: number | null,
            prices:  {
              __typename: "Prices",
              price: number,
              memberPrice?: number | null,
            },
            validDates:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            eventName: string,
            metadata?: string | null,
            eventID: string,
            createdAt: string,
            updatedAt: string,
          },
          personTicket:  {
            __typename: "PersonTicket",
            id: string,
            owner: string,
            readDates: Array< string >,
            privacy: Privacy,
            eventID: string,
            collaboratorID?: string | null,
            personID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            person:  {
              __typename: "Person",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              phone: string,
              dateOfBirth: string,
              wallet: number,
              privacy: Privacy,
              handle?: string | null,
              currentEventID?: string | null,
              createdAt: string,
              updatedAt: string,
              personAuxIdentityId?: string | null,
              personAvatarId?: string | null,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            transaction?:  {
              __typename: "TicketTransaction",
              id: string,
              owner: string,
              date: string,
              fee?: number | null,
              price: number,
              personID: string,
              eventID: string,
              createdAt: string,
              updatedAt: string,
              ticketTransactionTicketId: string,
              ticketTransactionRefundId?: string | null,
              ticketTransactionPersonTicketId?: string | null,
              ticketTransactionPromoterId?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            personTicketTicketId: string,
            personTicketRefundId?: string | null,
            personTicketTransactionId?: string | null,
          },
          transaction:  {
            __typename: "TicketTransaction",
            id: string,
            owner: string,
            date: string,
            fee?: number | null,
            price: number,
            personID: string,
            eventID: string,
            ticket:  {
              __typename: "Ticket",
              id: string,
              owner: string,
              name?: string | null,
              initialStock?: number | null,
              stock?: number | null,
              eventName: string,
              metadata?: string | null,
              eventID: string,
              createdAt: string,
              updatedAt: string,
            },
            refund?:  {
              __typename: "TicketRefund",
              id: string,
              owner: string,
              refundedAmount: number,
              refundedPercentage: number,
              ticketID: string,
              eventID: string,
              personID: string,
              createdAt: string,
              updatedAt: string,
              ticketRefundPersonTicketId: string,
              ticketRefundTransactionId: string,
            } | null,
            personTicket?:  {
              __typename: "PersonTicket",
              id: string,
              owner: string,
              readDates: Array< string >,
              privacy: Privacy,
              eventID: string,
              collaboratorID?: string | null,
              personID: string,
              createdAt: string,
              updatedAt: string,
              personTicketTicketId: string,
              personTicketRefundId?: string | null,
              personTicketTransactionId?: string | null,
            } | null,
            promoter?:  {
              __typename: "Promoter",
              id: string,
              owner: string,
              name: string,
              companyID: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            createdAt: string,
            updatedAt: string,
            ticketTransactionTicketId: string,
            ticketTransactionRefundId?: string | null,
            ticketTransactionPersonTicketId?: string | null,
            ticketTransactionPromoterId?: string | null,
          },
          eventID: string,
          personID: string,
          createdAt: string,
          updatedAt: string,
          ticketRefundPersonTicketId: string,
          ticketRefundTransactionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      promoters?:  {
        __typename: "ModelPromoterEventConnectionConnection",
        items:  Array< {
          __typename: "PromoterEventConnection",
          id: string,
          promoterID: string,
          eventID: string,
          promoter:  {
            __typename: "Promoter",
            id: string,
            owner: string,
            name: string,
            companyID: string,
            events?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
          },
          event:  {
            __typename: "Event",
            id: string,
            owner: string,
            name: string,
            promotions: Array< Promotions >,
            location:  {
              __typename: "Location",
              lon: number,
              lat: number,
            },
            addressAlias: string,
            companyID: string,
            description: string,
            startDate: string,
            endDate: string,
            date:  Array< {
              __typename: "DateSpan",
              start: string,
              end: string,
            } >,
            maxCapacity?: number | null,
            company?:  {
              __typename: "Company",
              id: string,
              owner: string,
              name: string,
              email?: string | null,
              iban: string,
              initials: string,
              handle?: string | null,
              consumablesFee: number,
              ticketsFee: number,
              createdAt: string,
              updatedAt: string,
              companyAvatarId?: string | null,
            } | null,
            banner:  {
              __typename: "Image",
              id: string,
              owner: string,
              eventID?: string | null,
              filename: string,
              identityID: string,
              createdAt: string,
              updatedAt: string,
            },
            poster?:  {
              __typename: "Poster",
              id: string,
              owner: string,
              createdAt: string,
              updatedAt: string,
            } | null,
            streaks?:  {
              __typename: "ModelStreakConnection",
              nextToken?: string | null,
            } | null,
            photos?:  {
              __typename: "ModelImageConnection",
              nextToken?: string | null,
            } | null,
            tickets?:  {
              __typename: "ModelTicketConnection",
              nextToken?: string | null,
            } | null,
            collaborators?:  {
              __typename: "ModelCollaboratorEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            consumables?:  {
              __typename: "ModelConsumableConnection",
              nextToken?: string | null,
            } | null,
            peopleState?:  {
              __typename: "ModelPersonEventStateConnection",
              nextToken?: string | null,
            } | null,
            transactionsTickets?:  {
              __typename: "ModelTicketTransactionConnection",
              nextToken?: string | null,
            } | null,
            transactionsConsumables?:  {
              __typename: "ModelConsumableTransactionConnection",
              nextToken?: string | null,
            } | null,
            ticketRefunds?:  {
              __typename: "ModelTicketRefundConnection",
              nextToken?: string | null,
            } | null,
            promoters?:  {
              __typename: "ModelPromoterEventConnectionConnection",
              nextToken?: string | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            eventBannerId: string,
            eventPosterId?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      eventBannerId: string,
      eventPosterId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
