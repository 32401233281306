import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import EventStatsBar from '../../components/EventStatsBar'
import { EventStatisticsResult, EventStatisticsState } from '../../types/event-statistics'
import { withTranslation } from 'react-i18next'
import { getEventLite } from '../../models/event'
import { pt as months } from '../../constants/months.json'

import StatsGeneral from './StatsGeneral'
import StatsTickets from './StatsTickets'
import StatsConsumables from './StatsConsumables'
import StatsCollaborators from './StatsCollaborators'
import { TailSpin } from 'react-loader-spinner'
import LiveIndicator from 'src/components/LiveIndicator'

import '../../styles/views/event-statistics.css'
import { fetchImage } from '../../models/images'
import { fetchEventStatistics } from '../../models/stats'

/* eslint-disable */
/**
 * Enum used to decide which sub page to show.
 */
enum SubPages {
  GENERAL,
  TICKETS,
  CONSUMABLES,
  COLLABORATORS
}
/* eslint-enable */

/**
 * This page shows the statistics of a specific event.
 */
class EventStatistics extends Component<RouteComponentProps<undefined, Record<string, never>, { eventID: string }>
  & GlobalProps,
  EventStatisticsState
  > {
  /**
   * AllEvents class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      subPage: SubPages.GENERAL,
      eventID: this.props.location.state.eventID,
      isLoading: true,
    }
  }

  /**
   * Fetches statistics from this event.
   */
  async componentDidMount() {
    const fetchedEvent = await getEventLite({ id: this.props.location.state.eventID })
    const fetchedEventImage = await fetchImage(fetchedEvent.banner?.filename, fetchedEvent.banner.identityID) as string
    console.log(fetchedEvent)
    const statistics = await fetchEventStatistics(fetchedEvent)

    /* Since we have finished gathering information, we set page as not loading to render the rest of the components */
    this.setState({
      isLoading: false,
      event: fetchedEvent,
      eventImage: fetchedEventImage,
      statistics: statistics as EventStatisticsResult,
    })
  }

  /**
   * Changes displayed category.
   *
   * @param event clicking of a button
   * @param statCategory new highlighted category
   */
  private changeStatCategory = (event: React.MouseEvent, statCategory: number): void => {
    this.setState({ subPage: statCategory + 1 })
  }

  /**
   * Decides which sub page should be rendered at the time.
   *
   * @param page page to be rendered
   */
  private subPageController = (page: SubPages): JSX.Element => {
    if (!this.state.statistics) return <div />

    /* Decides which page to render */
    switch (page) {
    /* Displays general info page */
    case SubPages.GENERAL:
      return (
        <StatsGeneral {...this.state.statistics?.general}/>
      )

      /* Displays tickets info page */
    case SubPages.TICKETS:
      return (
        <StatsTickets {...this.state.statistics?.tickets}/>
      )

      /* Displays consumables info page */
    case SubPages.CONSUMABLES:
      return (
        <StatsConsumables {...this.state.statistics?.consumables}/>
      )

    case SubPages.COLLABORATORS:
      return (
        <StatsCollaborators {...this.state.statistics?.collaborators}/>
      )

      /* Error */
    default:
      return (
        <div />
      )
    }
  }

  /**
  * Parses received date from the database into a user friendly string.
  *
  * @param startDate date in ISO string format
  *
  * @returns string user friendly string
  */
  private dateTransformer = (startDate: string): string => {
    const date = new Date(Date.parse(startDate))
    return `${date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}`
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="event-statistics-total">
        <div className="event-statistics-main">
          { this.state.eventImage
          && <img src={this.state.eventImage} alt="Event"/>
          }
          { !this.state.isLoading
          && <div className="event-statistics-title">
            <h1>{this.state.event?.name}</h1>
            {this.state.event?.date.map((date, index) => (
              <h3 key={index}>{this.dateTransformer(date.start)}</h3>
            ))}
            <LiveIndicator dateSpan={this.state.event?.date} />
          </div>
          }
          { !this.state.isLoading
          && <EventStatsBar changeStatCategory={this.changeStatCategory}/>
          }

          { this.state.isLoading
          && <div className="event-statistics-loading">
            <TailSpin
              visible={true}
              height="150"
              width="150"
              color="#02112E"
              radius="0"
            />
          </div>
          }

          {!this.state.isLoading && <div>{this.subPageController(this.state.subPage)}</div>}

        </div>
      </div>
    )
  }
}

export default withTranslation()(EventStatistics)
