import React, { Component } from 'react'
import { SearchBarProps } from '../../types/search-bar' /* Import needed for the props */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */

import '../../styles/components/search-bar.css'

/**
 * SearchBar used to define and search for which elements to show.
 */
class SearchBar extends Component<SearchBarProps> {
  /**
  * React component render function. Holds our extended html code.
  */
  public render(): JSX.Element {
    return (
      <div className="search-bar">
        <FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.2vw' }}/>
        <input className="search-bar-input" value={this.props.text} type="text" placeholder={i18next.t('SearchBar.placeholder')}
          onChange={(e) => this.props.changeText(e.target.value)}/>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => this.props.changeText('')}
          style={{ fontSize: '1.2vw', color: this.props.text !== '' ? '#02112E' : 'transparent' }}
        />
      </div>
    )
  }
}

export default withTranslation()(SearchBar)
